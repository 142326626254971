import React from "react"
import FieldListItem from "./FieldListItem"
class FieldListGroupItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      field: props.field,
      showControls: false,
      controlField: props.controlField,
      groupControlField: props.groupControlField
    }
    this.showControls = this.showControls.bind(this);
    this.hideControls = this.hideControls.bind(this);
    this.groupControlField = this.groupControlField.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      field: nextProps.field
    })
  }

  showControls() {
    this.setState({
      showControls: true
    })
  }

  hideControls() {
    this.setState({
      showControls: false
    })
  }

  controlField(event){
    event.preventDefault();
    let control = event.target.name
    this.state.controlField(this.state.field, control);
  }

  groupControlField(field, control){
    let groupField = this.state.field
    this.state.groupControlField(groupField, field, control);
  }

  format(label) {
    let maxLength = 6;
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "...";
    } else {
      return label;
    }
  };

  render () {
    return (
      <li className={"field-item group " + this.state.showControls} onMouseEnter={this.showControls} onMouseLeave={this.hideControls}>
        <a href="#" className="item">{this.format(this.state.field.label[0].value)} <span className="item-type">| Group</span></a>
        {
          this.state.showControls &&
          <div className="controls pull-right">
            <a href="#" className="delete  btn btn-link" name="delete" onClick={(event) => this.controlField(event)}>
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106428/trash-alt-solid.svg" alt="Trash" className="icon"/> Delete</a>
            <a href="#" className="edit  btn btn-link" name="edit" onClick={(event) => this.controlField(event)}>
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106428/pencil-alt-regular.svg" alt="Pencil" className="icon"/> Edit</a>
          </div>
        }
        {this.fieldsList()}
      </li>
    );
  }

  
  fieldsList(){
    let sortedList = this.state.field.fields.sort((a, b) =>{
      return parseFloat(a.index) - parseFloat(b.index);
    });
    let list = sortedList.map((field, i) =>
      <FieldListItem
        field={field}
        controlField={ (field, control)=>{
          this.groupControlField(field, control)
        } }
        key={i}
      />
    )
    let fields = null;
    if (list.length > 0){
      fields = (<div> <ul className="group-field">
          {list}
        </ul> </div>);
    }
    return fields
  }
}

export default FieldListGroupItem
