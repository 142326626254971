import React from "react"
import PropTypes from "prop-types"
class OptionInputs extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentOption: props.currentOption,
      currentOptionIndex: props.currentOptionIndex,
      //showOptionInputs: props.showOptionInputs,
      currentOptionList: props.currentOptionList,
      useSecondaryLanguage: props.useSecondaryLanguage
    }
    this.languageLabelValue = this.languageLabelValue.bind(this)
    this.updateLabelValue = this.updateLabelValue.bind(this);
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      currentOption: nextProps.currentOption,
      currentOptionIndex: nextProps.currentOptionIndex,
      showOptionInputs: nextProps.showOptionInputs,
      currentOptionList: nextProps.currentOptionList,
      useSecondaryLanguage: nextProps.useSecondaryLanguage
    })
  }

  updateOptionValue(event){
    let value = event.target.value;
    let currentOption = this.state.currentOption
    currentOption["name"] = value
    this.setState({...this.state, 
      currentOption: currentOption
    })
    //this.props.updateOptionValue(event);
  }

  addOption(event){
    this.props.addOption(event, this.state.currentOption);
  }

  
  updateLabelValue(index, event){
    let value = event.target.value;
    let currentOption = this.state.currentOption;
    // if(this.props.operationType == "edit" && index == 0 && value){
    //   currentOption["name"] = this.generateOptionName(value);
    // }
  
    if((index + 1) <= this.state.currentOption.label.length  ){
      currentOption.label[index].value = value
    }
    else{
      let labels = [...currentOption.label]
      
      labels.push({value: value})
      currentOption = {...currentOption, label: labels}
    }

    this.setState({...this.state, 
      currentOption: currentOption
    })
  }

  generateOptionName(value){
    let genName = value.replace(/[^a-zA-Z0-9]/g, "");
    let currentList = this.props.optionLists.filter(e=>{return e.listName== this.props.currentOptionList })[0];
    let exist = currentList.options.filter((e,index)=> e["name"] === genName && index !=this.state.currentOptionIndex).length > 0;
    if(!genName || exist){
      let exist = true;
      while(exist){
        genName = this.props.currentOptionList + Math.floor(Math.random() * 101);
        exist = currentList.filter(e=> e["name"] === genName).length > 0;
      }
    }
    return genName;
  }

  render () {
    if(this.state.currentOptionList === "new-option-list" || this.state.currentOptionList === ""){
      return <div />;
    }
    else{
      return (
        <div>
          {this.renderOptionName()}
          {this.renderOptionLabel()}
          <div className="option-help"></div>
          <button key="options-form-button" type="button" className="yo btn btn-default" onClick={(event) => this.addOption(event)}>{this.props.optionEditMode ? "Save Edit":"Add Option"}</button>
        </div>
      );
    }
  }

  renderOptionName(){
      return (<div>
          <label>Option Name</label>
          <input className="form-control" name="name" type="text" placeholder="option_name" value={this.state.currentOption["name"]} onChange={(event) => this.updateOptionValue(event)}/>
          <small>The value that gets saved</small>
          <div className="option-help"></div>
      </div>)
  }
  renderOptionLabel(){
    if(this.state.useSecondaryLanguage){
      return(<div>
        <label>Option Label</label>
        <br/>
        <small>Default label</small>
        <input type="text" className="form-control" name="label" placeholder="Option Label" value={this.languageLabelValue(0)} onChange={(event) => this.updateLabelValue(0, event)}/>
        <small>Secondary label</small>
        <input type="text" className="form-control" name="label" placeholder="Option Label" value={this.languageLabelValue(1)} onChange={(event) => this.updateLabelValue(1, event)}/>
        <small>The name you see on the list</small>
      </div>)
    }
    else{
      return(<div>
        <h2>Field Label</h2>
        <input type="text" className="form-control" name="label" placeholder="Option Label" value={this.languageLabelValue(0)} onChange={(event) => this.updateLabelValue(0, event)}/>
        <small>The name you see on the list</small>
      </div>
      )
    }

  }

    
  languageLabelValue(index){
    if (this.state.currentOption["label"][index]){
      return this.state.currentOption["label"][index].value
    }
    return ""
  }

}

export default OptionInputs
