import React from "react";
import PropTypes from "prop-types";

class Language extends React.Component {
  render() {
    let languageOptions = [];
    let languages = this.props.languages;
    let optionKey;
    let currentLanguage = this.props.currentLanguage;
    languages.forEach( function(option, i){
      optionKey = (i + 1).toString();
      languageOptions.push(<option key={optionKey.concat("-language")} value={i} selected={option == currentLanguage}>{option}</option>)
    })
    return (
      <div className="form-group">
        <label>Please select language</label>
        <select className="form-control" name="language_select" multiple={false} onChange={this.props.setLanguage}>
          {languageOptions}
        </select>
      </div>
    );
  }
}
Language.propTypes = {
  languages: PropTypes.array,
  setLanguage: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string
}
export default Language
