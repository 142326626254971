import React from "react";
import PropTypes from "prop-types";
import OptionForm from "./OptionForm";
import SelectFieldIndex from "./SelectFieldIndex"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class FieldSettings extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentlyBuilding: props.currentlyBuilding,
      field: props.currentField,
      newGroupIndex: props.newGroupIndex,
      submitField: props.submitField,
      cancelSubmit: props.cancelSubmit,
      updateCurrentField: props.updateCurrentField,
      optionLists: props.optionLists,
      startOptionList: props.startOptionList,
      currentOptionList: props.currentOptionList,
      addOption: props.addOption,
      controlOption: props.controlOption,
      currentOption: props.currentOption,
      currentOptionIndex: props.currentOptionIndex,
      selectOptionsList: props.selectOptionsList,
      fields: props.fields,
      useSecondaryLanguage: props.useSecondaryLanguage
    }

    this.selectOptionsList = this.selectOptionsList.bind(this);
    this.startOptionList = this.startOptionList.bind(this);
    this.addOption = this.addOption.bind(this);
    this.controlOption = this.controlOption.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
    this.languageLabelValue = this.languageLabelValue.bind(this);
    this.updateLabelValue = this.updateLabelValue.bind(this);
    this.updateContraintMessageValue = this.updateContraintMessageValue.bind(this)
    this.languageContraintMessageValue = this.languageContraintMessageValue.bind(this)
  }

  locationOptions(){
    let options =[];
    let currentField = this.state.field;
    options.push({ index:  99, name: "Starting field"})

    this.state.fields.forEach(field=>{
      options.push({ index: field.index, name: `${field.name}-${field.label[0].value}`});
      if(field["type"]=="group" && currentField["type"] !="group"){
        options.push({ index:  `${field["index"]}-99`, name: "-- Starting field"})
        field.fields.forEach(groupField=>{
          options.push({ index:  `${field["index"]}-${groupField["index"]}`, name: `-- ${groupField.name}-${groupField.label[0].value}`})
        })
      }
    });
    return options;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentlyBuilding: nextProps.currentlyBuilding,
      field: nextProps.currentField,
      newGroupIndex: nextProps.newGroupIndex,
      optionLists: nextProps.optionLists,
      //currentOptionList: nextProps.currentField["optionList"],
      currentOptionList: nextProps.currentOptionList,
      currentOption: nextProps.currentOption,
      currentOptionIndex: nextProps.currentOptionIndex,
      useSecondaryLanguage: nextProps.useSecondaryLanguage
    })
  }

  updateValue(event){
    event.preventDefault();
    let field = {...this.state.field};
    let newGroupIndex = null;
    if (event.target.name=="index"){
      if(event.target.value.includes("-")){
        let groupAndFieldIndex = event.target.value.split("-");
        newGroupIndex = parseInt(groupAndFieldIndex[0]);
        let fieldIndex = groupAndFieldIndex[1];
        field["index"] = parseInt(fieldIndex);
      }
      else{
        field["index"] = parseInt(event.target.value);
      }
      
    }else{
      field[event.target.name] = event.target.value;
    }

    if(field["type"]=="group"){
      field.fields=[]
    }
    this.state.updateCurrentField(field)
    // this.setState({...this.state, 
    //   field: field,
    //   newGroupIndex: newGroupIndex
    // })
  }

  updateLabelValue(index, event){
    let value = event.target.value;
    let field = this.state.field
    if((index + 1) <= this.state.field.label.length  ){
      field.label[index].value = value
    }
    else{
      let labels = [...field.label]
      
      labels.push({value: value})
      field = {...field, label: labels}
    }
    this.state.updateCurrentField(field);
    // this.setState({...this.state, 
    //   field: field
    // })
  }

  updateContraintMessageValue(index, event){
    let value = event.target.value;
    let field = this.state.field
    if(field.constraint_message == null){
      field.constraint_message = []
    }
    if((index + 1) <= field.constraint_message.length  ){
      field.constraint_message[index].value = value
    }
    else{
      let labels = [...field.constraint_message]
      
      labels.push({value: value})
      field = {...field, constraint_message: labels}
    }

    this.state.updateCurrentField(field);

    // this.setState({...this.state, 
    //   field: field
    // })
  }

  // updateOptionValue(event){
  //   let currentOption = this.state.currentOption;
  //   currentOption[event.target.name] = event.target.value;
  //   this.setState({...this.state, 
  //     currentOption: currentOption
  //   })
  // }

  submitField(event){
    let field = this.state.field;
    let newGroupIndex = this.state.newGroupIndex;

    this.state.submitField(newGroupIndex, field);
  }

  cancelSubmit(event){
    this.state.cancelSubmit()
  }

  addOption(listName, optionName, optionLabel){
    this.state.addOption(listName, optionName, optionLabel);
  }

  controlOption(option, control, index){
    this.state.controlOption(option, control, index);
  }

  selectOptionsList(listName){
    this.state.selectOptionsList(listName);
  }

  startOptionList(newOptionListName){
    this.state.startOptionList(newOptionListName);
  }

  getFieldIndex(){
    if(this.state.newGroupIndex){
      return `${this.state.newGroupIndex}-${this.state.field["index"]}`
    }
    else{
      let field = this.state.field
      if(field["index"]){
        return this.state.field["index"]
      }
      else{
        if(this.state.fields.length > 0){
          return this.state.fields[this.state.fields.length-1]["index"]
        }
        else{
          return ""
        }
      }
    }
  }

  render () {
    if (!this.state.currentlyBuilding){
      return (
        <div className="col-md-8 border-right settings">
          <div className="h-100">
              <h1>Form Builder</h1>
              <p>Form builder allows you to easily and quickly build forms that can be deployed to Standard Data! Once you’ve created your form, you’ll be able to start collecting data via iPhone, Android, and web in minutes.</p>
              <h2>Field Types</h2>
              <div className="row">
                <div className="col-md-6">
                  <div className="type type-text">
                    <div className="copy">
                      <h3>Text</h3>
                      <small>String & Text</small>
                      <p>A simple text input. Strings are useful for short pieces of text, such as names. The text entry field expands as the user types, and line breaks can be included.</p>
                    </div>
                  </div>
                  <div className="type type-date">
                    <div className="copy">
                      <h3>Date</h3>
                      <small>Date, Time, & DateTime</small>
                      <p>Form Builder makes it easy to capture date, time, or both. Useful for recording when data was collected, or storing any other dates relevant to the form.</p>
                    </div>
                  </div>
                  <div className="type type-choice">
                    <div className="copy">
                      <h3>Choice</h3>
                      <small>Select One & Select Multiple</small>
                      <p>You can easily add single or multiple choice questions, which helps control the data being gathered. Creating the lists of options is easy, and lists can be shared between different select type question.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="type type-number">
                    <div className="copy">
                      <h3>Number</h3>
                      <small>Integer & Decimal</small>
                      <p>There are two types of numbers, integers and decimals. An integer is a whole number that can be positive, negative, or zero. A decimal is a floating point number, such as 1.05.</p>
                    </div>
                  </div>
                  <div className="type type-location">
                    <div className="copy">
                      <h3>Location</h3>
                      <small>Geopoint</small>
                      <p>Collect precise GPS coordinates with this useful type. Records collected with geopint are automatically plotted on a map for you to view on the data management page.</p>
                    </div>
                  </div>
                  <div className="type type-help">
                    <div className="copy">
                      <h3>Form Help</h3>
                      <small>Note</small>
                      <p>Helpful descriptions to include in your form. Great for introducing a group of related questions. Also great for adding instructions to help collectors gather accurate data.</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      )
    }
    else if (this.state.currentlyBuilding){
      let basicProperty = null
      let advanceProperty = null
      if (this.state.field["name"]){
        basicProperty = (<div>
                {this.renderLabel()}
                {this.renderOptions() }
                {this.renderCalculation()}
                {this.renderSelectFieldIndex()}
                {this.renderPrimaryButton()}
        </div>)

      //   advanceProperty = (<div className="h-100">
      //     {this.renderRequired()}
      //     <h2>Appearance</h2>
      //     <input type="text" className="form-control" name="name" placeholder="Appearance" value={this.state.field["appearance"]} onChange={(event) => this.updateValue(event)}/>
      //     <h2>Relevant</h2>
      //     <textarea type="text" className="form-control" name="relevant" placeholder="Relevant" value={this.state.field["relevant"]} onChange={(event) => this.updateValue(event)}/>
      //     <small>Relevant is the conditions in which the field appears.</small>
      //     {this.renderConstraint()}
      //     { <div className="border-top"><button className="btn btn-md btn-primary save" onClick={(event) => this.submitField(event)}>{this.props.fieldEditMode ? "Save Edit":"Add Field"}</button></div>}
      // </div>)
      }
      return (
          <div className="col-md-3 border-right settings">
          <Tabs>
            <TabList>
              <Tab><b>Basic</b></Tab>
              {/* <Tab><b>Advance</b></Tab> */}
            </TabList>
            <TabPanel>
              <div className="h-100">
                
                {this.renderSelectType()}
                {this.renderFieldName()}
                {basicProperty}
              </div>
            </TabPanel>
            {/* <TabPanel>
              {advanceProperty}
            </TabPanel> */}
          </Tabs>
          </div>
      )
    }
  }
  
  renderLabel(){
    if(this.state.useSecondaryLanguage){
      return(<div>
        <h2>Field Label</h2>
        <small>Default label</small>
        <textarea className="form-control" name="label" placeholder="Field Label" value={this.languageLabelValue(0)} onChange={(event) => this.updateLabelValue(0, event)}/>
        <small>Secondary label</small>
        <textarea type="text" className="form-control" name="label" placeholder="Field Label" value={this.languageLabelValue(1)} onChange={(event) => this.updateLabelValue(1, event)}/>
        <small>The question people see in the form</small>
      </div>)
    }
    else{
      return(<div>
        <h2>Field Label</h2>
        <textarea type="text" className="form-control" name="label" placeholder="Field Label" value={this.languageLabelValue(0)} onChange={(event) => this.updateLabelValue(0, event)}/>
        <small>The question people see in the form</small>
      </div>
      )
    }

  }
  
  languageLabelValue(index){
    if (this.state.field["label"][index]){
      return this.state.field["label"][index].value
    }
    return ""
  }

  renderSelectType(){
    let disable = false;
    if(this.props.fieldEditMode && this.state.field["type"]=="group"){
      disable=true;
    }

    if(this.props.fieldOperation=="add"){
      return (
        <div>
          <h2>Field Type</h2>
          <select className="form-control" name="type" value={this.state.field["type"]} onChange={(event) => this.updateValue(event)} disabled={disable}>
            <option value="">-choose type-</option>
            <option value="string">String</option>
            <option value="text">Text</option>
            <option value="int">Integer</option>
            <option value="decimal">Decimal</option>
            <option value="dateTime">Date Time</option>
            <option value="date">Date</option>
            <option value="time">Time</option>
            <option value="note">Note</option>
            <option value="geopoint">Geopoint</option>
            <option value="select1">Select One</option>
            <option value="select">Select Multiple</option>
            {/* <option value="calculate">Calculate</option>
            <option value="group">Group</option> */}
          </select>
          <small>What kind of data is this? String, number, date, etc</small>
        </div>                
        )
    }
    else{
      return "";
    }

  }

  renderFieldName(){
    if(this.props.fieldOperation == "add"){
      return (<div>
        <h2>Field Name</h2>
                  <input type="text" className="form-control" name="name" placeholder="Field Name" value={this.state.field["name"]} onChange={(event) => this.updateValue(event)}/>
                  <small>The unique variable name for this data point</small>
      </div>)
    }
    else{
      return ""
    }
  }

  renderConstraint(){
    if (!this.state.field["type"]!="group"){
      if(this.state.useSecondaryLanguage){
        return (<div>
          <h2>Constraint</h2>
          <textarea type="text" className="form-control" name="constraint" placeholder="Constraint" value={this.state.field["constraint"]} onChange={(event) => this.updateValue(event)}/>
          <small>Constraint value is use to evaluate if the field value is valid.</small>
          <h2>Constraint message</h2>
          <small>Default Constraint message</small>
          <textarea className="form-control" name="constraint_message_1" placeholder="Constraint message" value={this.languageContraintMessageValue(0)} onChange={(event) => this.updateContraintMessageValue(0, event)}/>
          <small>Secondary Constraint message</small>
          <textarea type="text" className="form-control" name="constraint_message_2" placeholder="Constraint message" value={this.languageContraintMessageValue(1)} onChange={(event) => this.updateContraintMessageValue(1, event)}/>
          <small>Constraint message will show if the constraint is not valid.</small>
        </div>)
      }
      else{
        return (<div>
          <h2>Constraint</h2>
          <textarea type="text" className="form-control" name="constraint" placeholder="Constraint" value={this.state.field["constraint"]} onChange={(event) => this.updateValue(event)}/>
          <small>Constraint value is use to evaluate if the field value is valid.</small>
          <h2>Constraint message</h2>
          <textarea type="text" className="form-control" name="constraint_message" placeholder="Constraint message" value={this.languageContraintMessageValue(1)} onChange={(event) => this.updateContraintMessageValue(1, event)}/>
          <small>Constraint message will show if the constraint is not valid.</small>
        </div>)
      }
    }
    return null
  }
  
  languageContraintMessageValue(index){
    if (this.state.field.constraint_message && this.state.field.constraint_message[index]){
      return this.state.field.constraint_message[index].value
    }
    return ""
  }

  renderCalculation(){
    if (this.state.field["type"]=="calculate"){
      return (<div>
        <h2>Calculation</h2>
        <textarea className="form-control" name="name" placeholder="Calculation" value={this.state.field["calculation"]} onChange={(event) => this.updateValue(event)}></textarea>
      </div>)
    }
    return null
  }

  renderOptions(){
    if (this.state.field["type"].includes("select")){
      return (
        <OptionForm
          operationType={this.props.operationType}
          fieldOperation={this.props.fieldOperation}
          getListNameOptions={this.getListNameOptions}
          startOptionList={this.startOptionList}
          currentOption={this.state.currentOption}
          currentOptionIndex={this.state.currentOptionIndex}
          addOption={this.addOption}
          currentOptionList={this.state.currentOptionList}
          selectOptionsList={this.selectOptionsList}
          optionEditMode={this.props.optionEditMode}
          //updateOptionValue={this.updateOptionValue}
          optionLists={this.state.optionLists}
          controlOption={this.controlOption}
          useSecondaryLanguage={this.state.useSecondaryLanguage}
        />)
    }else{
      return null
    }
  }

  renderRequired(){
    if (!this.state.field["type"]!="group"){
      return (<div>    
          <h2>Required</h2>
          <select className="form-control" name="required" value={this.state.field["required"]} onChange={(event) => this.updateValue(event)}>
            <option value="">No</option>
            <option value="yes">Yes</option>
          </select>
          <small>Is this field required?</small>
        </div>)
    }

  }

  renderSelectFieldIndex(){
    if(this.state.field["type"]){
      return <SelectFieldIndex options={this.locationOptions()} fieldIndex={this.getFieldIndex()} editMode={this.props.fieldEditMode}  updateValue={(event) => this.updateValue(event)}/>
    }
    else{
      return "";
    }
  }

  renderPrimaryButton(){
    if (this.props.fieldEditMode){
      return (
          <div className="border-top">
            <button className="btn btn-md btn-primary save" onClick={(event) => this.submitField(event)}>Save Edit</button>&nbsp;&nbsp;
            <button className="btn btn-md btn-cancel save" onClick={(event) => this.cancelSubmit(event)}>Cancel Edit</button>
          </div>
       )
    }
    if(this.props.fieldOperation == "add"){
      return (
        <div className="border-top"><button className="btn btn-md btn-primary save" onClick={(event) => this.submitField(event)}>Add Field</button></div>
      )
    }
    return "";
  }
}

export default FieldSettings
