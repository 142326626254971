import React from "react"
import PropTypes from "prop-types"
import BuilderPanel from "./BuilderPanel"
import FormPreview from "./FormPreview"

import FieldList from "./FieldList"
import FieldSettings from "./FieldSettings"



class FormBuilder extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      browserSupported: true,
      operationType: props.operation_type,
      projectUrl: props.project_url,
      currentUserId: props.current_user_id,
      formID: props.formID?props.formID: "",
      formTitle: props.formTitle?props.formTitle: "",
      formSource: props.formSource?props.formSource: "",
      formSourceUrl: props.formSourceUrl?props.formSourceUrl: "",
      currentlyBuilding: props.fields.length>0,
      fields: props.fields,
      rootUrl: props.root_url,
      formBuilderCreateUrl: props.form_builder_create_url,
      formIsBeingPublished: false,
      formHasBeenPublished: false,
      failedToPublish: false,
      surveyRecord: null,
      editingFieldIndex: -1,
      optionLists: props.optionLists,
      editingOption: {},
      fieldEditMode: false,
      optionEditMode: false,
      currentOptionList: '',
      gettingLocation: false,
      languages: props.languages,
      useSecondaryLanguage: props.languages.length > 1,
      okToSubmit: false,
      validationAlertIndex: 0,
      validationAlertMessages: [
        "Field Name, Field Label, and Field Type are all required to add a field.",
        "Fields with a select type require an option list. Please select a list or start a new one.",
        "Field names can not contain uppercase letters, or spaces.\nThe only special characters allowed are underscores ( _ ).\nPlease enter a new name for this field following those rules.",
        "Field names can only be used for one field per form. Please enter a unique 'name' for this field."
      ],
      newGroupIndex: null,
      validatingField:{
        name: '',
        label: [{language: "", value: ""}],
        type: '',
        index: null
      },
      currentField: {
        name: '',
        label: [{language: "", value: ""}],
        type: '',
        index: props.fields.length > 0 ? props.fields[props.fields.length-1]["index"]: 100
      },
      currentOption: {
        name: '',
        label: [{language: "", value: ""}],
      },
      editField:{
        name: '',
        label: [{language: "", value: ""}],
        type: ''
      },
      featuresIsSupported: props.featuresIsSupported,
      fieldOperation: ""
    };
    
    this.startBuilding = this.startBuilding.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.updateSource = this.updateSource.bind(this);
    this.updateSourceUrl = this.updateSourceUrl.bind(this);
    this.submitField = this.submitField.bind(this);
    this.controlField = this.controlField.bind(this);
    this.startOptionList = this.startOptionList.bind(this);
    this.addOption = this.addOption.bind(this);
    this.controlOption = this.controlOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.selectOptionsList = this.selectOptionsList.bind(this);
    this.publishForm = this.publishForm.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.groupControlField = this.groupControlField.bind(this);
    this.cancelSubmit = this.cancelSubmit.bind(this);
    this.updateMultiLanguage = this.updateMultiLanguage.bind(this);
    this.updateUseSecondaryLanguage = this.updateUseSecondaryLanguage.bind(this);
    this.getOkToSubmit = this.getOkToSubmit.bind(this)
    this.newField = this.newField.bind(this);
    this.updateCurrentField = this.updateCurrentField.bind(this);
  }



  componentWillMount(){
    if(this.detectBrowserSupport()){
      this.setState({browserSupported: true});
    }
    else{
      this.setState({browserSupported: false});
    }
  }

  detectBrowserSupport() {
    /**
     * detect IE
     * returns false if browser is Internet Explorer
     */
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older
        return false
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11
        var rv = ua.indexOf('rv:');
        return false
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+)
       return false
    }

    // other browser
    return true;
  }

  startBuilding(){
    this.setState({...this.state, currentlyBuilding: true})
  }

  newField(){
    this.setState({...this.state,
      currentField: {
        name: '',
        label: [{language: "", value: ""}],
        type: '',
        required: false,
        relevant:'',
        constraint:'',
        constraint_message:[{language: "", value: ""}],
        calculation: '',
        index: this.state.fields.length > 0 ? this.state.fields[this.state.fields.length-1]["index"] : 100
      },
      validatingField:{
        name: '',
        label: [{language: "", value: ""}],
        type: '',
        required: false,
        relevant:'',
        constraint:'',
        constraint_message:[{language: "", value: ""}],
        calculation: '',
        index: this.state.fields.length > 0 ? this.state.fields[this.state.fields.length-1]["index"] : 100
      },
      validationAlertIndex: 0,
      editingFieldIndex: -1,
      fieldEditMode: false,
      currentOptionList: '',
      currentOption: {
        name: '',
        label: [{language: "", value: ""}],
      },
      currentlyBuilding: true,
      fieldOperation: "add"
    })
  }

  updateTitle(value){
    this.setState({...this.state, formTitle: value})
  }

  updateSource(value){
    this.setState({...this.state, formSource: value})
  }

  updateSourceUrl(value){
    this.setState({...this.state, formSourceUrl: value})
  }

  updateMultiLanguage(value, index){
    let languages = this.state.languages
    if ((index+1)<=languages.length ){
      languages[index]["language"] = value
    }
    else{
      languages.push({language: value})
    }

    this.setState({...this.state,
      languages: languages
    })
  }

  updateUseSecondaryLanguage(val){
    let languages = this.state.languages
    if(languages.length == 1){
      languages.push({language: ""})
    }
    this.setState({...this.state,
      useSecondaryLanguage: val
    })
  }

  submitField(newGroupIndex, field){
    
    let spliceIfEdit = (fields)=>{
      if (this.state.fieldEditMode){
        fields.splice(this.state.editingFieldIndex, 1);
      }
      return fields
    }
    let sortAndUpdateFieldsIndex = (fields)=>{
      let sortedFields = fields.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
      for(let cnt=0; cnt<sortedFields.length; cnt++){
        sortedFields[cnt]["index"] = (cnt+1)*100
      }
      return sortedFields
    }
    let addToFields = (fields, field)=>{
      fields.push(field);
      return fields;
    }

    let validationCallback = (valid) => {
      let field = this.state.validatingField;
      let fieldIndex = this.state.nextFieldIndex;
      if (valid){
        let fields = this.state.fields;
        //Edit field inside a group
        if(this.state.newGroupIndex){
          field = {...field, fields: null };
          let previousGroup = fields.filter(e=> e["index"] == this.state.newGroupIndex)[0];
          let newGroup = null;

          //field is inside a group
          if (newGroupIndex){
            newGroup = fields.filter(e=> e["index"] == newGroupIndex)[0];
            let newGroupFields = newGroup.fields;
            //field is inside a same group as previous group
            if(previousGroup["index"] == newGroupIndex){
              let spliceFields = spliceIfEdit(newGroupFields);
              let addNewField = addToFields(spliceFields, field)
              newGroupFields = sortAndUpdateFieldsIndex(addNewField);
            }
            else{
              let spliceFields = spliceIfEdit(previousGroup.fields)
              let addNewField = addToFields(spliceFields, field)
              previousGroup.fields = sortAndUpdateFieldsIndex(addNewField);
            }
          }
          else{
            previousGroup.fields = sortAndUpdateFieldsIndex(spliceIfEdit(previousGroup.fields));
            fields = sortAndUpdateFieldsIndex(addToFields(fields, field));
          }
        }
        else{
          
          //field is outside a group
          if (newGroupIndex){
            let newGroup = fields.filter(e=> e["index"] == newGroupIndex)[0];
            newGroup.fields =  sortAndUpdateFieldsIndex(addToFields(newGroup.fields , field))
            fields = sortAndUpdateFieldsIndex(spliceIfEdit(fields));
          }
          else{
            //field not inside a group
            if (this.state.fieldEditMode){
              fields.splice(this.state.editingFieldIndex, 1);
            }
            else{
              if(field["type"]=="group"){
                field["fields"] = []
              }
            }

            //fields = spliceIfEdit(previousGroup.fields);
            fields = addToFields(fields, field);
            fields = sortAndUpdateFieldsIndex(fields);
          }
        }

        let sortedFields = fields.sort((a, b) => parseFloat(a.index) - parseFloat(b.index))
        this.setState({...this.state, 
          fields: sortedFields,
          currentField: {
            name: '',
            label: '',
            type: ''
          },
          validatingField:{
            name: '',
            label: '',
            type: ''
          },
          validationAlertIndex: 0,
          editingFieldIndex: -1,
          nextFieldIndex: (fieldIndex += 1),
          fieldEditMode: false,
          currentOptionList: '',
          fieldOperation: ""
        })
      }
      else{
        alert(this.state.validationAlertMessages[this.state.validationAlertIndex] )
      }
    }
    this.setState({...this.state, 
      validatingField: field,
      fieldOperation: ""}, () => {
      this.validateField(validationCallback)
    })
  }

  cancelSubmit(){
    this.updateState(this.state.fields);
  }

  validateField(validationCallback){
    let valid = false;
    let isFormattedCorrectly = false;
    let isUnique = false;
    let isCompleteCallback = (isComplete) => {
      if(isComplete){
        valid = true;
        isFormattedCorrectly = this.isFormattedCorrectly();
        if(isFormattedCorrectly){
          valid = true;
          isUnique = this.isUnique();
          if(isUnique){
            valid = true;
          }
          else{
            valid = false;
          }
        }
        else{
          valid = false;
        }
      }
      else{
        validationCallback(false);
        return false
      }
      if(valid){
        validationCallback(true);
      }
      else{
        if(!isFormattedCorrectly){
          let formattedName = this.formatFieldName();
          if (confirm(`In order for this name to become a valid data point, it will be reformatted to "${formattedName}".\nClick OK to use this name, or Cancel to enter a new one.`)) {
            let validatingField = this.state.validatingField;
            validatingField["name"] = formattedName;
            this.setState({...this.state, validatingField: validatingField});
            isUnique = this.isUnique();
            if(!isUnique){
              this.setState({...this.state, validationAlertIndex: 3}, () => {
                validationCallback(false);
                return null
              });
            }
            else{
              validationCallback(true);
            }
          } else {
            this.setState({...this.state, validationAlertIndex: 2}, () => {
              validationCallback(false);
              return null
            });
          }
        }
        else if(!isUnique){
          this.setState({...this.state, validationAlertIndex: 3}, () => {
            validationCallback(false);
            return null
          });
        }
      }
    }
    this.isComplete(isCompleteCallback);
  }

  updateState(newFields){
    this.setState({...this.state,
      fields: newFields,
      currentField: {
        name: '',
        label: [{language: "", value: ""}],
        type: '',
        required: '',
        relevant:'',
        constraint:'',
        constraint_message:[{language: "", value: ""}],
        calculation: '',
        index: newFields[newFields.length-1]["index"] 
      },
      validatingField:{
        name: '',
        label: [{language: "", value: ""}],
        type: '',
        required: '',
        relevant:'',
        constraint:'',
        constraint_message:[{language: "", value: ""}],
        calculation: '',
        index: newFields[newFields.length-1]["index"]
      },
      validationAlertIndex: 0,
      editingFieldIndex: -1,
      fieldEditMode: false,
      currentOptionList: '',
      currentOption: {
        name: '',
        label: [{language: "", value: ""}],
      },
      fieldOperation: ""
    })
  }



  isComplete(callback){
    if(this.state.validatingField.name === ''){
      this.setState({...this.state, validationAlertIndex: 0}, () => {
        callback(false)
      });
    }
    else if (this.state.validatingField.label === ''){
      this.setState({...this.state, validationAlertIndex: 0}, () => {
        callback(false)
      });
    }
    else if (this.state.validatingField.type === ''){
      this.setState({...this.state, validationAlertIndex: 0}, () => {
        callback(false)
      });
    }
    else if(this.state.validatingField.type.includes('select')){
      if(this.state.currentOptionList === '' || this.state.currentOptionList === "empty-option"){
        this.setState({...this.state, validationAlertIndex: 1}, () => {
          callback(false)
        });
      }
      else{
        callback(true)
      }
    }
    else{
      callback(true)
    }
  }

  isFormattedCorrectly(){
    // only allow names that are alphanumeric, to be MySQL column names
    let permittedFormat = /^[a-zA-Z0-9_"]*$/;
    if(this.state.validatingField.name.match(permittedFormat)){
      // can't start with a number
      if(this.state.validatingField.name.match(/(^[0-9])/)){
        return false
      }
      return true
    }
    else{
      return false
    }
  }

  isUnique(){
    // column names for the database table must be unique to the dataset
    if(this.state.fieldEditMode){
      if(this.state.validatingField["name"] === this.state.editFieldName){
        return true
      }
      else{
        let unique = true;
        let fields = this.state.fields
        for (var i = 0, len = fields.length; i < len; i++) {
          let field = fields[i]
          if(field["name"] === this.state.validatingField["name"]){
            if(i !== this.state.editingFieldIndex){
              unique = false;
              break;
            }
          }
          if(field["type"]=="group"){
            for (var e = 0, len = field.fields.length; e < len; e++) {
              let groupField = field.fields[e];
              if(groupField["name"] === this.state.validatingField["name"]){
                unique = false;
                break;
              }
            }
          }
        }
        return unique
      }
    }
    else{
      let unique = true;
      let fields = this.state.fields
      for (var i = 0, len = fields.length; i < len; i++) {
        let field = fields[i];
        if(fields[i]["name"] === this.state.validatingField["name"]){
          unique = false;
          break;
        }
        if(field["type"]=="group"){
          for (var e = 0, len = field.fields.length; e < len; e++) {
            let groupField = field.fields[e];
            if(groupField["name"] === this.state.validatingField["name"]){
              unique = false;
              break;
            }
          }
        }
      }
      return unique
    }
  }

  formatFieldName(){
    // remove whitespaces, and convert to snakecase (lowercase with underscore)
    let name = this.state.validatingField.name.trim()
    .replace(/\s/g, ([innerSpaces]) => "_")
    .replace(/[+=!@#$%^&*(),.?"'`[\]\\:;{}|<>/~-]/g, ([specialChars]) => "")
    .replace(/(^[0-9])/, ([leadingNumber]) => `_${leadingNumber}`)
    return name
  }

  updateCurrentField(field){
    this.setState({...this.state, 
      currentField: field});
  }

  controlField(field, control){
    switch(control){
      case "edit":
        let index = this.state.fields.indexOf(field);

        this.setState({...this.state, 
          currentField: field,
          editingFieldIndex: index,
          editFieldName: field["name"],
          fieldEditMode: true,
          currentOptionList: field["optionList"]
        })
      break;
      case "delete":
        this.deleteField(field);
      break;
    }
  }

  groupControlField(group, field, control){
    switch(control){
      case "edit":
        let index = group.fields.indexOf(field);
        this.setState({...this.state,
          newGroupIndex: group["index"],
          currentField: {...field},
          editingFieldIndex: index,
          editFieldName: field["name"],
          fieldEditMode: true,
          currentOptionList: field["optionList"]
        })
      break;
      case "delete":
        group.fields.splice(group.fields.indexOf(field), 1);
        for (var cnt = 0, len = group.fields.length; cnt < len; cnt++) {
          group.fields[cnt]["index"] = (cnt+1)*100;
        }
        
        let indexDelete = 100;
        if (this.state.fields.length > 0){
          indexDelete = this.state.fields[this.state.fields.length-1]["index"]
        }

        this.setState({...this.state,
          fields: this.state.fields,
          currentField: {
            name: '',
            label: '',
            type: '',
            required: '',
            relevant:'',
            constraint:'',
            constraint_message:[{language: "", value: ""}],
            calculation: '',
            index: indexDelete,
            fields: this.state.fields
          },
        })
      break;
    }
  }

  deleteField(field){
    let fields = this.state.fields;
    fields.splice(fields.indexOf(field), 1);
    for (var cnt = 0, len = fields.length; cnt < len; cnt++) {
      fields[cnt]["index"] = (cnt+1)*100;
    }

    let index = 100;
    if (fields.length > 0){
      index = fields[fields.length-1]["index"]
    }
    this.setState({...this.state, 
      fields: fields,
      currentField: {
        name: '',
        label: [{language: "", value: ""}],
        type: '',
        required: '',
        relevant:'',
        constraint:'',
        constraint_message:'',
        calculation: '',
        index: index
      },
    })
  }

  selectOptionsList(listName){
    switch(listName){
      case 'empty-option':
        this.setState({...this.state, 
          currentOptionList: '',
          currentField: {...this.state.currentField, optionList: listName}
         })
      break;
      case 'new-option-list':

        this.setState({...this.state, 
          currentOptionList: listName,
          currentField: {...this.state.currentField, optionList: listName}
         })
      break;
      default:

        this.setState({...this.state, 
          currentOptionList: listName,
          currentField: {...this.state.currentField, optionList: listName}
         })
    }
  }

  startOptionList(listName){
    let optionLists = this.state.optionLists;
    // format to fit XLSForm standard for choice list_names
    let formattedName = listName.trim()
    .replace(/(^[A-Z])/, ([leadingUppercase]) => leadingUppercase.toLowerCase())
    .replace(/\s/g, ([innerSpaces]) => "_")
    .replace(/([A-Z])/g, ([innerUppercase]) => `${innerUppercase.toLowerCase()}`)
    .replace(/[+=!@#$%^&*(),.?"'`[\]\\:;{}|<>/~-]/g, ([specialChars]) => "")
    optionLists.push({listName: listName, options: []})

    this.setState({...this.state, 
      optionLists: optionLists,
      currentOptionList: formattedName,
      currentField: {...this.state.currentField, optionList: formattedName}
    })
  }

  addOption(listName, optionName, optionLabel){
    let optionLists = this.state.optionLists;
    if(this.state.optionEditMode){
      this.setState({...this.state, 
        editingOption: {},
        optionEditMode: false,
        currentOption: {
          name: '',
          label: [{ value: ""}]
        }
      })
    }
    else{
      let optionList = optionLists.filter(e=>{ return e.listName == listName })[0];
      optionList.options.push({
        name: optionName,
        label: optionLabel
      });

      // optionLists[listName].push({
      //   name: optionName,
      //   label: optionLabel
      // })
      this.setState({...this.state, 
        optionLists: optionLists,
        currentOption: {
          name: '',
          label: [{ value: ""}]
        }
      })
    }
  }

  controlOption(option, control, index){
    switch(control){
      case "option-edit":
        this.setState({...this.state, 
          currentOption: option,
          currentOptionIndex: index,
          optionEditMode: true
        })
      break;
      case "option-delete":
        this.deleteOption(option);
      break;
    }
  }

  deleteOption(option){
    let optionLists = this.state.optionLists;
    let optionList = optionLists.filter(e=>{ return e.listName== this.state.currentOptionList })[0];
    let options = optionList.options;
    let index;
    for(let i = 0; i < options.length; i++){
      if(JSON.stringify(options[i]) === JSON.stringify(option)){
        index = i;
        break;
      }
    }
    options.splice(index, 1);
    optionList.options = options;
    this.setState({...this.state, 
      optionLists: optionLists
    })
  }

  publishForm(){
    var pubMessage = "Are you sure the form is ready to build? Please note that the form you just built is a preview, and that any data entered will not be collected. Once the form has been built, it can not be changed."
    pubMessage = pubMessage+"\n\n If you are actively collecting data, you will need to inform your team to download the latest forms before submitting new data! It is possible that sending data from unsynched forms could cause data to not be saved"
    if (window.confirm(pubMessage)){
      this.setState({...this.state,  formIsBeingPublished: true } );
      let that = this;
      let body_json = {
        user_id: this.state.currentUserId, 
        form_id: this.state.formID,
        form_title: this.state.formTitle, 
        form_source: this.state.formSource, 
        form_source_url: this.state.formSourceUrl, 
        form_fields_attributes: this.state.fields, 
        options_for_selects: this.state.optionLists,
        languages: this.state.languages,
        use_secondary_language: this.state.useSecondaryLanguage
      };
      console.log(body_json);
      let body_string = JSON.stringify(body_json);
      fetch(this.state.formBuilderCreateUrl,{
        method: 'POST',
        headers: {
                'Accept': 'application/json',
                'Content-Type': ' application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': this.props.csrf_token
              },
        body: body_string
      }).then(response => {
        response.json().then(res =>{
          if(res.saved){
            that.setState({...this.state, 
              formIsBeingPublished: false,
              formHasBeenPublished: true,
              surveyRecord: res.record,
              publishedError: "",
              formID: res.form_id,
              operationType: "edit",
              formBuilderCreateUrl: res.form_builder_create_url
            })
          }
          else if (res.status === 500){
            that.setState({...this.state, 
              formIsBeingPublished: false,
              failedToPublish: true,
              publishedError: "Error occur: please contact administrator"
            })
          }
          else{
            let errors = res.errors;
            if( Array.isArray(errors)){
              errors = errors.join(",")
            }
            else{
              errors = "";
            }
            that.setState({...this.state, 
              formIsBeingPublished: false,
              failedToPublish: true,
              publishedError: errors
            })
          }
        })
      })
    }
  }

  buildAnother(){
    document.getElementById("builder-title").value = "";
    this.setState({
      formTitle: 'Form Builder',
      formSource: '',
      formSourceUrl: '',
      currentlyBuilding: false,
      fields: [],
      formIsBeingPublished: false,
      formHasBeenPublished: false,
      failedToPublish: false,
      surveyRecord: null,
      //nextFieldIndex: 100,
      editingFieldIndex: -1,
      optionLists: {},
      editingOption: {},
      fieldEditMode: false,
      optionEditMode: false,
      currentOptionList: '',
      gettingLocation: false,
      currentField: {
        name: '',
        label: '',
        type: '',
        index: 100
      },
      currentOption: {
        name: '',
        label: ''
      }

    });
  }

  closeDialog(){
    this.setState({...this.state, formHasBeenPublished:false,failedToPublish:false})
  }

  getLocation(event){
    this.setState({...this.state, 
      gettingLocation: true
    })
    let output = event.target.parentElement.lastChild;
    let newRecord = this.state.record;
    let name = output.name;
    if (!navigator.geolocation){
      output.value = "Geolocation is not supported by your browser";
      return;
    }
    let success = (position) => {
      output.value = 'Latitude: ' + position.coords.latitude + ' Longitude: ' + position.coords.longitude
      this.setState({...this.state, 
        gettingLocation: false
      })
    }

    let error = () => {
      output.value = "Unable to retrieve your location";
      this.setState({...this.state, 
        gettingLocation: false
      })
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }

  getLanguageByValue(lang){
    if (lang){
      return lang["language"];
    }
    return "";
  }

  render () {
    if(!this.state.featuresIsSupported){
      return (
        <div className="container-fluid form-builder">
          <div className="row">
            <div className="mask active" role="dialog">
            <div className="active builder-modal" role="alert">
             <div className="modal-dialog">
               <div>
               <p><span>Sorry, this form has features not supported by the edit form page. Try again later!</span></p>
               </div>
             </div>
            </div>
            </div>
          </div>
        </div>
      );
    }
    // setup for the response modals
    let publishingStatus;
    if(this.state.failedToPublish){
      // if there is an error on the server when creating the datset, show this modal
      let publishedError = this.state.publishedError.slice(0,this.state.publishedError.length > 200? 200: this.state.publishedError.length)
      publishingStatus =
      <div className="mask active" role="dialog">
      <div className="active builder-modal" role="alert">
      <button type="button" className="close" aria-label="Close" onClick={(event) => this.closeDialog(event)}><span>&times;</span></button>
       <div className="modal-dialog">
         <div>
         <p>Something terrible has happened! But we have recorded the error in our logs, and we will investigate the problem. 
           <br/>
           Error: {publishedError}....
           <br/>
          </p>
         </div>
       </div>
      </div>
      </div>
    }else if (this.state.formHasBeenPublished){
        // process was successful, show this modal
      publishingStatus =
      <div className="mask active" role="dialog">
        <div className="active builder-modal success col-sm-10" role="alert">
        <button type="button" className="close" aria-label="Close" onClick={(event) => this.closeDialog(event)}><span>&times;</span></button>
        <div className="modal-dialog">
          <div>
          <h2><span className="big">Nicely done! </span><br></br>Your form is built and launched!</h2>
          <div className="row">
            <div className="col-sm-6">
            <div className="bottom-space">
            <h4>Web</h4>
            <p>You can review the public web form now or view the data manager page once you start collecting surveys <a href={this.state.rootUrl+ "surveys/" + this.state.surveyRecord["id"] + "/records"}>here.</a> </p>
            <a href={this.state.rootUrl + "surveys/" + this.state.surveyRecord["id"] + "/form?ft=" + this.state.surveyRecord["web_form_token"]} target="_blank" className="btn btn-primary">Public Web Form</a>
            </div>
            <h4>Android & iOS</h4>
            <p>To help you collect more data, make sure to get the mobile app, <strong>Standard Data</strong>, from the <a href="https://play.google.com/store/apps/details?id=com.sdkmobileapp">Google Play Store</a> <strong>and</strong> the <a href="https://itunes.apple.com/us/app/secure-data-kit/id1221587450?ls=1&mt=8">Apple App Store</a> now!</p>
            </div>
            <div className="col-sm-6">
            <div className="bottom-space">

            <p>If you’re already collecting data in the field, please have your team update their forms as soon as possible!</p></div>
            <h4>Why Standard Data for surveys?</h4>
            <ul>
              <li>Works anywhere in the world ( 54 countries and counting regardless of internet access! )</li>
              <li>Supports longitudinal studies</li>
              <li>Export the data for analysis</li>
              </ul>
            </div>
          </div>
          </div>
        </div>
        </div>
        </div>
    }
    if(this.state.browserSupported){
      return (
        <div className="container-fluid form-builder">
          <div className="row">
          {publishingStatus}
            <div className="col-md-3 border-right">
              <div className="h-100">
                <FieldList
                  operationType={this.state.operationType}
                  projectUrl={this.state.projectUrl}
                  currentlyBuilding={this.state.currentlyBuilding}
                  startBuilding={this.startBuilding}
                  updateTitle={this.updateTitle}
                  updateSource={this.updateSource}
                  updateSourceUrl={this.updateSourceUrl}
                  fields={this.state.fields}
                  defaultLanguage={this.getLanguageByValue(this.state.languages[0])}
                  secondaryLanguage={this.getLanguageByValue(this.state.languages[1])}
                  useSecondaryLanguage={this.state.useSecondaryLanguage}
                  updateUseSecondaryLanguage={this.updateUseSecondaryLanguage}
                  updateMultiLanguage={this.updateMultiLanguage}
                  controlField={this.controlField}
                  groupControlField={this.groupControlField}
                  formTitle={this.state.formTitle}
                  formSource={this.state.formSource}
                  formSourceUrl={this.state.formSourceUrl}
                  newField={this.newField}
                />
              </div>
            </div>
              <FieldSettings
                operationType={this.state.operationType}
                fieldOperation={this.state.fieldOperation}
                currentlyBuilding={this.state.currentlyBuilding}
                startBuilding={this.startBuilding}
                submitField={this.submitField}
                cancelSubmit={this.cancelSubmit}
                updateCurrentField={this.updateCurrentField}
                controllingField={this.state.controllingField}
                currentField={this.state.currentField}
                optionLists={this.state.optionLists}
                startOptionList={this.startOptionList}
                addOption={this.addOption}
                controlOption={this.controlOption}
                optionEditMode={this.state.optionEditMode}
                fieldEditMode={this.state.fieldEditMode}
                selectOptionsList={this.selectOptionsList}
                currentOptionList={this.state.currentOptionList}
                currentOption={this.state.currentOption}
                currentOptionIndex={this.state.currentOptionIndex}
                fields={this.state.fields}
                newGroupIndex={this.state.newGroupIndex}
                defaultLanguage={this.getLanguageByValue(this.state.languages[0])}
                secondaryLanguage={this.getLanguageByValue(this.state.languages[1])}
                useSecondaryLanguage={this.state.useSecondaryLanguage}
              />
              <FormPreview
                publishForm={this.publishForm}
                currentlyBuilding={this.state.currentlyBuilding}
                formTitle={this.state.formTitle}
                fields={this.state.fields}
                optionLists={this.state.optionLists}
                getLocation={this.getLocation}
                formIsBeingPublished={this.state.formIsBeingPublished}
                useSecondaryLanguage={this.state.useSecondaryLanguage}
                languages={this.state.languages}
                okToSubmit={this.getOkToSubmit()}
              />
          </div>
        </div>
      );
    }
    else{
      return (
        <div className="container-fluid form-builder">
          <div className="row">
            <div className="mask active" role="dialog">
            <div className="active builder-modal" role="alert">
             <div className="modal-dialog">
               <div>
               <p><span>We're sorry, but it looks like the web browser that you are using does not support the sweet features used by the form builder. Please try upgrading to a different browser. We suggest Google Chrome.</span></p>
               </div>
             </div>
            </div>
            </div>
          </div>
        </div>

      );
    }
  }

  getOkToSubmit(){
    let valid = true;
    if (this.state.formTitle == ""){
      valid = false
    } 
    else if( this.state.languages.length == 1 && this.state.languages[0].language == ""){
      valid = false
    }
    else if (this.state.languages.length > 1 && (!this.state.languages[1] || this.state.languages[1].language == "")){
      valid = false
    }
    else if (this.state.fields.length == 0){
      valid = false
    }
    return valid
  }
}

export default FormBuilder
