import React from "react"
import PropTypes from "prop-types"


class SelectFieldIndex extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      options: props.options,
      fieldIndex: props.fieldIndex,
      editMode: props.editMode,
      onChange: props.updateValue
    }
    this.selectOptionsList = this.selectOptionsList.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state, 
      options: nextProps.options, 
      fieldIndex: nextProps.fieldIndex,
      editMode: nextProps.editMode
    })
  }
  selectOptionsList(event){
    event.preventDefault();
    this.state.onChange(event)
  }

  getListNameOptions(){
    let options = this.state.options.map(e=>{ return (<option key={`option${e.index}`} value={e.index}>{e.name}</option>); });
    return options;
  }

  

  render(){
    return (
      <div>
        <h2>Field location</h2>
        <select value={this.state.fieldIndex} className="form-control" name="index" multiple={false} onChange={(event) => this.selectOptionsList(event)}>
          {this.getListNameOptions()}
        </select>
        <small>"Start field" will insert the field at the beginning of the form. Selecting other option will insert the field after the selected field.</small>
      </div>
    );
  }
}


export default SelectFieldIndex
