import React from "react"
import PropTypes from "prop-types"
class OptionListItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      option: props.option,
      showOptionControls: false,
      controlOption: props.controlOption
    }
    this.showOptionControls = this.showOptionControls.bind(this);
    this.hideOptionControls = this.hideOptionControls.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      option: nextProps.option
    })
  }

  hideOptionControls() {
    this.setState({
      showOptionControls: false
    })
  }

  showOptionControls() {
    this.setState({
      showOptionControls: true
    })
  }

  controlOption(event){
    event.preventDefault();
    let control = event.target.getAttribute('name');
    this.state.controlOption(this.state.option, control);
  }

  format(label) {
    let maxLength = 18;
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "...";
    } else {
      return label;
    }
  };

  render () {
    return (
      <li className="list-option" onMouseEnter={this.showOptionControls} onMouseLeave={this.hideOptionControls}>
        {this.format(this.state.option["label"][0].value)}
        {
          this.state.showOptionControls &&
            <div className="controls pull-right">
              <a href="#" className="edit" onClick={(event) => this.controlOption(event)}>
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106428/pencil-alt-regular.svg" name="option-edit" alt="Pencil" className="icon"/>&nbsp;</a>
              <a href="#" className="delete" onClick={(event) => this.controlOption(event)}>
              <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/106428/trash-alt-solid.svg" name="option-delete" alt="Trash" className="icon"/>&nbsp;</a>
            </div>
        }
      </li>
    );
  }
}

export default OptionListItem
