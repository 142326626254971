
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Field from './Field';
import {evalCalculation, isRelevant} from './lib/EvalExpressHelper';
import {getGroup, getGroupEndIndex, isEmpty} from './lib/SurveyHelper';
import { v4 as uuidv4 } from 'uuid';

function nextFieldIndex(fieldIndex, fields, record) {
  for (var i = fieldIndex + 1; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === 'group') {
      if (isRelevant(record, field)) {
        if (field.appearance === 'field-list') {
          return i;
        }
      }
      else{
        i = getGroupEndIndex(fields, i, field.name);
      }
    } else if (field.type === 'end_group') {
      continue;
    } else if ( getGroup(fields, field) && getGroup(fields, field).appearance === 'field-list') {
      // Skip fields that are in a field-list (single page) group
      continue;
    } else if (isVisible(field) && isRelevant(record, field)) {
      return i;
    }
  }
  return fields.length;
}

function prevFieldIndex(fieldIndex, fields, record) {
  for (var i = fieldIndex - 1; i > -1; i--) {
    let field = fields[i];
    if (field.type === 'group') {
      if (isRelevant(record, field)) {
        // Group is relevant
        if (field.appearance === 'field-list') {
          return i;
        }
      }
      else{
        i = getGroupEndIndex(fields, i, field.name);
      }
    } else if (field.type === 'end_group') {
    } else if (getGroup(fields, field) && getGroup(fields, field).appearance === 'field-list') {
    } else if (isVisible(field) && isRelevant(record, field)) {
      return i;
    }
  }
  return false;
}

function isVisible(field) {
  let name = field.name;
  let groupName = field.group_name;
  let type = field.type;
  let metadata = field.metadata;
  if (type === "group" || type === "end_group") {
    return false;
  } else if (groupName === "meta") {
    return false;
  } else {
    return true;
  }
}

class PagingRepeater extends React.Component {

  constructor(props){
    super(props);
    this.addNewRecord = this.addNewRecord.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCalculation = this.handleCalculation.bind(this);
    this.prevField = this.prevField.bind(this);
    this.nextField = this.nextField.bind(this);
    this.addOrEndRepeater = this.addOrEndRepeater.bind(this);
    this.closeRepeater = this.closeRepeater.bind(this);
    this.lastRecordLastField = this.lastRecordLastField.bind(this);
    this.handleRemoveValue = this.handleRemoveValue.bind(this);
  }

  handleChange(event, recordIndex) {
    let records = [...this.props.repeaterRecords]
    let newRecord = records[recordIndex];
    newRecord[event.target.name] = event.target.value;
    let relevantRecord = this.relevantRecord(newRecord)
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.form.fields[this.props.repeaterBeginIndex], records)
  }

  handleFileChange(event, recordIndex) {
    let records = [...this.props.repeaterRecords];
    let newRecord = records[recordIndex];
    let files = {...this.props.files};
    if (event.target.files.length > 0){
      let extensionName = event.target.files[0].name.split(".").pop();
      let filename = `${Date.now()}.${extensionName}`;
      
      files[filename] = event.target.files[0];
      newRecord[event.target.name] = filename;
    }
    else{
      newRecord[event.target.name] = "";
    }

    this.props.updateFiles(files);
    let relevantRecord = this.relevantRecord(newRecord);
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }
  
  handleMultiSelectChange(event, recordIndex){
    var options = event.target.options;
    let name = event.target.name;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    let records = [...this.props.repeaterRecords]
    let newRecord = records[recordIndex];
    newRecord[name] = value;

    let relevantRecord = this.relevantRecord(newRecord)
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.form.fields[this.props.repeaterBeginIndex], records)
  }

  handleGroupedCheckboxes(field, option, options, event, recordIndex){
    let name = event.target.name;
    let value = event.target.value
    let records = [...this.props.repeaterRecords]
    let newRecord = records[recordIndex];
    if(typeof newRecord[name] === 'undefined'){
      newRecord[name] = []
    }
    else{
      if(option.filter){
        // check if existing checked values should be filtered
        for (let i = 0; i < newRecord[name].length; i++){
          for (let x = 0; x < options.length; x++){
            if (newRecord[name][i] === options[x].name){
              if (field.group_name){
                let groupFilter = field.group_name + "_" + options[x].filter.field;
                if(newRecord[groupFilter] !== options[x].filter.value){
                  let index = newRecord[name].indexOf(newRecord[name][i]);
                  if (index > -1) {
                    newRecord[name].splice(index, 1);
                  }
                }
              }
              else if(newRecord[options[x].filter.field] !== options[x].filter.value){
                let index = newRecord[name].indexOf(newRecord[name][i]);
                if (index > -1) {
                  newRecord[name].splice(index, 1);
                }
              }
            }
          }
        }
      }
    }
    if(newRecord[name].includes(value)){
      let index = newRecord[name].indexOf(value);
      if (index > -1) {
        newRecord[name].splice(index, 1);
      }
    }else{
      newRecord[name].push(value)
    }

    let relevantRecord = this.relevantRecord(newRecord)
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.form.fields[this.props.repeaterBeginIndex], records)
  }

  handleRemoveValue(event, recordIndex){
    let records = [...this.props.repeaterRecords];
    let newRecord = records[recordIndex];
    let files = {...this.props.files};
    delete files[newRecord[event.target.value]];
    delete newRecord[event.target.value];

    this.props.updateFiles(files);
    let relevantRecord = this.relevantRecord(newRecord);
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }

  handleCalculation(recordCopy){
    let fields = this.props.form.fields;
    let record = {...recordCopy};
    let startIndex = this.props.setting.fieldIndex
    let endIndex = getGroupEndIndex(fields, startIndex, fields[startIndex].name);
    let parentRecord = this.props.parentRecord;
    for(let cnt=startIndex; cnt < endIndex; cnt++){
      let field = fields[cnt];
      if(!isRelevant({...parentRecord, ...recordCopy}, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }

      let ismeta = field.name == "meta_instance_id"
      if(["group", "end_group"].includes(field.type) == false && ismeta == false) {
        if (field.calculate){
          let val = evalCalculation({...parentRecord, ...record}, field.calculate)
          if(val != record[field.name]){
            record[field.name] = val
          }
        }
      }
    }
    return record;
  }

  relevantRecord(recordCopy){
    let record = {};
    let fields = this.props.form.fields;
    let startIndex = this.props.setting.fieldIndex;
    let endIndex = getGroupEndIndex(fields, this.props.repeaterBeginIndex, fields[this.props.repeaterBeginIndex].name);
    let parentRecord = this.props.parentRecord;
    for(let cnt = this.props.repeaterBeginIndex; cnt < endIndex; cnt++){
      let field = fields[cnt];
      if(!isRelevant({...parentRecord, ...recordCopy}, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }

      let excludeType = ["group", "end_group"];
      let includedString = field.type =="string" && field.name !== "meta_instance_id" && !field.readonly && !field.metadata
      if(includedString || excludeType.includes(field.type) == false){
        record[field.name] = recordCopy[field.name];
      }

      if(field.type == "barcode"){
        record[`${field.name}_confirmation`] = recordCopy[`${field.name}_confirmation`];
      }
    }
    record["id"] = recordCopy["id"];
    return record;
  }

  getLocation(event, recordIndex){
    this.setState({
      gettingLocation: true
    });
    
    let output = event.target.parentElement.lastChild;
     let records = [...this.props.repeaterRecords]
    let newRecord = records[recordIndex];
    let name = output.name;
    if (!navigator.geolocation){
      output.value = "Geolocation is not supported by your browser";
      return;
    }
    let success = (position) => {
      output.value = `Latitude: ${position.coords.latitude} Longitude: ${position.coords.longitude} Altitude: ${position.coords.altitude} Accuracy: ${position.coords.accuracy}`
      newRecord[name + "_lat"] = position.coords.latitude
      newRecord[name + "_lng"] = position.coords.longitude
      newRecord[name + "_alt"] = position.coords.altitude
      newRecord[name + "_acc"] = position.coords.accuracy

      this.setState({
        gettingLocation: false,
        record: newRecord
      })
    }

    let error = () => {
      output.value = "Unable to retrieve your location";
      this.setState({
        gettingLocation: false
      })
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }

  addNewRecord(event){
    event.preventDefault();
    let records = [...this.props.repeaterRecords]
    records.push({id: uuidv4()});
    let newRecordIndex = records.length - 1;
    this.props.updateRepeaterRecords(this.props.form.fields[this.props.repeaterBeginIndex], records);

    let nextIndex = nextFieldIndex(this.props.repeaterBeginIndex, this.props.form.fields, this.props.parentRecord);
    let setting = {...this.props.setting,recordIndex: newRecordIndex, fieldIndex:  nextIndex}
    this.props.updateRepeaterSetting(setting)
  }

  removeRecord(event, recordIndex){
    event.preventDefault();
    let records = [...this.props.repeaterRecords]
    records.splice(recordIndex, 1);
    this.props.updateRepeaterRecords(this.props.repeaterField, records);

    let newRecordIndex = records.length - 1;
    let mergeRecord ={...this.props.parentRecord, ...this.props.repeaterRecords[newRecordIndex]}
    let newFieldIndex = nextFieldIndex(this.props.repeaterBeginIndex, this.props.form.fields, mergeRecord);
    let setting = {...this.props.setting, recordIndex: newRecordIndex, fieldIndex: newFieldIndex}
    this.props.updateRepeaterSetting(setting)
  }

  nextField() {
    let valid = this.validateField(this.props.form.fields[this.props.setting.fieldIndex])
    if (!valid) {
      return;
    }
    let setting = this.props.setting;
    let nextIndex = setting.fieldIndex;
    let mergeRecord = mergeRecord;
    if (this.props.repeaterRecords.length > 0){
      mergeRecord ={...this.props.parentRecord, ...this.props.repeaterRecords[setting.recordIndex]}
      nextIndex = nextFieldIndex(this.props.setting.fieldIndex, this.props.form.fields, mergeRecord);
    }
    else{
      mergeRecord ={...this.props.parentRecord};
      nextIndex = nextFieldIndex(this.props.setting.fieldIndex, this.props.form.fields, mergeRecord);
    }

    if(nextIndex <= this.props.repeaterEndIndex){
      // let valid = this.validateField(this.props.form.fields[this.props.setting.fieldIndex])
      // if (valid) {
        let setting = {...this.props.setting, fieldIndex: nextIndex}
        this.props.updateRepeaterSetting(setting);
      //}
    }
    else if(nextIndex > this.props.repeaterEndIndex && setting.recordIndex == (this.props.repeaterRecords.length-1)){
      let setting = {...this.props.setting, fieldIndex: this.props.repeaterEndIndex}
      this.props.updateRepeaterSetting(setting);
    }
    else if(nextIndex > this.props.repeaterEndIndex && setting.recordIndex < (this.props.repeaterRecords.length-1)){
      mergeRecord ={...this.props.parentRecord, ...this.props.repeaterRecords[setting.recordIndex+1]}
      let fieldIndex = nextFieldIndex(this.props.repeaterBeginIndex, this.props.form.fields, mergeRecord);
      setting = {fieldIndex: fieldIndex, recordIndex: (setting.recordIndex + 1)};
      this.props.updateRepeaterSetting(setting)
    }
  }

  prevField() {
    let setting = this.props.setting;
    let prevIndex = setting.fieldIndex;
    let mergeRecord = {};
    if (this.props.repeaterRecords.length > 0){
      mergeRecord ={...this.props.parentRecord, ...this.props.repeaterRecords[this.props.setting.recordIndex - 1]}
      prevIndex = prevFieldIndex(this.props.setting.fieldIndex, this.props.form.fields, mergeRecord);
    }
    else{
      mergeRecord ={...this.props.parentRecord};
      prevIndex = prevFieldIndex(this.props.setting.fieldIndex, this.props.form.fields, mergeRecord);
    }

    if(prevIndex > this.props.repeaterBeginIndex){
        setting = {...this.props.setting, fieldIndex: prevIndex};
        this.props.updateRepeaterSetting(setting);
    }
    else if(prevIndex <= this.props.repeaterBeginIndex &&  (!setting.recordIndex || setting.recordIndex == 0)){
      this.props.beforeRepeaterForm(this.props.repeaterBeginIndex);
    }
    else if(prevIndex <=this.props.repeaterBeginIndex &&  setting.recordIndex > 0){
      let fieldIndex = prevFieldIndex(this.props.repeaterEndIndex, this.props.form.fields, mergeRecord);
      setting = {fieldIndex: fieldIndex, recordIndex: (setting.recordIndex - 1)};
      this.props.updateRepeaterSetting(setting);
    }
  }

  closeRepeater(){
    this.props.afterRepeaterForm(this.props.repeaterEndIndex);
  }
  
  lastRecordLastField(){
    let recordIndex = this.props.repeaterRecords.length - 1;
    let mergeRecord ={...this.props.parentRecord, ...this.props.repeaterRecords[recordIndex]}
    let prevIndex = prevFieldIndex(this.props.repeaterEndIndex, this.props.form.fields, mergeRecord);
    
    let setting = {...this.props.setting, fieldIndex: prevIndex, recordIndex: recordIndex}
    this.props.updateRepeaterSetting(setting);
  }

  validateField(field) {
    if (field.type !== 'end_group' && field.type !== 'group') {
      let type = field.type;
      let name = field.name;
      let label = field.labels[this.props.languageIndex].value;
      let value = this.props.repeaterRecords[this.props.setting.recordIndex][name];
      let required = field.required;
      let constraint = field.constraint;

      // if(constraint){
      //   let constraintSatisfied = this.evaluateConstraint(constraint, value);
      //   if (constraintSatisfied == false){
      //     alert(field.constraint_labels[this.props.languageIndex].value)
      //     return false
      //   }
      // }

      switch(type) {
        case "string":
        break;
        case "select":
        break;
        case "select1":
        break;
        case "int":
        break;
        case "decimal":
        break;
        case "dateTime":
        break;
        case "date":
        break;
        case "time":
        break;
        case "geopoint":
          if (this.props.repeaterRecords[name.concat('_lat')]){
            value = this.props.repeaterRecords[name.concat('_lat')].toString();
          }
        break;
        case "binary":
        break;
        case "barcode":
          if (this.isVisible(field)) {
            let confirmationValue = document.getElementById(`${name}_confirmation`).value
            if(isEmpty(confirmationValue)){
              alert("Please confirm the barcode.");
              return false
            }
            if(confirmationValue !== value){
              alert("The values entered for the Barcode do not match. Please confirm the barcode number.");
              return false
            }
            else{
              return true
            }
          }
        break;
      }
      // run this check for all field types
      if (required && isVisible(field)) {
        if (isEmpty(value)) {
          alert(label + " is required.");
          return false;
        }
      }
    }
    return true;
  }

  render(){
    if(this.props.repeaterRecords.length == 0){
      return this.addOrEndRepeater();
    }
    else if (this.props.setting.fieldIndex > this.props.repeaterBeginIndex && this.props.setting.fieldIndex < this.props.repeaterEndIndex){
        return this.renderField();
    }
    else{
      return this.addOrEndRepeater();
    }
  }

  renderField(){
    let record =  this.props.repeaterRecords[this.props.setting.recordIndex];
    let recordID = record.id;
    let groupLabel = `Record ${this.props.setting.recordIndex + 1}: `;
    let field = this.props.form.fields[this.props.setting.fieldIndex];
    let isGroupVisible = false;
    let currentGroup = getGroup(this.props.form.fields, field);
    if (currentGroup && currentGroup.appearance !== 'field-list') {
      if (currentGroup.labels) {
        groupLabel =`Record ${this.props.setting.recordIndex + 1}: ${currentGroup.labels[this.props.languageIndex].value}`;
      }
      isGroupVisible = true;
    }

    let renderField = (<Field key={`${recordID}_${field.name}`}
      fieldItem={field} 
      languageIndex= {this.props.languageIndex}
      groupLabel={groupLabel}
      isGroupVisible={isGroupVisible}
      record={record}
      files={this.props.files}
      handleChange={e=>{ this.handleChange(e, this.props.setting.recordIndex)}}
      handleMultiSelectChange={e=>{ this.handleMultiSelectChange(e, this.props.setting.recordIndex)}}
      handleGroupedCheckboxes={(field,option,options,e)=>{
          this.handleGroupedCheckboxes(field, option, options, e, this.props.setting.recordIndex)
        }
      }
      handleFileChange={e=>{ this.handleFileChange(e, this.props.setting.recordIndex)}}
      handleRemoveValue={e=>{ this.handleRemoveValue(e, this.props.setting.recordIndex)}}
      getLocation={this.getLocation}
      gettingLocation={this.gettingLocation}
      />);

    return (<div>
        {renderField}
        <div className="form-buttons">
          <button key="prev-button" type="button" className="yo btn btn-default prev-button" onClick={this.prevField}>PREVIOUS</button>
          <button key="next-button" type="button" className="yo btn btn-default next-button" onClick={this.nextField}>NEXT</button>
        </div>
      </div>);
  }

  addOrEndRepeater(){
    let prev = (<button key="prev-button" type="button" className="yo btn btn-default prev-button" onClick={this.lastRecordLastField}>PREV</button>);
    if(this.props.repeaterRecords.length === 0){
      prev = (<button key="prev-button" type="button" className="yo btn btn-default prev-button" onClick={this.prevField}>PREV</button>);
    }

    let addNewRecord = (<div className="form-buttons">
        <button key="new-button" type="button" className="yo btn btn-default next-button" onClick={this.addNewRecord}>ADD NEW RECORD</button>
      </div>)
    if(this.props.fixRecordLength){
      let currentGroup = this.props.form.fields[this.props.repeaterBeginIndex];
      let groupLabel = "REPEATER";
      if (currentGroup.labels) {
        groupLabel = currentGroup.labels[this.props.languageIndex].value;
      }
      addNewRecord = <h1>{`${groupLabel} END`}</h1>;
    }
    return (<div>
      <br/>
      <br/>
      {addNewRecord}
      <br/>
      <br/>
      <div className="form-buttons">
        {prev}
        <button key="next-button" type="button" className="yo btn btn-default next-button" onClick={this.closeRepeater}>NEXT</button>
      </div>
    </div>)
  }
}

export default PagingRepeater

PagingRepeater.propTypes = {

  languageIndex: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  parentRecord: PropTypes.object.isRequired,
  repeaterRecords: PropTypes.array.isRequired,
  setting: PropTypes.object,
  repeaterEndIndex: PropTypes.number.isRequired,
  repeaterBeginIndex: PropTypes.number.isRequired,
  rptStartAtInputField: PropTypes.bool,
  updateRepeaterRecords: PropTypes.func.isRequired,
  beforeRepeaterForm: PropTypes.func.isRequired,
  afterRepeaterForm: PropTypes.func.isRequired,
  updateRepeaterSetting: PropTypes.func.isRequired
};

