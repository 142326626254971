import React, { Component } from 'react';
import Label from '../Label';
import GroupLabel from '../GroupLabel';

class Photo extends Component {
  constructor(props){
    super(props);
  }

  render() {
    let image = null;
    if (this.props.value instanceof File){
      image = (
      <img height={400} width={400} src={URL.createObjectURL(this.props.value)} /> 
      );
      return (
        <div className="form-group">
          <GroupLabel label={this.props.groupLabel} visible={this.props.groupLabelVisible}></GroupLabel>
          <Label htmlFor={this.props.name} label={this.props.label} required={this.props.required} hint={this.props.hint}/>
          <button value={this.props.name} key="submit-button" type="submit" className="yo btn btn-default" onClick={e=> {e.preventDefault(); this.props.handleRemoveValue(e); }} >Remove Image</button>
          {image}
        </div>
      );
    }
    else{
      return (
        <div className="form-group">
          <GroupLabel label={this.props.groupLabel} visible={this.props.groupLabelVisible}></GroupLabel>
          <Label htmlFor={this.props.name} label={this.props.label} required={this.props.required} hint={this.props.hint}/>
          <input id={this.props.name}
          name={this.props.name}
          tabIndex={this.props.tabIndex} 
          className="form-control" 
          type="file" 
          onChange={this.props.handleFileChange} 
          accept=".jpg, .jpeg, .png, .gif"
            />
          {image}
        </div>
      );
    }

  }
}
export default Photo