import React, { Component } from 'react';
import Label from './Label';
import GroupLabel from './GroupLabel';
import Video from './widgets/Video';
import Image from './widgets/Image';
import PropTypes from 'prop-types';
import {evalRelevant, evalOptionFilter, evalCalculation} from './lib/EvalExpressHelper'
class Field extends Component {

  render() {
    let that = this;
    let field = this.props.fieldItem;
    let groupLabel = this.props.groupLabel;
    let isGroupVisible = this.props.isGroupVisible;
    let readonly = field.readonly;
    let name = field.name;
    let metadata = field.metadata;
    let record = this.props.record;
    let tabIndex = 0;
    let value = "";
    let confirmationValue = "";
    if ("undefined" !== typeof this.props.record[name]  && this.props.record[name]  !== null) {
      value = this.props.record[name] 
    }

    let label;
    let hint;
    if (field.labels) {
      label = field.labels[this.props.languageIndex].value;
    }
    if (field.hints && field.hints.length > 0) {
      hint = field.hints[this.props.languageIndex].value;
    }

    switch(field.type){
      case "string":
        if(name === "intro_note" || name === "followup_intro_note" || field.readonly){
          return (
            <div className="">
              <h3>{label}</h3>
            </div>
          );
          break;
        }
        if (name !== "meta_instance_id") {
          if (metadata) {
            if (name == 'email') {
              break;
            }
          }

          if (readonly) {
            return (
              <div className="form-group">
                <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
                <Label label={label} required={field.required} hint={hint}/>
                <input className="form-control" name={name} type="text" readOnly/>
              </div>
            );
          }
          else if (field.calculate){
            return (
              <div className="form-group">
                <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
                <Label label={label} hint={hint}/>
                <label>{value}</label>
              </div>
            );
          }
          else {
            return (
              <div className="form-group">
                <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
                <Label label={label} required={field.required} hint={hint}/>
                <input tabIndex={tabIndex} className="form-control" name={name} type="text" onChange={this.props.handleChange} defaultValue={value}/>
              </div>
            );
          }
        }
        break;
      case "int":
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <input tabIndex={tabIndex} className="form-control" name={name} type="number" onChange={this.props.handleChange} defaultValue={value}/>
          </div>
        );
        break;
      case "decimal":
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <input tabIndex={tabIndex} className="form-control" name={name} type="number" step="0.0000000001" onChange={this.props.handleChange} defaultValue={value}/>
          </div>
        );
        break;
      case "text":
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <textarea tabIndex={tabIndex} className="form-control" name={name} onChange={this.props.handleChange} defaultValue={value}>
            </textarea>
          </div>
        ); 
        break;
      case "select":
        let checkBoxOptions = [];
        let options = field.options;
        let languageIndex = this.props.languageIndex;
        let multiOptionKey;
        let selectOption = value
        options.forEach(function(option, i) {
          let checked;
          if (typeof selectOption === 'undefined'){
            checked = false;
          }else{
            checked = selectOption.includes(option.name);
          }

          if(field.options_filter){
            if(evalOptionFilter(record, option, field.options_filter)){
              multiOptionKey = (i + 1).toString();
              
              checkBoxOptions.push(<div key={multiOptionKey.concat("-div")}><label><input tabIndex={tabIndex}  className="form-checkbox" key={multiOptionKey.concat("-option")} onChange={(event) => that.props.handleGroupedCheckboxes(field, option, options, event)} type="checkbox" name={name} value={option.name} defaultChecked={checked} />{option.labels[languageIndex].value}</label></div>)
            }
          }
          else{
            multiOptionKey = (i + 1).toString();
            checkBoxOptions.push(<div key={multiOptionKey.concat("-div")}><label><input tabIndex={tabIndex}  className="form-checkbox" key={multiOptionKey.concat("-option")} onChange={(event) => that.props.handleGroupedCheckboxes(field, option, options, event)} type="checkbox" name={name} value={option.name} defaultChecked={checked} />{option.labels[languageIndex].value}</label></div>)
          }
        });
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            {checkBoxOptions}
          </div>
        );
        break;
      case "select1":
        let optionsForSelect1 = [<option key={"empty-option"} value="">-select-</option>];
        let options1 = field.options;
        let languageIndex1 = this.props.languageIndex;
        let optionKey;

        
        options1.forEach( function(option, i){
          if(field.options_filter){
            if(evalOptionFilter(record, option, field.options_filter)){
              optionKey = (i + 1).toString();
              optionsForSelect1.push(<option key={optionKey.concat("-option")} value={option.name}>{option.labels[languageIndex1].value}</option>)
            }
          }
          else{
            optionKey = (i + 1).toString();
            optionsForSelect1.push(<option key={optionKey.concat("-option")} value={option.name}>{option.labels[languageIndex1].value}</option>)
          }
        })
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <select tabIndex={tabIndex} defaultValue={value} className="form-control" name={name} multiple={false} onChange={this.props.handleChange}>
              {optionsForSelect1}
            </select>
          </div>
        );
        break;
      case "dateTime":
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <input tabIndex={tabIndex} className="form-control" type={"datetime-local"} name={name} onChange={this.props.handleChange} value={value}/>
          </div>
        );
        break;
      case "date":
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <input tabIndex={tabIndex} className="form-control" type={"date"} name={name} onChange={this.props.handleChange} value={value}/>
          </div>
        );
        break;
      case "time":
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <input tabIndex={tabIndex} className="form-control" name={name} type="time" onChange={this.props.handleChange} value={value}/>
          </div>
        );
        break;
      case "geopoint":
        let geoSpinner = <div className="form-buttons"><div className="web-form-spinner"><div className="double-bounce1"></div><div className="double-bounce2"></div></div></div>
        let geoDescription = "";
        if(record[name + "_lat"]){
          geoDescription = `Latitude: ${record[name + "_lat"]} Longitude: ${record[name + "_lng"]} Altitude: ${record[name + "_alt"]} Accuracy: ${record[name + "_acc"]}`
        }
        return (
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <div className="form-buttons">
              <button type="button" name={name} className="yo btn btn-default" onClick={this.props.getLocation}>{(this.props.gettingLocation) ? geoSpinner : "Record Location"}</button>
            </div>
            <textarea rows={2} className="form-control" name={name} type="text" readOnly  value={geoDescription}/>
          </div>
        );
        break;
      case "barcode":
        let confirmationValue = "";
        let uncheckValue = record[`${name}_confirmation`] 
        if ("undefined" !== typeof uncheckValue  && uncheckValue  !== null) {
          confirmationValue = uncheckValue
        }
        return(
          <div className="form-group">
            <GroupLabel label={groupLabel} visible={isGroupVisible}></GroupLabel>
            <Label label={label} required={field.required} hint={hint}/>
            <input tabIndex={tabIndex} className="form-control" name={name} type="text" onChange={this.props.handleChange} placeholder="Enter barcode numbers."  value={value}/>
            <small>
              <label>
                {`${label} confirmation`}
              </label>
            </small>
            <input required className="form-control" name={`${name}_confirmation`} onChange={this.props.handleChange} type="text" placeholder="Confirm barcode numbers"  value={confirmationValue}/>
          </div>
        )
        break;
      case "video":
        let video_value = this.props.files && value ? this.props.files[value]: "";
        return (<Video groupLabel={groupLabel} key={"video"+name}
          isGroupVisible={isGroupVisible} 
          label={label} 
          required={field.required} 
          hint={hint} 
          name={name} 
          handleFileChange={this.props.handleFileChange} 
          handleRemoveValue={this.props.handleRemoveValue}
          value={video_value}/>)
          
      case "image":
        let photo_value = this.props.files && value ? this.props.files[value]: "";
        return (<Image groupLabel={groupLabel}  key={"image"+name}
          isGroupVisible={isGroupVisible} 
          label={label} 
          required={field.required} 
          hint={hint} 
          name={name} 
          handleFileChange={this.props.handleFileChange}
          handleRemoveValue={this.props.handleRemoveValue}
          value={photo_value}/>)
    }
  }
}


Field.propTypes = {
  fieldItem: PropTypes.object.isRequired,
  groupLabel: PropTypes.string,
  isGroupVisible: PropTypes.bool,
  record: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleMultiSelectChange: PropTypes.func.isRequired,
  handleGroupedCheckboxes: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  getLocation: PropTypes.func.isRequired,
  gettingLocation: PropTypes.bool,
  languageIndex: PropTypes.number
  
};

export default Field;
