import React from "react"
import PropTypes from "prop-types"
class FormPreview extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentlyBuilding: props.currentlyBuilding,
      formTitle: props.formTitle,
      fields: props.fields,
      optionLists: props.optionLists,
      publishForm: props.publishForm,
      getLocation: props.getLocation,
      gettingLocation: props.gettingLocation,
      formIsBeingPublished: props.formIsBeingPublished,
      languageIndex: 0,
      languages: props.languages,
      useSecondaryLanguage: props.useSecondaryLanguage,
      okToSubmit: props.okToSubmit
    };
    this.changeLanguage = this.changeLanguage.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentlyBuilding: nextProps.currentlyBuilding,
      formTitle: nextProps.formTitle,
      fields: nextProps.fields,
      optionsList: nextProps.optionsList,
      gettingLocation: nextProps.gettingLocation,
      formIsBeingPublished: nextProps.formIsBeingPublished,
      languages: nextProps.languages,
      useSecondaryLanguage:nextProps.useSecondaryLanguage,
      okToSubmit: nextProps.okToSubmit
    });
  }

  changeLanguage(event){
    let value = parseInt(event.target.value)
    this.setState({...this.state,  languageIndex: value})
  }

  getOptionsForSelectOne(field){
    let optionList = this.state.optionLists.filter(e=>{ return e.listName == field.optionList })[0]
    if(optionList){
      let optionsForSelect = [<option key={"empty-option"} value="">-select-</option>];
      let options = optionList.options;
      //let optionList = this.state.optionLists[field.optionList];
      let optionKey;
      let languageIndex = this.state.languageIndex
      options.map((option, i) => {
        optionKey = (i + 1).toString();
        let label = ""
        if (languageIndex < option.label.length){
          label = option.label[languageIndex].value
        }  
        optionsForSelect.push(<option key={optionKey.concat("-option")} value={option.name}>{label}</option>)
      })
      return optionsForSelect
    }
    else {
      return null
    }
  }

  getOptionsForSelectMultiple(field){
    let optionList = this.state.optionLists.filter(e=>{ return e.listName == field.optionList })[0]
    if(optionList){
      let checkBoxOptions = [];
      let options = optionList.options;
      let multiOptionKey;
      let name = field.name;
      let languageIndex = this.state.languageIndex
      options.forEach(function(option, i) {
        multiOptionKey = (i + 1).toString();
        let label = ""
        if (languageIndex < option.label.length){
          label = option.label[languageIndex].value
        }  
        checkBoxOptions.push(<div key={multiOptionKey.concat("-div")}><label><input className="form-checkbox" key={multiOptionKey.concat("-option")} type="checkbox" name={name} value={option.name} />{label}</label></div>)
      });
      return checkBoxOptions
    }
    else {
      return null
    }

  }

  getLocation(event) {
    this.setState({
      gettingLocation: true
    })
    this.state.getLocation(event);
  }

  publishForm(event) {
    event.preventDefault();
    this.state.publishForm();
  }

  renderField(field, key){
    let name = field.name
    let type = field.type
    let languageIndex = this.state.languageIndex
    let label = ""
    if (languageIndex < field.label.length){
      label = field.label[languageIndex].value
    }
    switch(type) {
      case "string":
        return (<div key={key} className="form-group">
            <label>
              {label}
            </label>
            <input className="form-control" name={name} type="text" placeholder="This is great field for short answers."/>
          </div>);
      case "text":
        return (<div key={key} className="form-group">
            <label>
              {label}
            </label>
            <textarea className="form-control" name={name} placeholder="This is a great field to hold lots of text. Like the description of an experience, or maybe extra comments. Seriously, a lot of text can fit in this box."></textarea>
          </div>);
      case "int":
        return (<div key={key} className="form-group">
            <label>
              {label}
            </label>
            <input className="form-control" name={name} type="number" placeholder="7"/>
          </div>);
      case "decimal":
        return (<div key={key} className="form-group">
            <label>
              {label}
            </label>
            <input className="form-control" name={name} type="number" step="0.01" placeholder="7.5"/>
          </div>);
      case "dateTime":
        return (<div key={key} className="form-group">
            <label>
              {label}
            </label>
            <input className="form-control" type={"datetime-local"} name={name}/>
          </div>);
      case "date":
        return (<div key={key} className="form-group">
            <label>
              {label}
            </label>
            <input className="form-control" type={"date"} name={name}/>
          </div>);
      case "time":
        return (<div key={key} className="form-group">
            <label>
              {label}
            </label>
            <input className="form-control" name={name} type="time"/>
          </div>);
      case "note":
        return (<div key={key} className="form-group">
            <input className="form-control" name={name} type="text" readOnly/>
            <div key={key.concat("-note")} className="">
              <h3>{label}</h3>
            </div>
          </div>);
      case "geopoint":
        return (<div key={key} className="form-group">
            <label>
            {label}
            </label>
            <button type="button" className="yo btn btn-default btn-location" onClick={(event) => this.getLocation(event)}>{(this.state.gettingLocation) ? spinner : "Record Location"}</button>
            <input className="form-control" name={name} type="text" readOnly/>
          </div>);
      case "select":
        let checkBoxOptions = this.getOptionsForSelectMultiple(field);
        return (<div key={key} className="form-group">
            <label>
            {label}
            </label>
            {checkBoxOptions}
          </div>);
      case "select1":
        let optionsForSelect1 = this.getOptionsForSelectOne(field);
        return (<div key={key} className="form-group">
            <label>
            {label}
            </label>
            <select className="form-control" name={name} multiple={false} onChange={this.handleChange}>
              {optionsForSelect1}
            </select>
          </div>);
      case "barcode":
        return (<div key={key} className="form-group">
            <label>
              {label}
            </label>
            <input className="form-control" name={name} type="text" placeholder="Enter barcode"/>
            <small>
              <label>
                {`${label} confirmation`}
              </label>
            </small>
            <input className="form-control" name={`${name}_confirmation`} type="text" placeholder="Confirm barcode"/>
          </div>)
    }
  }

  renderFormPreview(){
    let list = [];
    let languageIndex = this.state.languageIndex
    this.state.fields.map((field, i) =>
      {
        if(field.type=="group"){
          field.fields.forEach((groupField,index)=>{
            let label = ""
            if (languageIndex < field.label.length){
              label = field.label[languageIndex].value
            }  
            let groupLabel = (<small key={`small-${i}-${index}`}>
            <label>
                {`${label}`}
              </label>
            </small>);
            list.push(groupLabel)
            list.push(this.renderField(groupField,`${i}-${index}`))
          });
        }
        else{
          list.push(this.renderField(field,(i+1).toString()))
        }
      }
    )

    let submitButton = null
    if (this.state.okToSubmit){
      submitButton = (<div className="form-submit">
                <button disabled={this.state.formIsBeingPublished} key="submit-button" type="submit" className="yo btn btn-primary btn-dark pull-left">Publish Form</button>
               <p>
                  Once you have published your form you will be able to collect data. We'll provide a public web address for you to share.
                </p>
              </div>)
    }
    return (
      <div className="preview">
        <div className="col-md-5">
          <h1 className="text-center">{this.state.formTitle}  {this.renderLanguage()}</h1>
         
          <form onSubmit={event => this.publishForm(event)}>
            {list}
            {submitButton}
          </form>
        </div>
      </div>
    )
  }

  render () {
    if (!this.state.currentlyBuilding){
      return <div />
    }
    else if (this.state.currentlyBuilding && this.state.fields.length === 0){
      return (
        <div className="preview">
          <div className="col-md-5">
            <h1 className="text-center">{this.state.formTitle}{this.renderLanguage()}</h1>
            <p>Once you start adding fields, a preview of your form will appear here</p>
            <br/>
            <small>"Start field" will insert the field at the beginning of the form. Selecting other option will insert the field after the selected field.</small>
          </div>
        </div>
      )
    }
    else if (this.state.currentlyBuilding && this.state.fields.length > 0){
      return this.renderFormPreview();
    }
  }

  renderLanguage(){
    if(this.state.useSecondaryLanguage){
      let secondOption = null
      if (this.state.languages.length > 1){
        secondOption = (<option key={`option2`} value={1}>{this.state.languages[1].language}</option>)
      }

      return (<div className="languages">
        <select value={this.state.languageIndex} className="form-control" name="index" multiple={false} onChange={(event) => this.changeLanguage(event)}>
          <option key={`option1`} value={0}>{this.state.languages[0].language}</option>
          { secondOption }
        </select>
              </div>)
    }else{
      return null
    }
  }

}


FormPreview.propTypes = {
  title: PropTypes.string
};
export default FormPreview
