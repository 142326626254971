import React from "react"
import PropTypes from "prop-types"

class SinglePageGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLocation: 0,
      survey: props.survey,
      form: props.form,
      record: props.record,
      group: props.group,
      languageIndex: props.languageIndex,
      fieldIndex: props.fieldIndex,
      hasUnsavedChanges: false,
      isSaving: false,
      savedRemotely: false,
      buttonColor: '#F68657',
      selectedOptionLabel: {},
      buttonLabel: {},
      fieldCount: null,
      gettingLocation: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleGroupedCheckboxes = this.handleGroupedCheckboxes.bind(this);
    this.getLocation = this.getLocation.bind(this);
  }

  componentWillMount() {
    let groupFields = this.getGroupFields();
    this.setState({fieldCount: groupFields.length});
  }

  handleChange(event) {
    let recordCopy = this.state.record;
    recordCopy[event.target.name] = event.target.value
    this.setState({
      record: recordCopy
    });
  }

  handleGroupedCheckboxes(field, option, options, event){
    let name = event.target.name;
    let value = event.target.value
    let newRecord = this.state.record;
    if(typeof newRecord[name] === 'undefined'){
      newRecord[name] = []
    }
    else{
      if(option.filter){
        // check if existing checked values should be filtered
        for (let i = 0; i < newRecord[name].length; i++){
          for (let x = 0; x < options.length; x++){
            if (newRecord[name][i] === options[x].name){
              if (field.group_name){
                let groupFilter = field.group_name + "_" + options[x].filter.field;
                if(newRecord[groupFilter] !== options[x].filter.value){
                  let index = newRecord[name].indexOf(newRecord[name][i]);
                  if (index > -1) {
                    newRecord[name].splice(index, 1);
                  }
                }
              }
              else if(newRecord[options[x].filter.field] !== options[x].filter.value){
                let index = newRecord[name].indexOf(newRecord[name][i]);
                if (index > -1) {
                  newRecord[name].splice(index, 1);
                }
              }
            }
          }
        }
      }
    }
    if(newRecord[name].includes(value)){
      let index = newRecord[name].indexOf(value);
      if (index > -1) {
        newRecord[name].splice(index, 1);
      }
    }else{
      newRecord[name].push(value)
    }
    newRecord[name]
    this.setState({
      record: newRecord
    })
  }

  getLocation(event){
    this.setState({
      gettingLocation: true
    })
    let output = event.target.parentElement.lastChild;
    let newRecord = this.state.record;
    let name = output.name;
    if (!navigator.geolocation){
      output.value = "Geolocation is not supported by your browser";
      return;
    }
    let success = (position) => {
      output.value = 'Latitude: ' + position.coords.latitude + ' Longitude: ' + position.coords.longitude
      newRecord[name + "_lat"] = position.coords.latitude
      newRecord[name + "_lng"] = position.coords.longitude
      this.setState({
        gettingLocation: false,
        record: newRecord
      })
    }

    let error = () => {
      output.value = "Unable to retrieve your location";
      this.setState({
        gettingLocation: false
      })
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }

  getGroupFields() {
    let fields = this.state.form.fields;
    let groupFields = [];
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (field.group_name === this.state.group.name) {
        if (field.type !== 'group' || field.type !== 'end_group') {
          groupFields.push(fields[i]);
        }
      }
    }
    return groupFields;
  }

  isRelevant(field, grouping, relevantGroups){
    if(grouping){
      if(field.type == 'group'){
        if ("undefined" === typeof field.relevant){
          return true
        }
        else{
          return this.checkRelevance(field.relevant);
        }
      }
      else{
        if(relevantGroups.includes(field.group_name)){
          if ("undefined" === typeof field.relevant){
            return true
          }
          else{
            return this.checkRelevance(field.relevant, field.group_name);
          }
        }
        else{
          return false;
        }
      }
    }
    else{
      if ("undefined" === typeof field.relevant){
        return true
      }
      else{
        return this.checkRelevance(field.relevant);
      }
    }
  }

  checkRelevance(relevantConditions, groupName=null){
    let relevant = 0;
    let length = relevantConditions.length;
    let i;
    for(i = 0; i < length; i++) {
      if(Array.isArray(relevantConditions[i])) {
        let thisLength = relevantConditions[i].length;
        let x;
        for(x = 0; x < thisLength; x++) {
          if(groupName) {
            let inGroupRelevantCondition = Object.assign({}, relevantConditions[i][x])
            inGroupRelevantCondition.field_name = `${groupName}_${inGroupRelevantCondition.field_name}`
            if (this.evaluateConditionForRelevance(inGroupRelevantCondition)) {
              relevant += 1;
            }
          }
          else {
            if (this.evaluateConditionForRelevance(relevantConditions[i][x])) {
              relevant += 1;
            }
          }
        }
        if(relevant == thisLength) {
          return true;
        }
        else {
          if (i + 1 == length) {
            return false;
          }
          else {
            relevant = 0;
          }
        }
      }
      else {
        if(groupName) {
          let inGroupRelevantCondition = Object.assign({}, relevantConditions[i])
          inGroupRelevantCondition.field_name = `${groupName}_${inGroupRelevantCondition.field_name}`
          if (this.evaluateConditionForRelevance(inGroupRelevantCondition)) {
            relevant += 1;
          }
        }
        else {
          if (this.evaluateConditionForRelevance(relevantConditions[i])) {
            relevant += 1;
          }
        }
      }
    }
    if(relevant == length) {
      return true;
    }
    else {
      return false;
    }
  }
  
  evaluateConditionForRelevance(conditionForRelevance) {
    if (conditionForRelevance.selected == undefined) {
      return this.evaluateComparison(conditionForRelevance);
    }
    else {
      return this.evaluateSelected(conditionForRelevance);
    }
  }
  
  evaluateSelected(conditionForRelevance) {
    let record = this.state.record;
    let relevantField = conditionForRelevance.field_name;
    let relevantValues = record[relevantField];
    if (relevantValues != undefined) {
      let valueIncluded = relevantValues.includes(conditionForRelevance.value);
      return conditionForRelevance.selected == valueIncluded;
    }
    else {
      return false;
    }
  }
  
  evaluateComparison(conditionForRelevance){
    let record = this.state.record;
    let valueOne = record[conditionForRelevance.field_name];
    let valueTwo = conditionForRelevance.value;
    let comparisonSymbol = conditionForRelevance.comparison_symbol;
    switch(comparisonSymbol){
      case '>':
        if(parseInt(valueOne) > parseInt(valueTwo)){
          return true
        }else{
          return false
        }
        break;
      case '>=':
        if(parseInt(valueOne) >= parseInt(valueTwo)){
          return true
        }else{
          return false
        }
        break;
      case '<':
        if(parseInt(valueOne) < parseInt(valueTwo)){
          return true
        }else{
          return false
        }
        break;
      case '<=':
        if(parseInt(valueOne) <= parseInt(valueTwo)){
          return true
        }else{
          return false
        }
        break;
      case '!=':
        if(valueOne != valueTwo){
          return true
        }else{
          return false
        }
        break;
      case '=':
        if(valueOne == valueTwo){
          return true
        }else{
          return false
        }
        break;
    }
  }

  render() {
    if (this.state.fieldCount) {
      return this.renderSurvey();
    } else {
      return null;
    }
  }

  renderSurvey() {
    var list = [];

    // Display form fields
    let grouping = false;
    let groupLabel = '';
    let relevantGroups = [];
    let relevantFields = [];
    let group = null;
    let tabIndex = 0;
    if (this.state.group.labels) {
      groupLabel = this.state.group.labels[this.state.languageIndex].value;
    }

    // Display group name
    list.push(
      <div key={0}>
        <h3>{groupLabel}</h3>
      </div>
    );

    // Translations
    let getLocation = "Get Location";
    let capture = "Capture";
    let select = "Select";
    let selectDate = "Select Date";

    let startIndex = this.state.fieldIndex + 1;
    let endIndex = startIndex + this.state.fieldCount;

    // Display form fields
    for (let i = startIndex; i < endIndex; i++) {
      let field = this.state.form.fields[i];
      let name = field.name
      let type = field.type
      let label = "";
      if (field.labels) {
        label = field.labels[this.state.languageIndex].value;
      }
      let readonly = field.readonly
      let metadata = field.metadata
      let key = (i + 1).toString();
      let value = "";
      if ("undefined" !== typeof this.state.record[name] && this.state.record[name] !== null) {
        value = this.state.record[name].toString();
      }

      switch(type) {
        case "string":
          if(name === "intro_note" || name === "followup_intro_note" || field.readonly){
            if(this.isRelevant(field, grouping, relevantGroups)){
              list.push(
                <div key={key.concat("-note")} className="">
                  <h3>{label}</h3>
                </div>
              );
            }
            break;
          }
          if (name !== "meta_instance_id") {
            if(this.isRelevant(field, grouping, relevantGroups)){
              relevantFields.push(name);
              if (metadata) {
                if (name == 'email') {
                  // this.state.record['email'] = currentUser.email;
                  break;
                }
              }
              if (readonly) {
                list.push(
                  <div key={key} className="form-group">
                    <label>{label}</label>
                    <input className="form-control" name={name} type="text" readOnly/>
                  </div>
                );
              } else {
                list.push(
                  <div key={key} className="form-group">
                    <label>{label}</label>
                    <input tabIndex={tabIndex} className="form-control" name={name} type="text" onChange={this.handleChange} value={this.state.record[name]}/>
                  </div>
                );
              }
            }
          }
          break;
        case "int":
          if(this.isRelevant(field, grouping, relevantGroups)){
            relevantFields.push(name);
            list.push(
              <div key={key} className="form-group">
                <label>{label}</label>
                <input tabIndex={tabIndex} className="form-control" name={name} type="number" onChange={this.handleChange} value={this.state.record[name]}/>
              </div>
            );
          }
          break;
        case "decimal":
          if(this.isRelevant(field, grouping, relevantGroups)){
            relevantFields.push(name);
            list.push(
              <div key={key} className="form-group">
                <label>{label}</label>
                <input tabIndex={tabIndex} className="form-control" name={name} type="number" step="0.001" onChange={this.handleChange} value={this.state.record[name]}/>
              </div>
            );
          }
          break;
        case "text":
          if(this.isRelevant(field, grouping, relevantGroups)){
            relevantFields.push(name);
            list.push(
              <div key={key} className="form-group">
                <label>{label}</label>
                <textarea tabIndex={tabIndex} className="form-control" name={name} onChange={this.handleChange} value={this.state.record[name]}>
                </textarea>
              </div>
            );
          }
          break;
        case "select":
          if(this.isRelevant(field, grouping, relevantGroups)){
           relevantFields.push(name);
           let checkBoxOptions = [];
           let options = this.state.form.fields[i].options;
           let languageIndex = this.state.languageIndex;
           let multiOptionKey;
           let that = this;
           options.forEach(function(option, i) {
             let checked;
             if (typeof that.state.record[name] !== 'undefined'){
               if (that.state.record[name].includes(option.name)){
                  checked = true;
               }
             }
             if(option.filter){
               if(that.state.record[option.filter.field] === option.filter.value){
                 multiOptionKey = (i + 1).toString();
                 checkBoxOptions.push(<div key={multiOptionKey.concat("-div")}><label><input tabIndex={tabIndex}  className="form-checkbox" key={multiOptionKey.concat("-option")} onChange={(event) => that.handleGroupedCheckboxes(field, option, options, event)} type="checkbox" name={name} value={option.name} checked={checked} />{option.labels[languageIndex].value}</label></div>)
               }
               else if (that.state.group){
                 let groupFilter = that.state.group.name + "_" + option.filter.field;
                 if(that.state.record[groupFilter] === option.filter.value){
                   multiOptionKey = (i + 1).toString();
                   checkBoxOptions.push(<div key={multiOptionKey.concat("-div")}><label><input tabIndex={tabIndex}  className="form-checkbox" key={multiOptionKey.concat("-option")} onChange={(event) => that.handleGroupedCheckboxes(field, option, options, event)} type="checkbox" name={name} value={option.name} checked={checked} />{option.labels[languageIndex].value}</label></div>)
                 }
               }
             }
             else{
               multiOptionKey = (i + 1).toString();
               checkBoxOptions.push(<div key={multiOptionKey.concat("-div")}><label><input tabIndex={tabIndex}  className="form-checkbox" key={multiOptionKey.concat("-option")} onChange={(event) => that.handleGroupedCheckboxes(field, option, options, event)} type="checkbox" name={name} value={option.name} checked={checked} />{option.labels[languageIndex].value}</label></div>)
             }
           });
           list.push(
             <div key={key} className="form-group">
               <label>{label}</label>
               {checkBoxOptions}
             </div>
           );
         }
          break;
        case "select1":
          if (this.isRelevant(field, grouping, relevantGroups)){
            relevantFields.push(name);
            let optionsForSelect1 = [<option key={"empty-option"} value="">-select-</option>];
            let options1 = this.state.form.fields[i].options;
            let languageIndex1 = this.state.languageIndex;
            let optionKey;
            let that = this;
            options1.forEach( function(option, i){
              if(option.filter){
                if(that.state.record[option.filter.field] === option.filter.value){
                  optionKey = (i + 1).toString();
                  optionsForSelect1.push(<option key={optionKey.concat("-option")} value={option.name}>{option.labels[languageIndex1].value}</option>)
                }
                else if (that.state.group){
                  let groupFilter = that.state.group.name + "_" + option.filter.field;
                  if(that.state.record[groupFilter] === option.filter.value){
                    optionKey = (i + 1).toString();
                    optionsForSelect1.push(<option key={optionKey.concat("-option")} value={option.name}>{option.labels[languageIndex1].value}</option>)
                  }
                }
              }
              else{
                optionKey = (i + 1).toString();
                optionsForSelect1.push(<option key={optionKey.concat("-option")} value={option.name}>{option.labels[languageIndex1].value}</option>)
              }
            })
            list.push(
              <div key={key} className="form-group">
                <label>{label}</label>
                <select tabIndex={tabIndex}  className="form-control" name={name} multiple={false} onChange={this.handleChange} value={this.state.record[name]}>
                  {optionsForSelect1}
                </select>
              </div>
            );
          }
          break;
        case "dateTime":
          if(this.isRelevant(field, grouping, relevantGroups)){
            relevantFields.push(name);
            list.push(
              <div key={key} className="form-group">
                <label>{label}</label>
                <input tabIndex={tabIndex} className="form-control" type={"datetime-local"} name={name} onChange={this.handleChange} value={this.state.record[name]}/>
              </div>
            );
          }
          break;
        case "date":
          if(this.isRelevant(field, grouping, relevantGroups)){
            relevantFields.push(name);
            list.push(
              <div key={key} className="form-group">
                <label>{label}</label>
                <input tabIndex={tabIndex} className="form-control" type={"date"} name={name} onChange={this.handleChange} value={this.state.record[name]}/>
              </div>
            );

          }
          break;
        case "time":
          if(this.isRelevant(field, grouping, relevantGroups)){
            relevantFields.push(name);
            list.push(
              <div key={key} className="form-group">
                <label>{label}</label>
                <input tabIndex={tabIndex} className="form-control" name={name} type="time" onChange={this.handleChange} value={this.state.record[name]}/>
              </div>
            );
          }
        break;
        case "geopoint":
          if(this.isRelevant(field, grouping, relevantGroups)){
            let coordinatesOutput;
            if (this.state.record[name + '_lat'] && this.state.record[name + '_lng']){
              let coordinates = 'Latitude: ' + this.state.record[name + '_lat'] + ' Longitude: ' + this.state.record[name + '_lng']
              coordinatesOutput = <input className="form-control" name={name} type="text" value={coordinates} readOnly/>
            }else {
              coordinatesOutput = <input className="form-control" name={name} type="text" readOnly/>
            }
              let geoSpinner = <div className="form-buttons"><div className="web-form-spinner"><div className="double-bounce1"></div><div className="double-bounce2"></div></div></div>
              list.push(
                <div key={key} className="form-group">
                  <label>
                  {label}
                  </label>
                  <button type="button" className="yo btn btn-default" onClick={(event) => this.getLocation(event)}>{(this.state.gettingLocation) ? geoSpinner : "Record Location"}</button>
                  {coordinatesOutput}
                </div>
              );
            }
          break;
          case "barcode":
            if(this.isRelevant(field, grouping, relevantGroups)){
              relevantFields.push(name);
              list.push(
                <div key={key} className="form-group">
                  <label>
                    {label}
                  </label>
                  <input tabIndex={tabIndex} className="form-control" name={name} type="text" onChange={this.handleChange} placeholder="Enter barcode numbers."/>
                  <small>
                    <label>
                      {`${label} confirmation`}
                    </label>
                  </small>
                  <input className="form-control" name={`${name}_confirmation`} id={`${name}_confirmation`} type="text" placeholder="Confirm barcode numbers"/>
                </div>
              )
            }
          break;
          case "binary":
          break;
      }
    }
    return (
      <div>
        {list}
      </div>
    );
  }

  takePicture(photoKey) {
    const options = {};
    //options.location = ...
    this.camera.capture({metadata: options})
      .then((data) => {
        var newRecord = this.state.record;
        newRecord[photoKey] = data.path;
        this.setState({
          record: newRecord,
          hasUnsavedChanges: true,
          savedRemotely: false
        });
        console.log(data);
      })
      .catch(err => {
        console.log(err);
      });
  }

  getNameFromLabel(optionLabel, options) {
    let nameValue = '';
    options.forEach(function(option){
      option.labels.forEach(function(label){
        if(label.value == optionLabel){
          nameValue = options[options.indexOf(option)].name;
        }
      })
    })
    return nameValue
  }

  getLabelFromName(optionName, options, languageIndex) {
    let labelValue = '';
    options.forEach(function(option){
      if (option.name === optionName) {
        labelValue = option.labels[languageIndex].value;
      }
    });
    return labelValue;
  }

  getLabelsFromNames(optionNames, options, languageIndex) {
    let labels = [];
    let that = this;
    optionNames.forEach(function(optionName) {
      let label = that.getLabelFromName(optionName, options, languageIndex);
      labels.push(label);
    });
    return labels;
  }

  _select_renderRow(rowData) {
    return (
        <div>
          <p style={{fontSize: 18, padding: 12, color: '#444'}}>
            {`${rowData}`}
          </p>
        </div>
    );
  }

  _separator_renderRow(rowData) {
    return (
        <div></div>
    );
  }
}

export default SinglePageGroup

class ResultView extends React.Component {
  render() {
    let name = this.props.name;
    if (name == "blank") {
      name = <div />;
    } else {
      name = <Input value={this.props.name} editable={false} />;
    }
    return name;
  }
}

class GeoView extends React.Component {
  render() {
    let lat = this.props.lat;
    let lng = this.props.lng;
    let alt = this.props.alt;
    let acc = this.props.acc;
    if (!lat) {
      lat = <div />;
    } else {
      lat = <Input value={lat + ", " + lng} />;
      // lat = <Text>{lat + ", " + lng + ", " + alt + ", " + acc}</Text>;
    }
    return lat;
  }
}

class Label extends React.Component {
  render() {
    var labelStyle = {
      color: "#999",
      letterSpacing: 1,
      padding: 5,
      fontSize: 12
    };
    return (
      <p style={labelStyle}>{this.props.title}</p>
    );
  }
}

class GroupLabel extends React.Component {
  render() {
    var labelStyle = {
      color: "#000000",
      letterSpacing: 1,
      padding: 5,
      fontSize: 12
    };
    return (
      <h2 style={labelStyle}>{this.props.title}</h2>
    );
  }
}

class Input extends React.Component {
  render() {
    var inputStyle = {
      color: "#333",
      backgroundColor: "#fff",
      padding: 4,
      paddingBottom: 0,
      fontSize: 21,
      height: 30
    };
    return (
      <div style={{borderBottomWidth: 1, borderColor: '#ddd'}}>
        <TextInput
          style={inputStyle}
          placeholder={this.props.placeholder}
          onChangeText={this.props.onChangeText}
          keyboardType={this.props.keyboardType}
          returnKeyType={this.props.return}
          secureTextEntry={this.props.password}
          autoFocus={this.props.autoFocus}
          value={this.props.value}
        />
      </div>
    );
  }
}

class TextArea extends React.Component {
  render() {
    var inputStyle = {
      color: "#333",
      backgroundColor: "#fff",
      padding: 4,
      paddingBottom: 0,
      fontSize: 21,
      height: 30
    };
    return (
      <div style={{borderBottomWidth: 1, borderColor: '#ddd'}}>
        <TextInput
          style={inputStyle}
          placeholder={this.props.placeholder}
          onChangeText={this.props.onChangeText}
          keyboardType={this.props.keyboardType}
          returnKeyType={this.props.return}
          secureTextEntry={this.props.password}
          autoFocus={this.props.autoFocus}
          multiline={true}
          numberOfLines={14}
          value={this.props.value}
          editable={this.props.editable}
        />
      </div>
    );
  }
}

function isEmpty(str) {
  if (str == null) {
    return true;
  } else {
    return !str.replace(/^\s+/g, '').length;
  }
}
