
import React, { Component } from 'react';

class GroupLabel extends React.Component {
  render() {
    var labelStyle = {
      color: "#000000",
      letterSpacing: 1,
      padding: 5,
      textAlign: "left",
      fontWeight: "bold"
    };
    if (this.props.visible){
      return (
        <h2 style={labelStyle}>{this.props.label}</h2>
      );
    }
  }
}

export default GroupLabel