import React from "react"
import PropTypes from "prop-types"
import OptionListItem from "./OptionListItem"
import OptionListSelect from "./OptionListSelect"
import OptionInputs from "./OptionInputs"
import OptionList from "./OptionList"

class OptionForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      getListNameOptions: props.getListNameOptions,
      startOptionList: props.startOptionList,
      getOptionsList: props.getOptionsList,
      currentOption: props.currentOption,
      currentOptionIndex: props.currentOptionIndex,
      addOption: props.addOption,
      currentOptionList: props.currentOptionList,
      showOptionControls: false,
      updateOptionValue: props.updateOptionValue,
      startOptionList: props.startOptionList,
      newOptionListName: '',
      optionLists: props.optionLists,
      controlOption: props.controlOption,
      selectOptionsList: props.selectOptionsList,
      useSecondaryLanguage: props.useSecondaryLanguage,
    }
    this.controlOption = this.controlOption.bind(this);
    this.selectOptionsList = this.selectOptionsList.bind(this);
    this.updateNewOptionListName = this.updateNewOptionListName.bind(this);
    this.startOptionList = this.startOptionList.bind(this);
    this.updateOptionValue = this.updateOptionValue.bind(this);
    this.addOption = this.addOption.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      currentOption: nextProps.currentOption,
      currentOptionIndex: nextProps.currentOptionIndex,
      currentOptionList: nextProps.currentOptionList,
      optionLists: nextProps.optionLists,
      useSecondaryLanguage: nextProps.useSecondaryLanguage
    })
  }

  updateNewOptionListName(value){
    this.setState({
      newOptionListName: value
    })
  }

  updateOptionValue(event){
    let currentOption = this.state.currentOption;
    currentOption[event.target.name] = event.target.value;
    this.setState({
      currentOption: currentOption
    })
  }

  addOption(event, newOption){
    event.preventDefault();
    let listName = this.state.currentOptionList;
    let optionName = newOption["name"];
    let optionLabel = newOption["label"];
    if(this.optionsValid(optionName, optionLabel)){
      this.state.addOption(listName, optionName, optionLabel);
    }
    else{
      alert("Option names, and Option labels are both required to add an option." )
    }
  }

  optionsValid(optionName, optionLabel){
    if(optionName === ''){
      return false
    }
    else if (optionLabel === ''){
      return false
    }
    else{
      return true
    }
  }

  controlOption(option, control, index){
    this.state.controlOption(option, control, index);
  }

  selectOptionsList(value){
    this.state.selectOptionsList(value);
  }

  startOptionList(newOptionListName){
    this.state.startOptionList(newOptionListName);
  }

  render () {
    return (
      <div className="options-form">
        <label>Select a list to use, or create a new list of options.</label>
        <OptionListSelect
          optionLists={this.state.optionLists}
          currentOptionList={this.state.currentOptionList}
          selectOptionsList={this.selectOptionsList}
          updateNewOptionListName={this.updateNewOptionListName}
          newOptionListName={this.state.newOptionListName}
          startOptionList={this.startOptionList}
        />
        <OptionList
          optionLists={this.state.optionLists}
          currentOptionList={this.state.currentOptionList}
          controlOption={this.controlOption}
        />
        <OptionInputs
          fieldOperation={this.props.fieldOperation}
          operationType={this.props.operationType}
          currentOption={this.state.currentOption}
          currentOptionIndex={this.state.currentOptionIndex}
          updateOptionValue={this.updateOptionValue}
          optionEditMode={this.props.optionEditMode}
          addOption={this.addOption}
          currentOptionList={this.state.currentOptionList}
          optionLists={this.state.optionLists}
          useSecondaryLanguage={this.state.useSecondaryLanguage}
        />
      </div>
    );
  }
}

export default OptionForm
