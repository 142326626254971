import React, { Component } from 'react';
import Label from '../Label';
import GroupLabel from '../GroupLabel';
import ReactPlayer from 'react-player';


class Video extends Component {
  constructor(props){
    super(props);
  }

  render() {
    let video = null;
    if (this.props.value instanceof File){
      video = (<ReactPlayer url={URL.createObjectURL(this.props.value)} controls={true} style={{paddingTop: "10px"}}/> );
      return (
        <div className="form-group">
          <GroupLabel label={this.props.groupLabel} visible={this.props.groupLabelVisible}></GroupLabel>
          <Label htmlFor={this.props.name} label={this.props.label} required={this.props.required} hint={this.props.hint}/>
          <button value={this.props.name} key="submit-button" type="submit" className="yo btn btn-default" onClick={e=> {e.preventDefault(); this.props.handleRemoveValue(e); }} >Remove Video</button>
          
          {video}
        </div>
      );
    }
    else{
      return (
        <div className="form-group">
          <GroupLabel label={this.props.groupLabel} visible={this.props.groupLabelVisible}></GroupLabel>
          <Label htmlFor={this.props.name} label={this.props.label} required={this.props.required} hint={this.props.hint}/>
          <input id={this.props.name}
          name={this.props.name}
          tabIndex={this.props.tabIndex} 
          className="form-control" 
          type="file" 
          onChange={this.props.handleFileChange} 
          accept="video/mp4"
            />
          {video}
        </div>
      );
    }

  }
}
export default Video