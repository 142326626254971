
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Field from './Field';
import GroupLabel from './GroupLabel';
import {evalCalculation, isRelevant} from './lib/EvalExpressHelper';
import {getGroupEndIndex} from './lib/SurveyHelper';
import { v4 as uuidv4 } from 'uuid';
class SinglePageRepeater extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      gettingLocation: false
    }
    
    this.addNewRecord = this.addNewRecord.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCalculation = this.handleCalculation.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleRemoveValue = this.handleRemoveValue.bind(this);
  }

  handleChange(event, recordIndex) {
    let records = [...this.props.repeaterRecords]
    let newRecord = records[recordIndex];
    newRecord[event.target.name] = event.target.value;
    let relevantRecord = this.relevantRecord(newRecord)
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }

  handleFileChange(event, recordIndex) {
    let records = [...this.props.repeaterRecords];
    let newRecord = records[recordIndex];
    let files = {...this.props.files};
    if (event.target.files.length > 0){
      let extensionName = event.target.files[0].name.split(".").pop();
      let filename = `${Date.now()}.${extensionName}`;
      
      files[filename] = event.target.files[0];
      newRecord[event.target.name] = filename;
    }
    else{
      newRecord[event.target.name] = "";
    }

    this.props.updateFiles(files);
    let relevantRecord = this.relevantRecord(newRecord);
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }

  handleMultiSelectChange(event, recordIndex){
    var options = event.target.options;
    let name = event.target.name;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    let records = [...this.props.repeaterRecords]
    let newRecord = records[recordIndex];
    newRecord[name] = value;

    let relevantRecord = this.relevantRecord(newRecord)
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }

  handleGroupedCheckboxes(field, option, options, event, recordIndex){
    let name = event.target.name;
    let value = event.target.value
    let records = [...this.props.repeaterRecords]
    let newRecord = records[recordIndex];
    if(typeof newRecord[name] === 'undefined'){
      newRecord[name] = []
    }
    else{
      if(option.filter){
        // check if existing checked values should be filtered
        for (let i = 0; i < newRecord[name].length; i++){
          for (let x = 0; x < options.length; x++){
            if (newRecord[name][i] === options[x].name){
              if (field.group_name){
                let groupFilter = field.group_name + "_" + options[x].filter.field;
                if(newRecord[groupFilter] !== options[x].filter.value){
                  let index = newRecord[name].indexOf(newRecord[name][i]);
                  if (index > -1) {
                    newRecord[name].splice(index, 1);
                  }
                }
              }
              else if(newRecord[options[x].filter.field] !== options[x].filter.value){
                let index = newRecord[name].indexOf(newRecord[name][i]);
                if (index > -1) {
                  newRecord[name].splice(index, 1);
                }
              }
            }
          }
        }
      }
    }
    if(newRecord[name].includes(value)){
      let index = newRecord[name].indexOf(value);
      if (index > -1) {
        newRecord[name].splice(index, 1);
      }
    }else{
      newRecord[name].push(value)
    }

    let relevantRecord = this.relevantRecord(newRecord)
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }

  handleCalculation(recordCopy){
    let fields = this.props.form.fields;
    let record = {...recordCopy};
    let startIndex = this.props.fieldIndex;
    let endIndex = getGroupEndIndex(fields, startIndex, this.props.repeaterField.name);
    let parentRecord = this.props.parentRecord;
    for(let cnt=startIndex; cnt < endIndex; cnt++){
      let field = fields[cnt];
      if(!isRelevant({...parentRecord, ...recordCopy}, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }

      let ismeta = field.name == "meta_instance_id"
      if(["group", "end_group"].includes(field.type) == false && ismeta == false) {
        if (field.calculate){
          let val = evalCalculation({...parentRecord, ...record}, field.calculate)
          if(val != record[field.name]){
            record[field.name] = val
          }
        }
      }
    }
    return record;
  }

  handleRemoveValue(event, recordIndex){
    let records = [...this.props.repeaterRecords];
    let newRecord = records[recordIndex];
    let files = {...this.props.files};
    delete files[newRecord[event.target.value]];
    delete newRecord[event.target.value];

    this.props.updateFiles(files);
    let relevantRecord = this.relevantRecord(newRecord);
    records[recordIndex] = this.handleCalculation(relevantRecord)
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }

  relevantRecord(recordCopy){
    let record = {};
    let fields = this.props.form.fields;
    let startIndex = this.props.fieldIndex
    let endIndex = getGroupEndIndex(fields, startIndex, this.props.repeaterField.name);
    let parentRecord = this.props.parentRecord;
    for(let cnt=startIndex; cnt < endIndex; cnt++){
      let field = fields[cnt];
      if(!isRelevant({...parentRecord, ...recordCopy}, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }

      let excludeType = ["group", "end_group"];
      let includedString = field.type =="string" && field.name !== "meta_instance_id" && !field.readonly && !field.metadata
      if(includedString || excludeType.includes(field.type) == false){
        record[field.name] = recordCopy[field.name];
      }

      if(field.type == "barcode"){
        record[`${field.name}_confirmation`] = recordCopy[`${field.name}_confirmation`];
      }
    }
    record["id"] = recordCopy["id"];
    return record;
  }

  getLocation(event, recordIndex){
    this.setState({
      gettingLocation: true
    });
    
    let output = event.target.parentElement.lastChild;
     let records = [...this.props.repeaterRecords]
    let newRecord = records[recordIndex];
    let name = output.name;
    if (!navigator.geolocation){
      output.value = "Geolocation is not supported by your browser";
      return;
    }
    let success = (position) => {
      output.value = `Latitude: ${position.coords.latitude} Longitude: ${position.coords.longitude} Altitude: ${position.coords.altitude} Accuracy: ${position.coords.accuracy}`
      newRecord[name + "_lat"] = position.coords.latitude
      newRecord[name + "_lng"] = position.coords.longitude
      newRecord[name + "_alt"] = position.coords.altitude
      newRecord[name + "_acc"] = position.coords.accuracy

      this.setState({
        gettingLocation: false,
        record: newRecord
      })
    }

    let error = () => {
      output.value = "Unable to retrieve your location";
      this.setState({
        gettingLocation: false
      })
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }

  addNewRecord(event){
    event.preventDefault();
    let records = [...this.props.repeaterRecords]
    records.push({id: uuidv4()});
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }

  removeRecord(event, recordIndex){
    event.preventDefault();
    let records = [...this.props.repeaterRecords]
    records.splice(recordIndex, 1);
    this.props.updateRepeaterRecords(this.props.repeaterField, records)
  }

  render(){
    let repeaterLabel = '';
    let repeaterField = this.props.repeaterField;
    if (repeaterField.labels) {
      repeaterLabel = repeaterField.labels[this.props.languageIndex].value;
    }

    let addButton = (<button onClick={this.addNewRecord} className="yo btn btn-default" style={{float: "right"}}>ADD NEW RECORD</button>)
    if(repeaterField.repeater_count){
      addButton = null;
    }

    return (<div key={`rptH${repeaterField.name}`} className='repeater' >
        <div>
          <h3><span style={{fontWeight: "bold"}}>{repeaterLabel}</span> {addButton}</h3>
        </div>
        <div style={{paddingLeft: 25}}>
          {this.renderFields()}
        </div>
      </div>);
  }

  renderFields(){
    let repeaterField = this.props.repeaterField;
    let listOfFields = [];
    let groupLabel = '';

    let isGroupVisible = false
    let fields = this.props.form.fields;
    let startIndex = this.props.fieldIndex
    let endIndex = getGroupEndIndex(fields, startIndex, repeaterField.name);


    for(let cnt1 = 0; cnt1 < this.props.repeaterRecords.length; cnt1++){
      let record =  this.props.repeaterRecords[cnt1];
      let recordID = record.id;
      console.log(`recordID: ${recordID}`)
            
      let removeButton = <button className="yo btn btn-default" style={{float: "right"}} onClick={e=>{this.removeRecord(e, cnt1) }}>REMOVE RECORD</button>;
      if(repeaterField.repeater_count){
        removeButton = null;
      }
      listOfFields.push((
        <h3 key={`${record.id}_${cnt1}`} className="record">
          <span>Record {cnt1+1}</span> 
          {removeButton}
        </h3>
      ));


      for(let cnt2=startIndex+1; cnt2 < endIndex; cnt2++){
        let key = `k${cnt1}_${cnt2}`;
        let field = fields[cnt2];
        let mergeRecord = { ...this.props.parentRecord, ...record};

        if(!isRelevant(mergeRecord, field)){
          if (field.type == "group"){
            cnt = getGroupEndIndex(fields, cnt, field.name);
          }
          continue;
        }
  
        if(field.type == "group" && !field.is_repeater){
          if (field.labels) {
            label = field.labels[this.state.languageIndex].value;
          }
          groupLabel = label;
          isGroupVisible = true
          listOfFields.push(
            <div key={`g${key}`}>
              <GroupLabel title={groupLabel} />
            </div>
          );
        }

        if(field.type == "group" || field.type == "end_group"){

        }
        else{
          listOfFields.push(
            <Field key={`${recordID}_${field.name}`} style={repeaterChildStyle} 
              fieldItem={field} 
              groupLabel={groupLabel}
              isGroupVisible={isGroupVisible}
              record={mergeRecord} 
              files={this.props.files}
              handleChange={e=>{ this.handleChange(e, cnt1)}}
              handleMultiSelectChange={e=>{ this.handleMultiSelectChange(e, cnt1)}}
              handleGroupedCheckboxes={(field,option,options,e)=>{
                  this.handleGroupedCheckboxes(field, option, options, e, cnt1)
                }
              }
              handleFileChange={e=>{ this.handleFileChange(e, cnt1)}}
              handleRemoveValue={e=>{ this.handleRemoveValue(e, cnt1)}}
              getLocation={e=>{this.getLocation(e, cnt1)}}
              gettingLocation={this.state.gettingLocation}
              languageIndex= {this.props.languageIndex}
              />);
        }
        
      }
    }
    return listOfFields;
  }
}

SinglePageRepeater.propTypes = {
  repeaterField: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  parentRecord: PropTypes.object.isRequired,
  repeaterRecords: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleMultiSelectChange: PropTypes.func.isRequired,
  handleGroupedCheckboxes: PropTypes.func.isRequired,
  getLocation: PropTypes.func.isRequired,
  gettingLocation: PropTypes.bool.isRequired,
  languageIndex: PropTypes.number.isRequired,
  updateRepeaterRecords: PropTypes.func.isRequired
};

const repeaterChildStyle  = {
  paddingLeft: 15
}

export default SinglePageRepeater