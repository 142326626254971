import React from "react"
import PropTypes from "prop-types"
class OptionListSelect extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      optionLists: props.optionLists,
      currentOptionList: props.currentOptionList,
      newOptionListName: props.newOptionListName,
      selectOptionsList: props.selectOptionsList
    }
    this.selectOptionsList = this.selectOptionsList.bind(this);
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      optionLists: nextProps.optionLists,
      currentOptionList: nextProps.currentOptionList,
      newOptionListName: nextProps.newOptionListName
    })
  }

  getListNameOptions(){
    let optionsForSelect = [<option key={"empty-option"} value="empty-option">-select-</option>];
    let optionLists = this.state.optionLists;
    for(let i = 0; i < this.state.optionLists.length; i ++){
      let optionKey;
      optionKey = (i + 1).toString();
      optionsForSelect.push(<option key={optionKey.concat("-option")} value={optionLists[i].listName}>{optionLists[i].listName}</option>)
    }
    optionsForSelect.push(<option key={"new-option-list"} value="new-option-list">Start new option list</option>);
    return optionsForSelect
  }

  updateNewOptionListName(event){
    event.preventDefault();
    this.props.updateNewOptionListName(event.target.value);
  }

  selectOptionsList(event){
    event.preventDefault();
    this.state.selectOptionsList(event.target.value);
  }

  startOptionList(event, newOptionListName){
    event.preventDefault();
    this.props.startOptionList(newOptionListName);
  }

  optionListNameInput(){
    if(this.state.currentOptionList === "new-option-list"){
      return (
        <div>
          <label>Name of Options List </label>
          <input className="form-control" name="option_list_name" type="text" placeholder="option_list_name" onChange={(event) => this.updateNewOptionListName(event)}/>
          <small>What is this a list of? People, places, things, etc</small>
          <div className="option-help"></div>
          <button key="options-form-button" type="button" className="yo btn btn-default" onClick={(event) => this.startOptionList(event, this.state.newOptionListName)}>Start List</button>
        </div>
      );
    }
    else{
      return <div />;
    }
  }

  render () {
    return (
      <div>
        <select value={this.state.currentOptionList} className="form-control" name="option-list-select" multiple={false} onChange={(event) => this.selectOptionsList(event)}>
          {this.getListNameOptions()}
        </select>
        {this.optionListNameInput()}
      </div>
    );
  }
}

export default OptionListSelect
