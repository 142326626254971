import React from "react"
import PropTypes from "prop-types"
class FormPreview extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      formFields: props.formFields,
      editField: props.editField,
      buildForm: props.buildForm,
      getLocation: props.getLocation,
      deleteField: props.deleteField,
      showControls: false,
      formIsBeingBuilt: props.formIsBeingBuilt,
      formHasBeenBuilt: props.formHasBeenBuilt,
      formBuildFailed: props.formBuildFailed,
      surveyRecord: props.surveyRecord,
      buildAnother: props.buildAnother,
      gettingLocation: props.gettingLocation,
      optionLists: props.optionLists,
      rootUrl: props.rootUrl
    };
    this.buildForm = this.buildForm.bind(this);
    this.showControls = this.showControls.bind(this);
    this.hideControls = this.hideControls.bind(this);
    this.editField = this.editField.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.deleteField = this.deleteField.bind(this);
    this.buildAnother = this.buildAnother.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      formFields: nextProps.formFields,
      formIsBeingBuilt: nextProps.formIsBeingBuilt,
      formHasBeenBuilt: nextProps.formHasBeenBuilt,
      formBuildFailed: nextProps.formBuildFailed,
      surveyRecord: nextProps.surveyRecord,
      gettingLocation: nextProps.gettingLocation,
      optionLists: nextProps.optionLists
    });
  }

  buildForm(event) {
    event.preventDefault();
    this.state.buildForm();
  }

  hideControls() {
    this.setState({
      showControls: false
    })
  }

  showControls() {
    this.setState({
      showControls: true
    })
  }

  editField(field) {
    this.state.editField(field);
  }

  deleteField(field) {
    this.state.deleteField(field);
  }

  getLocation(event) {
    this.setState({
      gettingLocation: true
    })
    this.state.getLocation(event);
  }

  buildAnother(event){
    this.state.buildAnother();
  }

  getOptionsForSelectOne(field){
    let optionsForSelect = [<option key={"empty-option"} value="">-select-</option>];
    let optionLists = this.state.optionLists;
    for(let x = 0; x < optionLists.length; x ++){
      if(optionLists[x].option_list_name === field.options){
        let optionKey;
        optionLists[x].options.forEach( function(option, i){
          optionKey = (i + 1).toString();
          optionsForSelect.push(<option key={optionKey.concat("-option")} value={option.option_name}>{option.option_labelEnglish}</option>)
        })
        break;
      }
    }
    return optionsForSelect
  }

  getOptionsForSelectMultiple(field){
    let optionsForSelect = [];
    let optionLists = this.state.optionLists;
    for(let x = 0; x < optionLists.length; x ++){
      if(optionLists[x].option_list_name === field.options){
        let optionKey;
        optionLists[x].options.forEach( function(option, i){
          optionKey = (i + 1).toString();
          optionsForSelect.push({option_name: option.option_name, option_labelEnglish: option.option_labelEnglish})
        })
        break;
      }
    }
    return optionsForSelect
  }

  renderFormPreview(){
    let that = this;
    let formFields = JSON.parse(this.state.formFields);
    let list = [];
    let key;
    formFields.forEach(function(field, i){
      key = (i + 1).toString();
      let name = field.name;
      let type = field.type;
      let label = field.labelEnglish;
      switch(type) {
        case "string":
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
                {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <input className="form-control" name={name} type="text" placeholder="This is great field for short answers."/>
            </div>
          );
        break;
        case "text":
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
                {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <textarea className="form-control" name={name} placeholder="This is a great field to hold lots of text. Like the description of an experience, or maybe extra comments. Seriously, a lot of text can fit in this box."></textarea>
            </div>
          );
        break;
        case "int":
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
                {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <input className="form-control" name={name} type="number" placeholder="7"/>
            </div>
          );
        break
        case "decimal":
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
                {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <input className="form-control" name={name} type="number" step="0.01" placeholder="7.5"/>
            </div>
          );
        break;
        case "dateTime":
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
                {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <input className="form-control" type={"datetime-local"} name={name}/>
            </div>
          );
        break;
        case "date":
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
                {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <input className="form-control" type={"date"} name={name}/>
            </div>
          );
        break;
        case "time":
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
                {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <input className="form-control" name={name} type="time"/>
            </div>
          );
        break;
        case "note":
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <input className="form-control" name={name} type="text" readOnly/>
              <div key={key.concat("-note")} className="">
                <h3>{label}
                  {that.state.showControls &&   <small onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</small>}
                  {that.state.showControls &&   <small onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</small>}
                </h3>
              </div>
            </div>
          );
        break;
        case "geopoint":
          let spinner = <div className="form-btn"><div className="spinner"><div className="double-bounce1"></div><div className="double-bounce2"></div></div></div>
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
              {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <button type="button" className="yo btn btn-default" onClick={(event) => that.getLocation(event)}>{(that.state.gettingLocation) ? spinner : "Record Location"}</button>
              <input className="form-control" name={name} type="text" readOnly/>
            </div>
          );
        break;
        case "select":
          let checkBoxOptions = [];
          let options = that.getOptionsForSelectMultiple(field);
          let multiOptionKey;
          options.forEach(function(option, i) {
            multiOptionKey = (i + 1).toString();
            checkBoxOptions.push(<div key={multiOptionKey.concat("-div")}><label><input className="form-checkbox" key={multiOptionKey.concat("-option")} type="checkbox" name={name} value={option.option_name} />{option.option_labelEnglish}</label></div>)
          });
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
              {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              {checkBoxOptions}
            </div>
          );
        break;
        case "select1":
          let optionsForSelect1 = that.getOptionsForSelectOne(field);
          list.push(
            <div key={key} className="form-group" onMouseLeave={that.hideControls} onMouseEnter={that.showControls}>
              <label>
              {label}
                {that.state.showControls &&   <span onClick={(event) => that.editField(field, event)} className="form-builder-field-controls">Edit</span>}
                {that.state.showControls &&   <span onClick={(event) => that.deleteField(field, event)} className="form-builder-field-controls">Delete</span>}
              </label>
              <select className="form-control" name={name} multiple={false} onChange={that.handleChange}>
                {optionsForSelect1}
              </select>
            </div>
          );
        break;
      }
    })
    let builderStatus;
    let spinner = <div className="form-btn"><div className="spinner"><div className="double-bounce1"></div><div className="double-bounce2"></div></div></div>
    if(this.state.formBuildFailed){
      builderStatus =
      <div className="modal fade builder-modal">
       <div className="modal-dialog">
         <div>
         <p>Something terrible has happened! Try <span onClick={(event) => this.buildAnother(event)} className="build-another">building another form</span>.</p>
         </div>
       </div>
      </div>
    }else if (this.state.formHasBeenBuilt){
      builderStatus =
      <div className="modal fade builder-modal">
       <div className="modal-dialog">
         <div>
         <h2>Your Form has been built!</h2>
         <p data-toggle="tooltip" data-placement="top" title="This link is public and accessible to anybody without a login. To use private webforms, contact Standard Data.">You can start collecting data right now! Check out the web form <a href={this.state.rootUrl + "/surveys/" + this.state.surveyRecord["slug"] + "/form?ft=" + this.state.surveyRecord["web_form_token"]} target="_blank">here</a> </p>
         <p>You can also view the data manager page <a href={this.state.rootUrl+ "/surveys/" + this.state.surveyRecord["slug"] + "/records"}>here</a> </p>
         <p>Or you can <span onClick={(event) => this.buildAnother(event)} className="build-another">build another form</span>.</p>
         <p>To help you collect more data, make sure to get the mobile app, <strong>Standard Data</strong>, from the <a href="https://play.google.com/store/apps/details?id=com.sdkmobileapp">Google Play Store</a> <strong>AND</strong> the <a href="https://itunes.apple.com/us/app/secure-data-kit/id1221587450?ls=1&mt=8">Apple App Store</a> now!</p>
         </div>
       </div>
      </div>
    }
    return (
      <div className="web-form-container container">
        <h1>{this.props.title}</h1>
        <div className="web-form container builder-form">
          <form onSubmit={event => this.buildForm(event)}>
            {builderStatus}
            {list}
            <button key="submit-button" type="submit" className="yo btn btn-default">{(this.state.formIsBeingBuilt) ? spinner : "Build Form"}</button>
          </form>
        </div>
      </div>
    );
  }

  render () {
    return this.renderFormPreview();
  }
}

FormPreview.propTypes = {
  title: PropTypes.string
};
export default FormPreview
