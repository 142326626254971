import React from "react";
import PropTypes from "prop-types";
import SinglePageGroup from './SinglePageGroup';
import 'core-js/stable';
import fetch from 'isomorphic-fetch';
import {isRelevant, evalCalculation } from './lib/EvalExpressHelper';
import {getGroupEndIndex, getGroupIndex, isEmpty, getGroup, cleanRecord, cleanRepeatersRecord, isVisible, CreateFormData } from './lib/SurveyHelper';
import Field from './Field';
import PagingRepeater from './PagingRepeater';
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from './Spinner';
import { v4 as uuidv4 } from 'uuid';

var CACHE_STATE_NAME = "SurveyFormPagingCache";
var Pages = {
  LANGUAGE_SELECT: 1,
  CHILDREN_SURVEYS: 2,
  FIELDS: 3,
  CONFIRMATION: 4
};

//var recaptchaRef = React.createRef();

function initialRecord(form, currentUser){
  var record = {};
  form.fields.forEach(function(field, i){
    if(field.metadata){
      switch(field.name) {
        case "email":
          record['email'] = currentUser? currentUser.email: null;
          break;
      }
    }
  });
  return record;
}

function initialState(props){
  let cacheID = `${CACHE_STATE_NAME}_${props.survey.id}_${props.currentUser.id}`
  let cacheState = localStorage.getItem(cacheID)
  if(cacheState){
    let state = JSON.parse(cacheState)
    if (props.survey.updated_at == state.cacheID.surveyUpdatedAt){
      return {
        form: props.form,
        survey: props.survey,
        submissionUrl: props.submission_url,
        currentUser: props.currentUser,
        record: state.record,
        repeatersRecord: state.repeatersRecord,
        languageIndex: state.languageIndex,
        currentRepeaterSetting: state.currentRepeaterSetting,
        formSubmitting: false,
        formSubmitted: false,
        submissionAuthFailed: null,
        serverError: null,
        loaded: true,
        fieldIndex: state.fieldIndex,
        currentPage: state.currentPage,
        grouping: state.grouping,
        relevantGroups: state.relevantGroups,
        currentGroup:  state.currentGroup,
        gettingLocation: false,
        loadFromCache: true,
        captchaSiteKey: props.captchaSiteKey
      };
    }
  }

  localStorage.removeItem(cacheID);
  let record = initialRecord(props.form, props.currentUser);
  return {
    form: props.form,
    survey: props.survey,
    submissionUrl: props.submission_url,
    currentUser: props.currentUser,
    record: record,
    repeatersRecord: {},
    languageIndex: 0,
    currentRepeaterSetting: {},
    formSubmitting: false,
    formSubmitted: false,
    submissionAuthFailed: null,
    serverError: null,
    loaded: true,
    fieldIndex: nextFieldIndex(-1, props.form.fields, record),
    currentPage: props.form.languages.length > 1 ? Pages.LANGUAGE_SELECT: Pages.FIELDS ,
    currentGroup: null,
    gettingLocation: false,
    loadFromCache: false,
    captchaSiteKey: props.captchaSiteKey
  };
}

function nextFieldIndex(fieldIndex, fields, record) {
  for (var i = fieldIndex + 1; i < fields.length; i++) {
    let field = fields[i];
    if (field.type === 'group') {
      if (isRelevant(record, field)) {
        if (field.appearance === 'field-list' || field.is_repeater) {
          return i;
        }
      }
      else{
        i = getGroupEndIndex(fields, i, fieldName);
      }
    } else if (field.type === 'end_group') {
      continue;
    } else if ( getGroup(fields, field) && getGroup(fields, field).appearance === 'field-list') {
      // Skip fields that are in a field-list (single page) group
      continue;
    } else if (isVisible(field) && isRelevant(record, field)) {
      return i;
    }
  }
  return false;
}

function prevFieldIndex(fieldIndex, fields, record) {
  for (var i = fieldIndex - 1; i > -1; i--) {
    let field = fields[i];
    if (field.type === 'group') {
      if (isRelevant(record, field)) {
        // Group is relevant
        if (field.appearance === 'field-list') {
          return i;
        }
      }
      else{
        i = getGroupEndIndex(fields, i, fieldName);
      }
    } 
    else if (field.type === 'end_group' && getGroup(fields, field.name).is_repeater) {
      let groupIndex = getGroupIndex(fields, 0, field.name);
      if (isRelevant(record, fields[groupIndex])) {
        return groupIndex;
      }
    }
    else if (getGroup(fields, field) && getGroup(fields, field).appearance === 'field-list') {
    }
    else if (isVisible(field) && isRelevant(record, field)) {
      return i;
    }
  }
  return false;
}

function createRecordsInRepeaterRecord(repeatersRecord, field, recordCount){
  let rRecords = [];
  repeatersRecord = {...repeatersRecord};
  if(repeatersRecord && Object.keys(repeatersRecord).includes(field.name)){
    rRecords = [...repeatersRecord[field.name]];
  }

  if(rRecords.length < recordCount){
    let currentRecordLength =  rRecords.length;
    for(let cnt =0; cnt < recordCount - currentRecordLength; cnt++){
      rRecords.push({id: uuidv4()});
    }
  }
  else if(rRecords.length > recordCount){
    rRecords = rRecords.slice(0, recordCount)
  }
  repeatersRecord[field.name] = rRecords;
  return repeatersRecord;
}

class SurveyFormPaging extends React.Component {

  constructor(props){
    super(props);
    this.state = initialState(props)
    this.setLanguage = this.setLanguage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGroupedCheckboxes = this.handleGroupedCheckboxes.bind(this);
    this.prevField = this.prevField.bind(this);
    this.nextField = this.nextField.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.enterNext = this.enterNext.bind(this);
    this.warnBeforeLeaving = this.warnBeforeLeaving.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.closePage = this.closePage.bind(this);
    this.handleCalculation = this.handleCalculation.bind(this);
    this.handleRepeater = this.handleRepeater.bind(this);
    this.addIntervalCaching = this.addIntervalCaching.bind(this);
    this.removeIntervalCaching = this.removeIntervalCaching.bind(this);
    this.handleCaching = this.handleCaching.bind(this);
    this.loadFromCache = this.loadFromCache.bind(this);
    this.loadNew = this.loadNew.bind(this);
    this.removeCaching = this.removeCaching.bind(this);
    this.updateRepeaterRecords = this.updateRepeaterRecords.bind(this);
    this.beforeRepeaterForm = this.beforeRepeaterForm.bind(this);
    this.afterRepeaterForm = this.afterRepeaterForm.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.updateFiles = this.updateFiles.bind(this);
    this.updateRepeaterSetting = this.updateRepeaterSetting.bind(this);
    this.handleRemoveValue = this.handleRemoveValue.bind(this);
  }

  componentWillMount(){
    this.addCacheListener();
  }

  addCacheListener(){
    if(!this.state.loadFromCache){
      this.addIntervalCaching();
      window.addEventListener("focus", this.addIntervalCaching);
      window.addEventListener("blur", this.removeIntervalCaching);
    }
  }

  addIntervalCaching(){
    if(!this.intervalId){
      this.intervalId = setInterval(this.handleCaching, 1000);
    }
  }

  removeIntervalCaching(){
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  handleCaching(){
    let state = this.state;
    if (state.record || state.languageIndex || state.fieldIndex || state.currentPage || state.repeatersRecord || state.currentRepeaterSetting){
      let cacheState = {
        record: state.record, 
        repeatersRecord: state.repeatersRecord,
        languageIndex: state.languageIndex,
        fieldIndex: state.fieldIndex,
        currentPage: state.currentPage,
        grouping: state.grouping,
        relevantGroups: state.relevantGroups,
        currentGroup:  state.currentGroup,
        currentRepeaterSetting: state.currentRepeaterSetting,
        cacheID:{
          surveyID: this.state.survey.id, 
          surveyUpdatedAt: this.state.survey.updated_at,
          currentUserID: this.state.currentUser.id
        }
      }
      localStorage.setItem(`${CACHE_STATE_NAME}_${this.state.survey.id}_${this.state.currentUser.id}`, JSON.stringify(cacheState))
    }
  }

  removeCaching(){
    localStorage.removeItem(`${CACHE_STATE_NAME}_${this.state.survey.id}_${this.state.currentUser.id}`);
  }

  loadFromCache(){
    this.setState({loadFromCache:false}, this.addCacheListener);
  }

  loadNew(){
    this.removeCaching();
    let state = initialState(this.props);
    if (this.state.form.languages.length > 1) {
      state.currentPage = Pages.LANGUAGE_SELECT;
    }else {
      state.currentPage = Pages.FIELDS;
    }
    state.loadFromCache = false;
    state.loaded=true;
    this.setState(state, ()=>{
      this.addCacheListener();
    });
  }

  warnBeforeLeaving(){
    return "This survey is not complete. You will lose your data if you do not submit this form."
  }

  enterNext(e){
    if (e.key === 'Enter') {
      if(this.state.currentPage === Pages.LANGUAGE_SELECT){
        this.next();
      }else if(this.state.currentPage === Pages.FIELDS){
        this.nextField();
      }else{

      }
    }
  }

  setLanguage(event){
    this.setState({
      languageIndex: event.target.value
    })
  }

  getLocation(event){
    this.setState({
      gettingLocation: true
    })
    let output = event.target.parentElement.parentElement.lastChild;
    let newRecord = this.state.record;
    let name = event.target.name;
    if (!navigator.geolocation){
      output.value = "Geolocation is not supported by your browser";
      return;
    }
    let success = (position) => {
      newRecord[name + "_lat"] = position.coords.latitude
      newRecord[name + "_lng"] = position.coords.longitude
      newRecord[name + "_alt"] = position.coords.altitude
      newRecord[name + "_acc"] = position.coords.accuracy

      this.setState({
        gettingLocation: false,
        record: newRecord
      });
      output.value = `Latitude: ${position.coords.latitude} Longitude: ${position.coords.longitude} Altitude: ${position.coords.altitude} Accuracy: ${position.coords.accuracy}`
    }

    let error = () => {
      output.value = "Unable to retrieve your location";
      this.setState({
        gettingLocation: false
      })
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }

  next() {
    switch (this.state.currentPage) {
      case Pages.LANGUAGE_SELECT:
        if (this.hasChildrenSurveys()) {
          this.setState({currentPage: Pages.CHILDREN_SURVEYS});
        } else {
          this.setState({currentPage: Pages.FIELDS});
        }
        break;
      case Pages.CHILDREN_SURVEYS:
        this.setState({currentPage: Pages.FIELDS});
        break;
      case Pages.FIELDS:
        this.setState({currentPage: Pages.CONFIRMATION});
        break;
    }
  }

  prev() {
    switch (this.state.currentPage) {
      case Pages.CHILDREN_SURVEYS:
        if (this.state.form.languages.length > 1) {
          this.setState({currentPage: Pages.LANGUAGE_SELECT});
        }
        break;
      case Pages.FIELDS:
        if (this.hasChildrenSurveys()) {
          this.setState({currentPage: Pages.CHILDREN_SURVEYS});
        } else if (this.state.form.languages.length > 1) {
          this.setState({currentPage: Pages.LANGUAGE_SELECT});
        }
        break;
      case Pages.CONFIRMATION:
        this.setState({currentPage: Pages.FIELDS}, this.prevField);
        break;
    }
  }

  nextField() {
    let valid = false;
    let currentField = this.currentField();
    if (currentField.type === 'group' && currentField.appearance === 'field-list') {
      if (this.validateGroup(currentField.name)) {
        valid = true;
      }
    } else if (this.validateField(currentField)) {
      valid = true;
    }
    if (valid) {
      let form = this.state.form;
      let nextIndex = nextFieldIndex(this.state.fieldIndex, form.fields, this.state.record);
      if(nextIndex === false){
        this.setState({currentPage: Pages.CONFIRMATION});
        return;
      }

      let nextField = form.fields[nextIndex];
      if(nextField.type == "group" && nextField.is_repeater){
        let repeaterCount = nextField.repeater_count;
        if(repeaterCount){
          let recordCount = 0;
          let rptCountVal = this.state.record[repeaterCount.field];
          recordCount = parseInt(this.state.record[repeaterCount.field]);
          if(!rptCountVal || recordCount == 0){
            let groupEndIndex = getGroupEndIndex(fields, nextIndex, field.name);
            let rptFieldIndex = nextFieldIndex(groupEndIndex, form.fields, this.state.record);
            let setting = {fieldIndex: rptFieldIndex};
            this.setState({fieldIndex: nextIndex, currentRepeaterSetting: setting});
          }
          else{
            let repeatersRecord = createRecordsInRepeaterRecord(this.state.repeatersRecord, nextField, recordCount);
            let rptRecords = repeatersRecord[nextField.name];
            let record = {...this.state.record}
            if(rptRecords.length > 0){
              record = {...this.state.record, ...rptRecords[0]}
            }

            let rptFieldIndex = nextFieldIndex(nextIndex, form.fields, record);
            let setting = {fieldIndex: rptFieldIndex, recordIndex: 0};
            this.setState({fieldIndex: nextIndex, repeatersRecord: repeatersRecord, currentRepeaterSetting: setting});
          }
        }
        else{
          let setting = {fieldIndex: nextIndex};
          let repeatersRecord = {}; repeatersRecord[nextField.name] = [];
          if(this.state.repeatersRecord && Object.keys(this.state.repeatersRecord).includes(nextField.name)){
            repeatersRecord = {...this.state.repeatersRecord};
          }
          let rptRecords = repeatersRecord[nextField.name];
          if(rptRecords.length > 0){
            let record = {...this.state.record, ...rptRecords[0]};
            setting.fieldIndex = nextFieldIndex(nextIndex, form.fields, record);
            setting.recordIndex = 0;
          }
          this.setState({fieldIndex: nextIndex, repeatersRecord: repeatersRecord, currentRepeaterSetting: setting});
        }
        return;
      }

      if (nextIndex) {
        this.setState({fieldIndex: nextIndex});
      } else {
        this.next();
      }
      
    }
  }

  prevField() {
    let field = this.currentField();
    let form = this.state.form;

    if(field.type === "end_group"){
      let groupFieldIndex = getGroupIndex(form.fields, 0, field.name);
      let group = form.fields[groupFieldIndex];
      let rptRecords = this.state.repeatersRecord[group.name]
      let rptFieldIndex = this.state.fieldIndex + 1;
      let recordIndex = 0;
      if(rptRecords.length > 0){
        recordIndex = rptRecords.length - 1;
      }
      
      let setting = {fieldIndex: rptFieldIndex, recordIndex: recordIndex};
      this.setState({fieldIndex: groupFieldIndex, currentRepeaterSetting: setting });
      return;
    }

    let prevIndex = prevFieldIndex(this.state.fieldIndex, this.state.form.fields, this.state.record);
    if (prevIndex !== false) {
      let form = this.state.form;
      let field = form.fields[prevIndex];
      if(field.is_repeater){
        let rptFieldIndex = prevIndex;
        let recordIndex = null;
        if(this.state.repeaterRecords){
          if(this.state.repeatersRecord && Object.keys(this.state.repeatersRecord).includes(field.name) && this.state.repeatersRecord[field.name].length > 0){
            recordIndex = this.state.repeatersRecord[field.name].length - 1;
            rptFieldIndex = getGroupEndIndex(form.fields, prevIndex, field.name);
          }
        }
        let setting = { 
          fieldIndex: rptFieldIndex,
          recordIndex: recordIndex
        };
        this.setState({fieldIndex: prevIndex, currentRepeaterSetting: setting})
      }
      else{
        this.setState({fieldIndex: prevIndex});
      }
    } else {
      this.prev();
    }
  }

  currentField() {
    return this.state.form.fields[this.state.fieldIndex];
  }

  handleChange(event) {
    let recordCopy = this.state.record;
    recordCopy[event.target.name] = event.target.value
     
    recordCopy = this.handleCalculation(recordCopy);
    this.setState({
      record: recordCopy
    });
  }

  handleMultiSelectChange(event){
    var options = event.target.options;
    let name = event.target.name;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    let newRecord = this.state.record;
    newRecord[name] = value;
     
    newRecord = this.handleCalculation(newRecord)
    this.setState({
      record: newRecord
    });
  }

  handleGroupedCheckboxes(field, option, options, event){
    let name = event.target.name;
    let value = event.target.value
    let newRecord = this.state.record;
    if(typeof newRecord[name] === 'undefined'){
      newRecord[name] = []
    }
    else{
      if(option.filter){
        // check if existing checked values should be filtered
        for (let i = 0; i < newRecord[name].length; i++){
          for (let x = 0; x < options.length; x++){
            if (newRecord[name][i] === options[x].name){
              if (field.group_name){
                let groupFilter = field.group_name + "_" + options[x].filter.field;
                if(newRecord[groupFilter] !== options[x].filter.value){
                  let index = newRecord[name].indexOf(newRecord[name][i]);
                  if (index > -1) {
                    newRecord[name].splice(index, 1);
                  }
                }
              }
              else if(newRecord[options[x].filter.field] !== options[x].filter.value){
                let index = newRecord[name].indexOf(newRecord[name][i]);
                if (index > -1) {
                  newRecord[name].splice(index, 1);
                }
              }
            }
          }
        }
      }
    }
    if(newRecord[name].includes(value)){
      let index = newRecord[name].indexOf(value);
      if (index > -1) {
        newRecord[name].splice(index, 1);
      }
    }else{
      newRecord[name].push(value)
    }
 
    newRecord = this.handleCalculation(newRecord)
    this.setState({
      record: newRecord
    })
    
  }

  handleCalculation(recordCopy){
    let fields = this.state.form.fields;
    for(let cnt=0; cnt < fields.length; cnt++){
      let field = fields[cnt];
      if(field.type == "group" && !field.is_repeater){
        cnt = getGroupEndIndex(fields, cnt, field.name);
        continue;
      }
      else if(!isRelevant(recordCopy, field)){
        if (field.type == "group"){
          cnt = getGroupEndIndex(fields, cnt, field.name);
        }
        continue;
      }

      let ismeta = field.name == "meta_instance_id"
      if(["group", "end_group"].includes(field.type) == false && ismeta == false) {
        if (field.calculate){
          let val = evalCalculation(recordCopy, field.calculate)
          if(val != recordCopy[field.name]){
            recordCopy[field.name] = val
          }
        }
      }
    }
    return recordCopy;
  }

  handleFileChange(event) {
    let recordCopy = {...this.state.record};
    let files = {...this.state.files};
    if (event.target.files.length > 0){
      let extensionName = event.target.files[0].name.split(".").pop();
      let filename = `${Date.now()}.${extensionName}`;
      
      files[filename] = event.target.files[0];
      recordCopy[event.target.name] = filename;
    }
    else{
      recordCopy[event.target.name] = "";
    }

    
    let relevantRecord = this.handleCalculation(recordCopy);
    let repeaterRecord = this.handleRepeater(relevantRecord)
    this.setState({
      record: relevantRecord,
      repeatersRecord: repeaterRecord,
      files: files
    });
  }


  handleRemoveValue(e){
    let recordCopy = {...this.state.record};
    let files = {...this.props.files};
    delete files[recordCopy[e.target.value]];
    delete recordCopy[e.target.value];


    let relevantRecord = this.handleCalculation(recordCopy)
    this.setState({
      record: relevantRecord
    });

    this.updateFiles(files);
  }

  updateFiles(files){
    this.setState({
      files: files
    });
  }

  handleRepeater(recordCopy){
    let repeaterRecord = {...this.state.repeatersRecord};
    let fields = this.props.form.fields.filter(e=> e.is_repeater);
    for(let cnt=0; cnt < fields.length; cnt++){
      let field = fields[cnt];
      let repeaterCount = field.repeater_count;
      if(repeaterCount){
        let rRecords = repeaterRecord[field.name];
        if(!rRecords){ rRecords = [] }
  
        let rowCount = 0;
        let rptCountVal = recordCopy[repeaterCount.field];
        if(!rptCountVal){
          repeaterRecord[field.name] = [];
          continue;
        }
  
        rowCount = parseInt(recordCopy[repeaterCount.field]);
        if(!rowCount || rowCount == 0){
          repeaterRecord[field.name] = [];
          continue;
        }
  
        let rRecordsLength = rRecords.length;
        if(rRecordsLength < rowCount){
          for(let cnt =0; cnt < rowCount - rRecordsLength; cnt++){
            rRecords.push({id: uuidv4()});
          }
          repeaterRecord[field.name] = rRecords
        }
        else if(rRecordsLength > rowCount){
          rRecords = rRecords.slice(0, rowCount)
          repeaterRecord[field.name] = rRecords
        }
      }
    }
    return repeaterRecord;
  }

  updateRepeaterRecords(repeater, records){
    let repeatersRecord = this.state.repeatersRecord;
    if(!repeatersRecord){
      repeatersRecord = {};
    }
    repeatersRecord[repeater.name] = records;
    this.setState({repeatersRecord: repeatersRecord, rptStartAtInputField: false});
  }

  updateRepeaterSetting(setting){
    let field = this.state.form.fields[this.state.fieldIndex];
    if(field.type == "end_group"){
      let groupIndex = getGroupIndex(this.state.form.fields, 0, field.name);
      let group = this.state.form.fields[groupIndex];
      if(group.is_repeater){
        this.setState({fieldIndex: groupIndex, currentRepeaterSetting: setting});
      }
      else{
        this.setState({currentRepeaterSetting: setting});
      }
    }
    else{
      this.setState({currentRepeaterSetting: setting});
    }
  }

  beforeRepeaterForm(groupBeginIndex){
    this.setState({fieldIndex: groupBeginIndex}, this.prevField);
  }

  afterRepeaterForm(nextToGroupEndIndex){
    this.setState({fieldIndex: nextToGroupEndIndex}, this.nextField);
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState({formSubmitting: true});
    let that = this;

    that.executeSubmit("")
    // recaptchaRef.current.executeAsync().then(response=>{
    //   if(!response){
    //     this.setState({formSubmitting: false, submissionAuthFailed: true});
    //   }
    //   else{
    //     that.executeSubmit(response)
    //   }
    // });
  }

  executeSubmit(captchaResponse){
    this.setState({formSubmitting: true});
    let that = this;
    that.removeIntervalCaching();
    let record = cleanRecord(this.state.record, this.state.form.fields);
    let repeatersRecord = cleanRepeatersRecord(this.state.repeatersRecord, this.state.form.fields);
    let strData = JSON.stringify({data: record, repeaters_data: repeatersRecord, current_user: this.props.currentUser});
    let formData = CreateFormData(strData, this.state.files);

    fetch(this.state.submissionUrl, {
      method: 'POST',
      headers: {
              'X-CSRF-Token': this.props.authenticity_token,
              'FT': this.state.survey.web_form_token
            },
      body: formData})
      .then(function(response) {
      response.json().then( (json) => {
        if(json.status === 200){
          that.removeCaching();
          that.setState({
            formSubmitted: true,
            formSubmitting: false,
            record: initialRecord(that.state.form, that.state.currentUser),
            languageIndex: 0,
            fieldIndex: 0,
            grouping: false,
            relevantGroups: {},
            currentGroup: null});
        }
        else if (json.status === 401){
          that.setState({
            submissionAuthFailed: true,
            formSubmitting:false
          }, that.addIntervalCaching)
        }
        else if (json.status === 500){
          that.setState({
            serverError: true,
            formSubmitting:false
          }, that.addIntervalCaching)
        }
        else{
          that.setState({
            serverError: true,
            formSubmitting:false
          }, that.addIntervalCaching);
        }
      })
    })
  }

  validateGroup(group_name) {
    let groupFields = this.getGroupFields(group_name);
    for (let i = 0; i < groupFields.length; i++) {
      let field = groupFields[i];
      if (!this.validateField(field)) {
        return false;
      }
    }
    return true;
  }

  getGroupFields(group_name) {
    let fields = this.state.form.fields;
    let groupFields = [];
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (field.group_name === group_name) {
        if (field.type !== 'group' || field.type !== 'end_group') {
          groupFields.push(fields[i]);
        }
      }
    }
    return groupFields;
  }

  validateField(field) {
    if (field.type !== 'end_group') {
      let type = field.type;
      let name = field.name;
      let label = field.labels[this.state.languageIndex].value;
      let value = this.state.record[name];
      let required = field.required;
      let constraint = field.constraint;

      // if(constraint){
      //   let constraintSatisfied = this.evaluateConstraint(constraint, value);
      //   if (constraintSatisfied == false){
      //     alert(field.constraint_labels[this.state.languageIndex].value)
      //     return false
      //   }
      // }

      switch(type) {
        case "string":
        break;
        case "select":
        break;
        case "select1":
        break;
        case "int":
        break;
        case "decimal":
        break;
        case "dateTime":
        break;
        case "date":
        break;
        case "time":
        break;
        case "geopoint":
          if (this.state.record[name.concat('_lat')]){
            value = this.state.record[name.concat('_lat')].toString();
          }
        break;
        case "binary":
        break;
        case "barcode":
          if (isVisible(field)) {
            let confirmationValue = this.state.record[name.concat('_confirmation')];
            if(isEmpty(confirmationValue)){
              alert("Please confirm the barcode.");
              return false
            }
            if(confirmationValue !== value){
              alert("The values entered for the Barcode do not match. Please confirm the barcode number.");
              return false
            }
            else{
              return true
            }
          }
        break;
        case "video":
          break;
        case "photo":
            break;
        }
      // run this check for all field types
      if (required && isVisible(field)) {
        if (isEmpty(value)) {
          alert(label + " is required.");
          return false;
        }
      }
    }
    return true;
  }

  renderField(){
    if (this.state.fieldIndex < 0){
      return "";
    }
    let list = [];
    let groupLabel = '';
    let i = this.state.fieldIndex;
    let field = this.state.form.fields[i];
    let key;
    key = (i + 1).toString();
    let type = field.type;
    let label = "";
    let hint = ""
    if (field.labels) {
      label = field.labels[this.state.languageIndex].value;
    }
    if (field.hints && field.hints.length > 0) {
      hint = field.hints[this.state.languageIndex].value;
    }

    key = (i + 1).toString();
    // Display group name above field
    let isGroupVisible = false;
    let currentGroup = getGroup(this.state.form.fields, field);
    if (currentGroup && currentGroup.appearance !== 'field-list') {
      //group = <h3>{this.state.relevantGroups[field.group_name]}</h3>
      if (currentGroup.labels) {
        groupLabel = currentGroup.labels[this.state.languageIndex].value;
      }
      isGroupVisible = true;
    }

    if(type == "group" && field.appearance === 'field-list') {
      list.push(
        <SinglePageGroup
          survey={this.state.survey}
          record={this.state.record}
          form={this.state.form}
          group={field}
          languageIndex={this.state.languageIndex}
          fieldIndex={i}
          key={key} />
      );
    }
    else if(type == "group" && field.is_repeater){
      let endIndex = getGroupEndIndex(this.state.form.fields, 0, field.name);
      let repeaterRecords  = [...this.state.repeatersRecord[field.name]];


      list.push(<PagingRepeater key={key}
        start_at = "beginning"
        languageIndex= {this.state.languageIndex}
        form= {this.state.form}
        repeaterField={field}
        files={this.state.files}
        fieldIndex={this.state.fieldIndex}
        parentRecord = {this.state.record}
        repeaterRecords={repeaterRecords}
        fixRecordLength={field.repeater_count?true:false}
        repeaterBeginIndex={this.state.fieldIndex}
        repeaterEndIndex={endIndex}
        setting={this.state.currentRepeaterSetting}
        updateRepeaterRecords={this.updateRepeaterRecords}
        beforeRepeaterForm={this.beforeRepeaterForm}
        afterRepeaterForm={this.afterRepeaterForm}
        updateRepeaterSetting={this.updateRepeaterSetting}
        updateFiles={this.updateFiles}
        />);
    }
    else if(type != "end_group"){
      list.push( <Field key={key}
        fieldItem={field} 
        groupLabel={groupLabel}
        isGroupVisible={isGroupVisible}
        record={this.state.record}
        files={this.state.files}
        handleChange={this.handleChange}
        handleMultiSelectChange={this.handleMultiSelectChange}
        handleGroupedCheckboxes={this.handleGroupedCheckboxes}
        handleFileChange={this.handleFileChange}
        handleRemoveValue={this.handleRemoveValue}
        getLocation={this.getLocation}
        gettingLocation={this.state.gettingLocation}
        languageIndex= {this.state.languageIndex} 
        />);
    }

    let buttons = "";
    let showButtons = true;
    if(field.type == "begin" || field.is_repeater){
      showButtons = false
    }
    else if(field.type == "end_group"){
      let groupIndex = getGroupIndex(this.state.form.fields, 0, field.name);
      let group = this.state.form.fields[groupIndex];
      if (group.is_repeater){
        showButtons = false;
      }
    }

    if(showButtons){
      if(this.state.fieldIndex > 0){
        buttons = <div className="form-buttons">
                    <button key="prev-button" type="button" className="yo btn btn-default prev-button" onClick={this.prevField}>PREVIOUS</button>
                    <button key="next-button" type="button" className="yo btn btn-default next-button" onClick={this.nextField}>NEXT</button>
                  </div>
      }
      else{
        buttons = <div className="form-buttons">
                    <button key="next-button" type="button" className="yo btn btn-default solo-next" onClick={this.nextField}>NEXT</button>
                  </div>
      }
    }

    return (
      <div className="web-form container" onKeyPress={this.enterNext}>
        <form>
          {list}
          {buttons}
        </form>
      </div>
    );
  }

  renderLanguageSelect(){
    let languageOptions = [];
    let languages = this.state.form.languages;
    let optionKey;
    languages.forEach( function(option, i){
      optionKey = (i + 1).toString();
      languageOptions.push(<option key={optionKey.concat("-language")} value={i}>{option}</option>)
    })
    return (
      <div className="web-form container" onKeyPress={this.enterNext}>
        <div className="form-group">
          <label>Please select language</label>
          <select className="form-control" name="language_select" multiple={false} onChange={this.setLanguage} value={this.state.languageIndex}>
            {languageOptions}
          </select>
        </div>
        <button key="next-button" type="button" className="yo btn btn-default" onClick={this.next}>NEXT</button>
      </div>
    );
  }

  renderConfirmation(){
    return (
      <div className="web-form container">
        <form>
          <div className="form-group">
            <h3 className="text-center">"Almost done. Click 'SUBMIT' below to save your form, or click 'PREV' to go back."</h3>
          </div>
          <div className="form-buttons">
            <button key="prev-button" type="button" className="yo btn btn-default prev-button" disabled={this.state.formSubmitting} onClick={this.prev}>PREVIOUS</button>
            <button  key="submit-button" type="submit" className="yo btn btn-default submit-button" disabled={this.state.formSubmitting} onClick={this.handleSubmit} >{ this.state.formSubmitting? <Spinner /> : "SUBMIT"}</button>
            {/* <ReCAPTCHA
            sitekey={this.state.captchaSiteKey}
            size="invisible"
            ref={recaptchaRef} /> */}
          </div>
        </form>
      </div>
    );
  }

  refreshPage(){
    var currentPage = null;
    if (this.state.form.languages.length > 1) {
      currentPage = Pages.LANGUAGE_SELECT;
    } else if (this.hasChildrenSurveys()) {
      currentPage = Pages.CHILDREN_SURVEYS;
    } else {
      currentPage = Pages.FIELDS;
    }
    var defaultValue = {
      ...this.state,
      record: initialRecord(this.state.form, this.state.currentUser),
      languageIndex: 0,
      formSubmitting: false,
      formSubmitted: false,
      submissionAuthFailed: null,
      serverError: null,
      loaded: true,
      fieldIndex: nextFieldIndex(-1, this.state.form.fields, {}),
      currentPage: currentPage,
      grouping: false,
      relevantGroups: {},
      currentGroup: null,
      gettingLocation: false,
      loadFromCache: false 
    }

    this.setState(defaultValue, this.addCacheListener);
  }

  closePage(){
    window.postMessage("closeSurveyForm", window.location.origin);
  }

  render () {
    if(this.state.loaded){
      if(this.state.formSubmitted){
        window.onbeforeunload = null;
        return (
          <div className="web-form container">
            <h2>Thank you for filling out the form.</h2>
            <p>Your information has been collected successfully.</p>
          </div>
        )
      }
      else if (this.state.submissionAuthFailed){
        window.onbeforeunload = null;
        return (
          <div className="web-form container">
            <h2>You are not authorized to submit data.</h2>
          </div>
        )
      }
      else if (this.state.serverError){
        window.onbeforeunload = null;
        return (
          <div className="web-form container">
          <h2>Submission Failed</h2>
          <p>There has been an error on the server, and your data has not been saved. We apologize for the inconvienience.</p>
          </div>
        )
      }
      else if(this.state.loadFromCache){
        return (
          <div className="web-form container">
          <h2>Do you want to continue with the previous unsubmitted form?</h2>
          <div className="form-buttons">
            <button key="continue-button" type="button" className="yo btn btn-default" onClick={this.loadFromCache}>
                Continue
            </button>
            <button key="next-button" type="button" className="yo btn btn-default" onClick={this.loadNew}>
                No
            </button>
          </div>
          </div>
        )
      }
      else{
        // return this.renderSurvey();
        window.onbeforeunload = this.warnBeforeLeaving;
        switch (this.state.currentPage) {
          case Pages.LANGUAGE_SELECT:
            return this.renderLanguageSelect();
          case Pages.CHILDREN_SURVEYS:
            return this.renderChildrenSurveys();
          case Pages.FIELDS:
            return this.renderField();
          case Pages.CONFIRMATION:
            return this.renderConfirmation();
        }
      }
    }
  }

}

SurveyFormPaging.propTypes = {
  title: PropTypes.string
};
export default SurveyFormPaging
