import React from "react"
import PropTypes from "prop-types"
import FieldListItem from "./FieldListItem"
import FieldListGroupItem from "./FieldListGroupItem"
class FieldList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentlyBuilding: props.currentlyBuilding,
      projectUrl: props.projectUrl,
      startBuilding: props.startBuilding,
      updateTitle: props.updateTitle,
      updateSource: props.updateSource,
      updateSourceUrl: props.updateSourceUrl,
      fields: props.fields,
      showControls: false,
      formTitle: props.formTitle,
      formSource: props.formSource,
      formSourceUrl: props.formSourceUrl,
      defaultLanguage: props.defaultLanguage,
      secondaryLanguage: props.secondaryLanguage,
      useSecondaryLanguage: props.useSecondaryLanguage,
      updateMultiLanguage: props.updateMultiLanguage,
      updateUseSecondaryLanguage: props.updateUseSecondaryLanguage
    }
    this.startBuilding = this.startBuilding.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.updateSource = this.updateSource.bind(this);
    this.updateSourceUrl = this.updateSourceUrl.bind(this);
    this.controlField = this.controlField.bind(this);
    this.groupControlField = this.groupControlField.bind(this);
    this.update1stLanguage = this.update1stLanguage.bind(this);
    this.update2ndLanguage = this.update2ndLanguage.bind(this);
    this.toggleUserSecondaryLanguage = this.toggleUserSecondaryLanguage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state,
      formTitle: nextProps.formTitle,
      formSource: nextProps.formSource,
      formSourceUrl: nextProps.formSourceUrl,
      currentlyBuilding: nextProps.currentlyBuilding,
      fields: nextProps.fields,
      useSecondaryLanguage: nextProps.useSecondaryLanguage,
      defaultLanguage: nextProps.defaultLanguage,
      secondaryLanguage: nextProps.secondaryLanguage,
    })
  }

  startBuilding(event){
    event.preventDefault();
    this.state.startBuilding();
  }

  updateTitle(event) {
    this.state.updateTitle(event.target.value);
  }

  updateSource(event) {
    this.state.updateSource(event.target.value);
  }

  updateSourceUrl(event) {
    this.state.updateSourceUrl(event.target.value);
  }

  update1stLanguage(event){
    this.state.updateMultiLanguage(event.target.value, 0);
  }

  update2ndLanguage(event){
    this.state.updateMultiLanguage(event.target.value, 1);
  }

  toggleUserSecondaryLanguage(event){
    this.state.updateUseSecondaryLanguage(!this.state.useSecondaryLanguage);
  }

  controlField(field, control){
    this.props.controlField(field, control);
  }

  groupControlField(groupField, field, control){
    this.props.groupControlField(groupField, field, control);
  }

  render () {
    if (!this.state.currentlyBuilding){
      return (
        <div>
          {this.basicInfo()}
          <h2>Fields</h2>
          <div className="text-center"><small>No Fields Yet</small></div>
          <a className="btn btn-primary btn-block first-field" href="javascript:void(0)" onClick={(event) => this.props.newField()}>Add a Field to your Form</a>
        </div>
      )
    }
    else if(this.state.currentlyBuilding && this.state.fields.length === 0){
      return (
        <div>
          {this.basicInfo()}
          <h2>Fields <a className="first-field add-field" href="javascript:void(0)" onClick={(event) => this.props.newField()}>Add new field</a></h2>
          <div className="text-center"><small>No Fields Yet</small></div>
        </div>
      )
    }
    else if (this.state.currentlyBuilding && this.state.fields.length > 0){
      let sortedList = this.state.fields.sort((a, b) =>{
        return parseFloat(a.index) - parseFloat(b.index);
      })
      let list = sortedList.map((field, i) =>{
          if(field.type== "group"){
            return <FieldListGroupItem
            field={field}
            controlField={this.controlField}
            groupControlField={this.groupControlField}
            key={i}
          />
          }
          else{
            return <FieldListItem
            field={field}
            controlField={this.controlField}
            key={i}
          />
          }
        }
      )
      return (
        <div>
          {this.basicInfo()}
          <h2>Fields <a className="first-field add-field" href="javascript:void(0)" onClick={(event) => this.props.newField()}>Add new field</a></h2>
          <ul className="field-list">
            {list}
          </ul>
        </div>
      )
    }
  }

  
  basicInfo(){
    let language = null
    if(this.props.operationType == "add"){
      language = ( <div>
        <h2>Languages</h2>
          <small>Default language</small>
          <input type="text" id="builder-title" className="form-control" name="title" placeholder="New title" onChange={this.update1stLanguage} value={this.state.defaultLanguage}/>
          <small><input type="checkbox" defaultChecked={this.state.useSecondaryLanguage} onChange={this.toggleUserSecondaryLanguage} /> Use Secondary language?</small>
          <input type="text" id="builder-title" className="form-control" name="title" placeholder="New title" onChange={this.update2ndLanguage} value={this.state.secondaryLanguage} disabled={!this.state.defaultLanguage}/>

      </div>)
    }
    return (
      <div>
        <a href={this.state.projectUrl} className="">Back</a>
          <h2>Form Title</h2>
          <input type="text" id="builder-title" className="form-control" name="title" placeholder="New title" value={this.state.formTitle}  onChange={this.updateTitle}/>
          <small>What should we call your form?</small>
          <h2>Source</h2>
          <input id="builder-source" className="form-control" name="source" type="text" placeholder="New Source" value={this.state.formSource}  onChange={this.updateSource}/>
          <h2>Source Url</h2>
          <input id="builder-source-url" className="form-control" name="source_url" type="text" placeholder="New Source Url"  value={this.state.formSourceUrl}  onChange={this.updateSourceUrl}/>
          <br/>
          {language}
      </div>
    )
  }
}

export default FieldList
