import React from "react"
import PropTypes from "prop-types"
import BuilderPanel from "./BuilderPanel"
import FormPreview from "./FormPreview"

class FormBuilder extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      updatingOption: {
        original: "",
        option_list_name: "",
        option_name:"",
        option_labelEnglish: ""
      },
      projectUrl: props.project_url,
      currentUserId: props.current_user_id,
      formTitle: 'Title of New Form',
      newFieldAttributes: {
        type: "",
        name: "",
        labelEnglish: "",
        options: ""
      },
      editingField: false,
      fieldToEdit: {
        original: "",
        type: "",
        name: "",
        labelEnglish: "",
        options: ""
      },
      newOptions: [],
      editoptions: [],
      previewOptions: [],
      newOptionListName: "",
      editOptionListName: "",
      newOption: {
        option_list_name: "",
        option_name: "",
        option_labelEnglish: ""
      },
      editingOption: false,
      optionToEdit: {
        option_list_name: "",
        option_name:"",
        option_labelEnglish: "",
        original: ""
      },
      formFieldsForPreview: [],
      formBuilderCreateUrl: props.form_builder_create_url,
      rootUrl: props.root_url,
      formIsBeingBuilt: false,
      formHasBeenBuilt: false,
      formBuildFailed: false,
      surveyRecord: null,
      gettingLocation: false,
      showOptionsForm: false,
      updateAllSharedLists: false,
      optionLists: [],
      sharedOptionWarning: 'This option list is shared with other fields in this form. Any updates made will update the list for all fields using this list. Click "OK" if you wish to continue.',
      optionListsExistsMessage: ' is already an option list name in this form. Click OK to use the options for this list. If not, please enter a new name for this list.'
    };
    this.updateTitle = this.updateTitle.bind(this);
    this.updateField = this.updateField.bind(this);
    this.previewFields = this.previewFields.bind(this);
    this.editField = this.editField.bind(this);
    this.updateOptionListName = this.updateOptionListName.bind(this);
    this.updateOption = this.updateOption.bind(this);
    this.addOption = this.addOption.bind(this);
    this.editOption = this.editOption.bind(this);
    this.deleteOption = this.deleteOption.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.deleteField = this.deleteField.bind(this);
    this.buildForm = this.buildForm.bind(this);
    this.buildAnother = this.buildAnother.bind(this);
    this.startOptionList = this.startOptionList.bind(this);
  }

  updateTitle(value){
    if (value == ''){
      this.setState({
        formTitle: 'Title of New Form'
      })
    }else{
      this.setState({
        formTitle: value
      })
    }
  }
  updateField(event){
    if(this.state.editingField){
      let fieldToEdit = this.state.fieldToEdit;
      fieldToEdit[event.target.name] = event.target.value;
      this.setState({
        fieldToEdit: fieldToEdit,
        showOptionsForm: (fieldToEdit["type"].includes("select"))
      })
    }else{
      let newFieldAttributes = this.state.newFieldAttributes;
      newFieldAttributes[event.target.name] = event.target.value;
      this.setState({
        newFieldAttributes: newFieldAttributes,
        showOptionsForm: (newFieldAttributes["type"].includes("select"))
      });
    }
  }
  previewFields(){
    let formFieldsForPreview = this.state.formFieldsForPreview;
    if(this.state.editingField){
      let fieldToEdit = this.state.fieldToEdit;
      if (fieldToEdit["type"].includes('select')){
        this.addOptionsToField();
      }
      if (this.nameValueIsUnique(formFieldsForPreview, fieldToEdit)){
        for(let i = 0; i < formFieldsForPreview.length; i++){
          if(JSON.stringify(formFieldsForPreview[i]) === fieldToEdit["original"]){
            formFieldsForPreview[i] = fieldToEdit
          }
        }
        this.setState({
          formFieldsForPreview: formFieldsForPreview,
          editingField: false,
          fieldToEdit: {
            original: "",
            type: "",
            name: "",
            labelEnglish: "",
            options: ""
          },
          showOptionsForm: false,
          newOptionListName: "",
          updatingOption: {
            original: "",
            option_list_name: "",
            option_name:"",
            option_labelEnglish: ""
          },
          editingOption: false,
          updateAllSharedLists: false,
          previewOptions: []
        })
        document.getElementById("field-attribute-form").reset();
      }else{
        alert("Each Name value in a form needs to be unique. '" + this.state.fieldToEdit['name'] + "' is already apart of this form.")
      }
    }else{
      if (this.state.newFieldAttributes["type"].includes('select')){
        this.addOptionsToField();
      }
      if (this.nameValueIsUnique(formFieldsForPreview, this.state.newFieldAttributes)){
        formFieldsForPreview.push(this.state.newFieldAttributes)
        this.setState({
          formFieldsForPreview: formFieldsForPreview,
          newFieldAttributes: {
            type: "",
            name: "",
            labelEnglish: "",
            options: "",
          },
          showOptionsForm: false,
          newOptionListName: "",
          newOption: {
            option_list_name: "",
            option_name: "",
            option_labelEnglish: ""
            },
          newOptions: [],
          optionToEdit: {
            option_list_name: "",
            option_name: "",
            option_labelEnglish: ""
          },
          editingOption: false,
          updateAllSharedLists: false,
          previewOptions: [],
          updatingOption: {
            original: "",
            option_list_name: "",
            option_name:"",
            option_labelEnglish: ""
          },
          previewOptions: []
        })
        document.getElementById("field-attribute-form").reset();
      }else{
        alert("Each Name value in a form needs to be unique. '" + this.state.newFieldAttributes['name'] + "' is already apart of this form.")
      }
    }
  }
  editField(field){
    let original = JSON.stringify(field);
    let fieldToEdit = {
      original: original,
      type: field["type"],
      name: field["name"],
      labelEnglish: field["labelEnglish"],
      options: field["options"]
    }
    let previewOptions = this.state.optionLists.filter(list => list["option_list_name"] === field["options"]);
    if(field["type"].includes('select')){
      previewOptions = previewOptions.map(option => option.options);
      previewOptions = previewOptions[0];
    }
    this.setState({
      previewOptions: previewOptions,
      fieldToEdit: fieldToEdit,
      newOptionListName: field["options"],
      editingField: true,
      showOptionsForm: (field["type"].includes("select"))
    })
  }
  deleteField(field){
    let formFieldsForPreview = this.state.formFieldsForPreview;
    let index;
    for(let i = 0; i < formFieldsForPreview.length; i++){
      if(JSON.stringify(formFieldsForPreview[i]) === JSON.stringify(field)){
        index = i;
        break;
      }
    }
    formFieldsForPreview.splice(index, 1);
    this.setState({
      formFieldsForPreview: formFieldsForPreview
    })
  }
  editOption(option){
    let original = JSON.stringify(option);
    let updatingOption = {
      original: original,
      option_list_name: option["option_list_name"],
      option_name: option["option_name"],
      option_labelEnglish: option["option_labelEnglish"]
    }
    this.setState({
      newOptionListName: option["option_list_name"],
      updatingOption: updatingOption,
      editingOption: true,
    })
  }
  deleteOption(option){
    let optionLists = this.state.optionLists;
    let previewOptions = this.state.previewOptions;
    let index;
    for(let i = 0; i < optionLists.length; i++){
      if(optionLists[i].option_list_name === option.option_list_name){
        for(let x = 0; x < optionLists[i].options.length; x++){
          if(JSON.stringify(optionLists[i].options[x]) === JSON.stringify(option)){
            index = x;
            break;
          }
        }
        optionLists[i].options.splice(index, 1);
        previewOptions = optionLists[i].options;
      }
    }
    this.setState({
      optionLists: optionLists,
      previewOptions: previewOptions
    })
  }
  optionsAreShared(option){
    let optionLists = this.state.optionLists;
    if (this.state.editingField){
      let fieldToEdit = this.state.fieldToEdit;
      for(let i = 0; i < optionLists.length; i++){
        if (optionLists[i]["option_list_name"] === option.option_list_name && optionLists[i]["field_name"] !== fieldToEdit["name"]){
          return true
        }
      }
      return false
    }else{
      let newField = this.state.newFieldAttributes;
      for(let i = 0; i < optionLists.length; i++){
        if (optionLists[i]["option_list_name"] === option.option_list_name && optionLists[i]["field_name"] !== newField["name"]){
          return true
        }
      }
      return false
    }
  }
  updateAllSharedLists(event){
    let formFieldsForPreview = this.state.formFieldsForPreview;
    let optionLists = this.state.optionLists;
    if(this.state.editingField){
      let optionToEdit = this.state.optionToEdit;
      let originalOptionToEdit = JSON.parse(optionToEdit.original)
      for(let i = 0; i < optionLists.length; i ++){
        if(optionLists[i].option_list_name === originalOptionToEdit.option_list_name){
          for(let x = 0; x < formFieldsForPreview.length; x ++){
            if(formFieldsForPreview[x].options[0].option_list_name === originalOptionToEdit.option_list_name){
              formFieldsForPreview[x].options.map(option => option[event.target.name] = event.target.value);
            }
          }
        }
      }
    }else{
      debugger
    }
    return formFieldsForPreview
  }
  updateOptionListName(event){
    this.setState({
      newOptionListName: event.target.value
    });
  }
  updateOption(event){
    let updatingOption = this.state.updatingOption;
      updatingOption[event.target.name] = event.target.value;
      this.setState({
        updatingOption: updatingOption
      });
  }
  addOption(event){
    if(this.state.editingOption){
      let optionLists = this.state.optionLists;
      let optionListName = this.state.newOptionListName;
      let previewOptions = this.state.previewOptions;
      let newOption = {
        option_list_name: optionListName,
        option_name: this.state.updatingOption.option_name,
        option_labelEnglish: this.state.updatingOption.option_labelEnglish
      }
      if(this.optionNameisValid(newOption)){
        let isNewList = true;
        for(let x = 0; x < optionLists.length; x ++){
          if(optionLists[x].option_list_name === optionListName){
            // if (window.confirm(this.state.sharedOptionWarning)){
              for(let i = 0; i < previewOptions.length; i++){
                if(JSON.stringify(previewOptions[i]) === this.state.updatingOption.original){
                  previewOptions[i] = newOption;
                }
              }
              // isNewList = false;
            // }
            isNewList = false
          }
        }
        if (isNewList){
          optionLists.push({
            option_list_name: optionListName,
            options: [newOption]
          });
          previewOptions.push(newOption);
        }
        this.setState({
          optionLists: optionLists,
          previewOptions: previewOptions,
          updatingOption: {
            option_list_name: "",
            option_name: "",
            option_labelEnglish: ""
          }
        })
      }else{
        alert("Option names must be unique per list. " + newOption["option_name"] + " is already a part of this options list.")
      }
    }else{
      let optionLists = this.state.optionLists;
      let optionListName = this.state.newOptionListName;
      let previewOptions = this.state.previewOptions;
      let newOption = {
        option_list_name: optionListName,
        option_name: this.state.updatingOption.option_name,
        option_labelEnglish: this.state.updatingOption.option_labelEnglish
      }
      if(this.optionNameisValid(newOption)){
        let isNewList = true;
        for(let x = 0; x < optionLists.length; x ++){
          if(optionLists[x].option_list_name === optionListName){
            // if (window.confirm(this.state.sharedOptionWarning)){
              previewOptions.push(newOption);
              isNewList = false;
            // }
            // isNewList = false
          }
        }
        if (isNewList){
          optionLists.push({
            option_list_name: optionListName,
            options: [newOption]
          });
          previewOptions.push(newOption);
        }
        this.setState({
          optionLists: optionLists,
          previewOptions: previewOptions,
          updatingOption: {
            option_list_name: "",
            option_name: "",
            option_labelEnglish: ""
          }
        })
      }else{
        alert("Option names must be unique per list. " + newOption["option_name"] + " is already a part of this options list.")
      }
    }
  }
  addOptionsToField(){
    let optionLists = this.state.optionLists;
    let isNewList = true;
    let field;
    for(let x = 0; x < optionLists.length; x ++){
      if(optionLists[x].option_list_name === this.state.newOptionListName){
        optionLists[x].options = this.state.previewOptions;
        isNewList = false
      }
    }
    if (isNewList){
      optionLists.push({
        option_list_name: this.state.newOptionListName,
        options: this.state.previewOptions
      });
    }
    if (this.state.editingField){
      field = this.state.fieldToEdit;
      field["options"] = this.state.newOptionListName;
      this.setState({
        optionLists: optionLists,
        fieldToEdit: field
      })
    }else{
      field = this.state.newFieldAttributes;
      field["options"] = this.state.newOptionListName;
      this.setState({
        optionLists: optionLists,
        newFieldAttributes: field
      })
    }
  }

  getLocation(event){
    this.setState({
      gettingLocation: true
    })
    let output = event.target.parentElement.lastChild;
    let newRecord = this.state.record;
    let name = output.name;
    if (!navigator.geolocation){
      output.value = "Geolocation is not supported by your browser";
      return;
    }
    let success = (position) => {
      output.value = 'Latitude: ' + position.coords.latitude + ' Longitude: ' + position.coords.longitude
      this.setState({
        gettingLocation: false
      })
    }

    let error = () => {
      output.value = "Unable to retrieve your location";
      this.setState({
        gettingLocation: false
      })
    }
    navigator.geolocation.getCurrentPosition(success, error);
  }
  buildForm(){
    if(this.state.formFieldsForPreview.length === 0){
      alert("There are no fields in this form! Start adding fields by using the panel on the left. You will see them appear here in the Preview section")
    }
    else if (this.state.formTitle == "Title of New Form"){
      alert("Your form needs a title! Enter one where it says 'Form Title'.")
    }
    else{
      if (window.confirm('Are you sure the form is ready to build? Please note that the form you just built is a preview, and that any data entered will not be collected. Once the form has been built, it can not be changed.')){
        this.setState({
          formIsBeingBuilt: true
        }, function(){
          let that = this;
          fetch(this.state.formBuilderCreateUrl,{
            method: 'POST',
            headers: {
                    'Accept': 'application/json',
                    'Content-Type': ' application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                  },
            body: JSON.stringify({user_id: this.state.currentUserId, form_title: this.state.formTitle, form_fields_attributes: this.state.formFieldsForPreview, options_for_selects: this.state.optionLists})
          }).then(response => {
            response.json().then(res =>{
              if(res.saved){
                that.setState({
                  formIsBeingBuilt: false,
                  formHasBeenBuilt: true,
                  surveyRecord: res.record
                })
              }else{
                that.setState({
                  formIsBeingBuilt: false,
                  formBuildFailed: true
                })
              }
            })
          })
        })
      }
    }
  }
  buildAnother(){
    document.getElementById("builder-title").value = "";
    this.setState({
      formTitle: 'Title of New Form',
      newFieldAttributes: {
        type: "",
        name: "",
        labelEnglish: ""
      },
      editingField: false,
      fieldToEdit: {
        original: "",
        type: "",
        name: "",
        labelEnglish: ""
      },
      options: [],
      newOptionListName: "",
      newOption: {
        option_name: "",
        option_labelEnglish: ""
      },
      formFieldsForPreview: [],
      formIsBeingBuilt: false,
      formHasBeenBuilt: false,
      formBuildFailed: false,
      surveySlug: ""
    });
  }

  nameValueIsUnique(formFieldsForPreview, fieldAttributes){
    if(this.state.editingField){
      let originalField = JSON.parse(this.state.fieldToEdit["original"])
      if(originalField["name"] === fieldAttributes["name"]){
        return true
      }else{
        for(let i = 0; i < formFieldsForPreview.length; i++){
          if(formFieldsForPreview[i]["name"] === fieldAttributes["name"]){
            return false
          }
        }
        return true
      }
    }else{
      for(let i = 0; i < formFieldsForPreview.length; i++){
        if(formFieldsForPreview[i]["name"] === fieldAttributes["name"]){
          return false
        }
      }
      return true
    }
  }

  startOptionList(event){
    let optionLists = this.state.optionLists;
    for(let i = 0; i < optionLists.length; i++){
      if (optionLists[i].option_list_name === event.target.value){
        if((window.confirm(optionLists[i].option_list_name + this.state.optionListsExistsMessage))){
          this.setState({
            previewOptions: optionLists[i].options
          })
          break;
        }
      }
    }
    this.setState({
      newOptionListName: event.target.value
    })
  }

  optionNameisValid(option){
    let options = this.state.optionLists.filter(list => list["option_list_name"] === option["option_list_name"])
    if (this.state.editingOption){
      let orignalOption = JSON.parse(this.state.updatingOption["original"]);
      if(orignalOption["option_name"] === option["option_name"]){
        return true
      }else{
        for(let i = 0; i < options.length; i++){
          if(options[i]["option_list_name"] === option["option_list_name"]){
            for(let x = 0; x < options[i]["options"].length; x++){
              if(options[i]["options"][x].option_name === option.option_name){
                return false
              }
            }
          }
        }
        return true
      }
    }else{
      if(options.length > 0){
        for(let i = 0; i < options[0].options.length; i++){
          if(options[0].options[i]["option_name"] === option["option_name"]){
            return false
          }
        }
        return true
      }else{
        return true
      }
    }
  }

  render () {
    let fields = JSON.stringify(this.state.formFieldsForPreview);
    return (
      <div className="form-builder">
        <BuilderPanel updateTitle={this.updateTitle}
          updateField={this.updateField}
          editingField={this.state.editingField}
          fieldToEdit={this.state.fieldToEdit}
          newValues={this.state.newFieldAttributes}
          previewFields={this.previewFields}
          projectUrl={this.state.projectUrl}
          showOptionsForm={this.state.showOptionsForm}
          updateOptionListName={this.updateOptionListName}
          updatingOption={this.state.updatingOption}
          updateOption={this.updateOption}
          addOption={this.addOption}
          newOptionListName={this.state.newOptionListName}
          editOptionListName={this.state.editOptionListName}
          newOptions={this.state.newOptions}
          newOption={this.state.newOption}
          editOption={this.editOption}
          deleteOption={this.deleteOption}
          editingOption={this.state.editingOption}
          optionToEdit={this.state.optionToEdit}
          checkIfNameUnique={this.checkIfNameUnique}
          editOptions={this.state.editOptions}
          previewOptions={this.state.previewOptions}
          startOptionList={this.startOptionList}
        />
        <FormPreview
          title={this.state.formTitle}
          formFields={fields}
          editField={this.editField}
          deleteField={this.deleteField}
          getLocation={this.getLocation}
          gettingLocation={this.state.gettingLocation}
          buildForm={this.buildForm}
          formIsBeingBuilt={this.state.formIsBeingBuilt}
          formHasBeenBuilt={this.state.formHasBeenBuilt}
          formBuildFailed={this.state.formBuildFailed}
          surveyRecord={this.state.surveyRecord}
          buildAnother={this.buildAnother}
          optionLists={this.state.optionLists}
          previewOptions={this.state.previewOptions}
          rootUrl={this.state.rootUrl}
        />
      </div>
    );
  }
}

export default FormBuilder
