var Parser = require('expr-eval').Parser;


export const evalRelevant = (record, relevant_v2) =>{
  let  expr = new Parser()
  expr.functions["selected"] = function (arg1, arg2) {
    if(Array.isArray(arg1)){
      return arg1.includes(arg2)
    }
    else if (typeof arg1 == "string"){
      return arg1 == arg2;
    }
    else{
      throw Exception(`${arg1} type is not supported`)
    }
  };

  let evalParams = {}
  relevant_v2.fields.forEach(function(field_name){
    evalParams[field_name] = record[field_name] ? record[field_name] : ""
  });
  let result = expr.evaluate(relevant_v2.logic, evalParams)
  return result;
}

export const evalOptionFilter = (record, option, options_filter)=>{
  let  expr = new Parser()
  expr.functions["selected"] = function (arg1, arg2) {
    if(Array.isArray(arg1)){
      return arg1.includes(arg2)
    }
    else if (typeof arg1 == "string"){
      return arg1 == arg2;
    }
    else{
      throw Exception(`${arg1} type is not supported`)
    }
  };
  let evalParams = {}
  options_filter.fields.forEach(function(field_name){
    evalParams[field_name] = record[field_name] ? record[field_name] : ""
  });
  
  options_filter.categories.forEach(function(category_name){
    let category = option.categories.find(function (el) { return el.name==category_name; });
    evalParams[category_name] = category.value
  });
  return expr.evaluate(options_filter.logic, evalParams);
}

export const evalCalculation = (record, calculate)=>{
  let  expr = new Parser()
  expr.functions["selected"] = function (arg1, arg2) {
    if(Array.isArray(arg1)){
      return arg1.includes(arg2)
    }
    else if (typeof arg1 == "string"){
      return arg1 == arg2;
    }
    else{
      throw Exception(`${arg1} type is not supported`)
    }
  };
  let evalParams = {}
  calculate.fields.forEach(function(field_name){
    evalParams[field_name] = record[field_name] ? record[field_name] : ""
  });

  return expr.evaluate(calculate.calculation, evalParams);
}

export const isRelevant = (record, field) =>{
  if(field.relevant_v2){
    let evalResult = evalRelevant(record, field.relevant_v2)
    return evalResult
  }
  else{
    if ("undefined" === typeof field.relevant){
      return true
    }
    else{
      return checkRelevance(record, field.relevant);
    }
  }
}

export const checkRelevance = (record, relevantConditions) =>{
  let relevant = 0;
  let length = relevantConditions.length;
  let ors = false;
  let i;
  if(relevantConditions[relevantConditions.length - 1] == 'ors'){
    ors = true;
    length--;
  }
  for(i = 0; i < length; i++) {
    if(Array.isArray(relevantConditions[i])) {
      let thisLength = relevantConditions[i].length;
      let x;
      for(x = 0; x < thisLength; x++) {
        if (evaluateConditionForRelevance(record, relevantConditions[i][x])) {
          relevant += 1;
        }
      }
      if(relevant == thisLength) {
        return true;
      }
      else {
        if (i + 1 == length) {
          return false;
        }
        else {
          relevant = 0;
        }
      }
    }
    else {
      if (evaluateConditionForRelevance(record, relevantConditions[i])) {
        relevant += 1;
      }
    }
  }
  if(relevant == length) {
    return true;
  }
  else if (ors) {
      return relevant > 0;
    }
  else {
    return false;
  }
}

export const evaluateConditionForRelevance = (record, conditionForRelevance) => {
  if (conditionForRelevance.selected == undefined) {
    return evaluateComparison(record, conditionForRelevance);
  }
  else {
    return evaluateSelected(record, conditionForRelevance);
  }
}

export const evaluateSelected = (record, conditionForRelevance) => {
  let relevantField = conditionForRelevance.field_name;
  let relevantValues = record[relevantField];
  if (relevantValues != undefined) {
    let valueIncluded = relevantValues.includes(conditionForRelevance.value);
    return conditionForRelevance.selected == valueIncluded;
  }
  else {
    return false;
  }
}

export const evaluateComparison = (record, conditionForRelevance) =>{
  let valueOne = record[conditionForRelevance.field_name];
  let valueTwo = conditionForRelevance.value;
  let comparisonSymbol = conditionForRelevance.comparison_symbol;
  switch(comparisonSymbol){
    case '>':
      if(parseInt(valueOne) > parseInt(valueTwo)){
        return true
      }else{
        return false
      }
      break;
    case '>=':
      if(parseInt(valueOne) >= parseInt(valueTwo)){
        return true
      }else{
        return false
      }
      break;
    case '<':
      if(parseInt(valueOne) < parseInt(valueTwo)){
        return true
      }else{
        return false
      }
      break;
    case '<=':
      if(parseInt(valueOne) <= parseInt(valueTwo)){
        return true
      }else{
        return false
      }
      break;
    case '!=':
      if(valueOne != valueTwo){
        return true
      }else{
        return false
      }
      break;
    case '=':
      if(valueOne == valueTwo){
        return true
      }else{
        return false
      }
      break;
  }
}
