

export const getGroupEndIndex = (fields, startSearchAtIndex, fieldName) =>{
  for(let cnt=startSearchAtIndex; cnt < fields.length; cnt++){
    let field = fields[cnt];
    if(field.name == fieldName && field.type == "end_group"){
      return cnt;
    }
  }
}

export const getGroupIndex = (fields, startSearchAtIndex, fieldName) =>{
  for(let cnt=startSearchAtIndex; cnt < fields.length; cnt++){
    let field = fields[cnt];
    if(field.name == fieldName && field.type == "group"){
      return cnt;
    }
  }
}

export const  getGroup = (fields, field) => {
  for (var i = 0; i < fields.length; i++) {
    if (fields[i].type === 'group' && fields[i].name === field.group_name) {
      return fields[i];
    }
  }
  return null;
}

export const isEmpty =(str) => {
  if (str == null) {
    return true;
  }
  else {
    let string = str.toString();
    return !string.replace(/^\s+/g, '').length;
  }
}


export const cleanRepeatersRecord = (repeatersRecord, fields) =>{
  let rptsRecord = {...repeatersRecord};
  let repeaterNames = Object.keys(rptsRecord);
  
  for(let cnt=0; cnt < repeaterNames.length; cnt++){
    let rptName = repeaterNames[cnt];
    let rptRecords = rptsRecord[rptName];
    for(let cnt2=0; cnt2 < rptRecords.length; cnt2++){
      let record = rptRecords[cnt2];
      let recordKeys = Object.keys(record);
      for(let cnt3=0; cnt3< recordKeys.length; cnt3++){
        let key = recordKeys[cnt3];
        let field = fields.filter(e=> e.name == key)[0];
        if(!field){
         
        }
        else{
          let val =  record[key];
          let newKeyLength = `${rptName}_`.length;
          let newKey = key.substring(newKeyLength, key.length);
          record[newKey] = val; 
        }
        delete record[key];
      }
    }
  }

  return rptsRecord;
}


export const cleanRecord = (record, fields) =>{
  let newRecord = {...record}
  let recordKeys = Object.keys(newRecord);
  for(let cnt3=0; cnt3< recordKeys.length; cnt3++){
    let key = recordKeys[cnt3];
    if(key.endsWith("_acc") || key.endsWith("_alt") || key.endsWith("_lat") || key.endsWith("_lng")){
      let locKey = key.substring(0, key.length-4);
      let field = fields.filter(e=> e.name == locKey)[0];
      if(!field){
        delete newRecord[key];
      }
    }
    else{
      let field = fields.filter(e=> e.name == key)[0];
      if(!field){
        delete newRecord[key];
      }
    }
  }

  return newRecord;
}

export const  isVisible = (field) => {
  let name = field.name;
  let groupName = field.group_name;
  if (groupName === "meta") {
    return false;
  }
  else if(name === "meta_instance_id"){
    return false;
  }
  else if(field.readonly && field.type == "string" && field.calculate){
    return false;
  }
  else {
    return true;
  }
}

export const CreateFormData = (strData, files) =>{
  let formData = new FormData();
  formData.append("data", strData);

  let fileKeys = Object.keys(files);
  for(let cnt3=0; cnt3< fileKeys.length; cnt3++){
    formData.append(fileKeys[cnt3], files[fileKeys[cnt3]], fileKeys[cnt3])
  }

  return formData;
}