import React, { Component } from 'react';

class Label extends Component {

  render() {
    return (
      <div>
        {(this.props.required)?<span className="required">*</span>: ""}
        <label htmlFor={this.props.htmlFor}>{this.props.label}</label><br/>
        {(this.props.hint)?<label className="hint">{this.props.hint}</label>: ""}
      </div>
      
    );

  }
}
export default Label